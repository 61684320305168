import React from "react";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";
import CaserTextField from "./mainComponents/Fields";
import Slider from "@material-ui/core/Slider";

export default class CaserSliderField extends CaserTextField {
  state = {
    value: this.props.value,
    newVal: null,
    changed: false,
  };
  componentDidUpdate() {
    this.updateValue();
  }
  updateValue() {
    if (this.state.changed === true) {
      if (this.state.newVal === this.props.value) {
        return;
      }
      this.setState({ newVal: this.props.value });
    } else {
      if (this.state.value === this.props.value) {
        return;
      }
      this.setState({ value: this.props.value });
    }
  }
  // stringify(ob) {
  //   return JSON.stringify(ob, null, "\t");
  // }
  // onChange(event) {
  //   if (!this.checkEditable()) return;
  //   let value = event.target.value;
  //   // let value = h2p.fromString(event.target.value,{
  //   //       wordwrap: 130,
  //   //       preserveNewlines:true,
  //   //       singleNewLineParagraphs:true
  //   // });
  //   // console.log(value)
  //   this.setState({
  //     value,
  //   });
  //   if (this.props.noDebounce !== true) this.sendDebounceUpdate(value);
  //   else this.sendDirectUpdate(value);
  // }

  onChange(ob, value) {
    console.log(ob, value);
    this.setState({
      value: value,
    });
    this.props.onChange(value);
  }
  // onSave = () => {
  //   try {
  //     let val = JSON.parse(this.state.value);
  //     this.props.onChange(val);
  //     this.setState({ changed: false });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };
  render() {
    return (
      <div className={"caser-input-container"}>
        <div className={"caser-input-label"}>{this.props.label}</div>
        <div
          className={"caser-input"}
          style={{ paddingRight: "15px", paddingLeft: "15px" }}
        >
          <Slider
            track={"normal"}
            value={this.state.value}
            onChange={this.onChange.bind(this)}
            {...this.props.sliderProps}
          />
        </div>
      </div>
    );
  }
}
