import React from "react";
import Video from "./../../Tools/Video";
export default class IFrame extends React.PureComponent {
  canvas = React.createRef();

  componentDidMount() {
    this.props.display.context.globals.media.addDevice(
      this.props.element.id,
      "local",
      {
        type: "desktopinput",
        label: this.props.element.computedElement.props.screenLabel,
        title: this.props.element.computedElement.name,
        shouldStream: false,
        constraints: (deviceId) => {
          return {
            video: {
              mandatory: {
                chromeMediaSource: "desktop",
                chromeMediaSourceId: deviceId,
                //                     minWidth: 960,
                //   maxWidth: 960,
                //   minHeight: 540,
                //   maxHeight: 540,
              },

              //   height: 540,
              //   width: 960,
              //   frameRate: { exact: 30 },
            },
            audio: false,
            // video: false,
          };
        },
      }
    );
    this.props.display.context.globals.media.devices[this.props.element.id].on(
      "active",
      () => (this.props.element.ready = true)
    );
    // if (
    //   this.props.display.context.globals.media.devices[this.props.element.id]
    //     .active
    // )
    // this.props.element.ready = true;
  }
  render() {
    // if (
    //   !this.props.display.context.globals.media.devices[this.props.element.id]
    // )
    //   return <div className={"display-element-inner"}></div>;
    return (
      <Video
        streaming={this.props.display.context.globals.media}
        width={"inherit"}
        height={"inherit"}
        view={this.props.element.id}
        id={this.props.element.id}
        style={{ width: "inherit", height: "inherit", border: "none" }}
      />
    );
  }
}
