import React from "react";
import StreamMedium from "../../Tools/StreamMedium";
import mapValues from "lodash.mapvalues";
export default class Medium extends React.PureComponent {
  canvas = React.createRef();
  get deviceConfig() {
    let config = {
      type: this.props.element.computedElement.props.type,
      label: this.props.element.computedElement.props.streamLabel.split(
        "___"
      )[1],
      title: this.props.element.computedElement.name,
      shouldStream: this.props.element.computedElement.shouldStream || false,
      constraints: this.parseConstraints.bind(this),
    };
    return config;
  }
  constraintDefault(type) {
    if (type === "audioinput")
      return {
        audio: {
          deviceId: {
            exact: "_deviceId",
          },
          echoCancellation: false,
          noiseSuppression: false,
          channelCount: 1,
          autoGainControl: false,
        },
      };
    if (type === "videoinput")
      return {
        video: {
          deviceId: {
            exact: "_deviceId",
          },
          width: 1920,
          height: 1080,
          frameRate: {
            exact: 30,
          },
        },
      };
    if (type === "desktopinput")
      return {
        video: {
          mandatory: {
            chromeMediaSource: "desktop",
            chromeMediaSourceId: "_deviceId",
          },
        },
        audio: false,
      };
    return {};
  }
  parseConstraints(deviceId) {
    let mapConstraintObject = (v) => {
      if (Array.isArray(v)) {
        return v.map(mapConstraintObject);
      } else if (typeof v === "object") {
        return mapValues(v, mapConstraintObject);
      } else if (typeof v === "string" && v === "_deviceId") {
        return deviceId;
      } else return v;
    };
    return mapValues(
      this.props.element.computedElement.props.constraints ||
        this.constraintDefault(this.props.element.computedElement.props.type),
      mapConstraintObject
    );
  }
  get deviceLocation() {
    console.log(this.props.displayObj, this.props);
    return this.props.displayObj.location ===
      this.props.element.computedElement.props.location
      ? "local"
      : "remote";
  }
  componentDidMount() {
    console.log(
      this.deviceLocation,
      this.props.element.computedElement.props.streamLabel,
      this.deviceConfig,
      this.props.element.computedElement.props.streamLabel
    );
    if (
      !this.props.display.context.globals.media.devices[
        this.props.element.computedElement.props.streamLabel
      ]
    )
      this.props.display.context.globals.media.addDevice(
        this.props.element.computedElement.props.streamLabel,
        this.deviceLocation,
        this.deviceConfig
      );
    this.props.display.context.globals.media.devices[
      this.props.element.computedElement.props.streamLabel
    ].on("active", () => (this.props.element.ready = true));
    if (
      this.props.display.context.globals.media.devices[
        this.props.element.computedElement.props.streamLabel
      ].active
    )
      this.props.element.ready = true;
  }
  render() {
    return (
      <StreamMedium
        onEnd={() => (this.props.element.ready = false)}
        onLive={() => (this.props.element.ready = true)}
        streaming={this.props.display.context.globals.media}
        width={"inherit"}
        height={"inherit"}
        manualConfig={true}
        view={this.props.element.computedElement.props.streamLabel}
        id={this.props.element.id}
        style={{ width: "inherit", height: "inherit", border: "none" }}
      />
    );
  }
}
