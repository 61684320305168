import React, { Component } from "react";
// import PouchDB from "pouchdb";
// import PouchDBFind from "pouchdb-find";
// import PouchDBUpsert from "pouchdb-upsert";
// import { Socket } from "avc-client-tools";
import { State } from "avc-client-tools";
// import onChange from "on-change";

// PouchDB.plugin(PouchDBUpsert);
// PouchDB.plugin(PouchDBFind);

let DBContext = React.createContext({});
export default DBContext;

export class DB extends Component {
  dataState = new State({
    domains: this.domains,
    onLoad: this.onLoad.bind(this),
    onChange: this.onDataChange.bind(this),
  });
  state = {
    loaded: false,
    ws: this.dataState.ws,
  };
  domains = this.props.domains || [
    "admin",
    "camera",
    "displays",
    "presets",
    "teams",
    "smartControl",
    "schedule",
    "mediaServer",
  ];
  onLoad(state) {
    this.setState({ loaded: true, ...state });
  }
  onDataChange(state, { domain, name, value }) {
    console.log("ChangingData", domain, name);
    // if (!this.state[domain] || JSON.stringify(this.state[domain][name]) !== JSON.stringify(value))
    this.setState((st) => {
      if (!st[domain]) st[domain] = {};
      st[domain][name] = value;
      return st;
    });
  }
  render() {
    if (!this.state.loaded) return null;
    return (
      <DBContext.Provider value={this.state}>
        {this.props.children}
      </DBContext.Provider>
    );
  }
}
