import React from "react";
import iframe from "./iframe";
import image from "./image";
import color from "./color";
import screen from "./screen";
import audio from "./audio";
import video from "./video";
import stream from "./medium";
import text from "./text";
import translation from "./translation";

let elementClasses = {
  iframe,
  color,
  image,
  screen,
  audio,
  video,
  stream,
  text,
  translation,
};
export default class Element extends React.PureComponent {
  get shouldDisplay() {
    return (
      this.isPersistent || this.props.element.live || this.props.element.live
    );
  }
  get isPersistent() {
    return true;
    // return this.props.element.persistent;
  }
  get id() {
    return this.props.element.id;
  }
  render() {
    let ElementClass = elementClasses[this.props.element.computedType];
    if (!ElementClass) return <div></div>;
    let className = [
      `display-element-outer`,
      this.props.element.live ? "live" : "notLive",
      this.props.element.preview ? "preview" : "notPreview",
      this.props.element.ready ? "ready" : "notReady",
      this.shouldDisplay ? "displayable" : "notDisplayable",
    ];
    return (
      <div
        className={className.join(" ")}
        id={this.props.element.computedElement.name}
        style={{
          ...(this.props.element.computedElement.container || {}),
          zIndex: this.props.element.live
            ? 1000 - this.props.order
            : 1000 - this.props.order,
        }}
      >
        {this.shouldDisplay && (
          <>
            <div
              className={`display-element-inner`}
              style={{
                ...(this.props.element.computedElement.element || {}),
              }}
            >
              <ElementClass {...this.props} live={this.props.element.live} />
            </div>
            <div
              className={`display-element-after`}
              style={{
                ...(this.props.element.computedElement.elementAfter || {}),
              }}
            >
              {" "}
            </div>
          </>
        )}
      </div>
    );
  }
}
