import MainStreaming from "./MainStreaming";
// import TeamsStreaming from "./TeamsStreaming";
// import Audio from "./Audio";
import OCL from "./OCL";
import Display from "./Display";
import Translation from "./Translation";
import Control from "./Control";
const pagesManifest = [
  {
    name: "Stream",
    options: {},
    class: MainStreaming,
    pinned: true,
    defaultAdd: true,
    renderClosed: false,
  },
  // {
  //   name: "Mixer",
  //   options: {},
  //   class: Audio,
  //   pinned: true,
  //   defaultAdd: true,
  //   renderClosed: false,
  // },
  // {
  //   name: "Zoom",
  //   options: {},
  //   class: TeamsStreaming,
  //   pinned: true,
  //   defaultAdd: true,
  //   renderClosed: false,
  // },
  {
    name: "OCL",
    options: {},
    class: OCL,
    pinned: true,
    defaultAdd: true,
  },
  {
    name: "Displays",
    options: {},
    class: Display,
    pinned: true,
    defaultAdd: true,
  },
  {
    name: "Translation",
    options: {},
    class: Translation,
    pinned: true,
    defaultAdd: false,
    renderClosed: false,
  },
  {
    name: "Settings",
    options: {},
    class: Control,
    pinned: true,
    defaultAdd: true,
  },
];

export default pagesManifest.reduce((r, c) => r.set(c.name, c), new Map());
