import onChange from "on-change";
import merge from "lodash.merge";
import clone from "lodash.clonedeep";
export default class Display {
  constructor(context, id) {
    this.context = context;
    this._setId = id;
  }
  setId(id) {
    this._setId = id;
    return this;
  }
  setName(name) {
    this._setName = name;
    return this;
  }
  get id() {
    return this._setId || (this.displayObject || {}).id || null;
  }
  get exists() {
    return this.displayObject !== null;
  }
  get displayObject() {
    if (this._setId) return this.context.displays.displays.find(v => v.id === this._setId) || null;
    if (this._setName) return this.context.displays.displays.find(v => v.name === this._setName) || null;
    return null;
  }
  get displayIndex() {
    if (this._setId) return this.context.displays.displays.findIndex(v => v.id === this._setId);
    if (this._setName) return this.context.displays.displays.findIndex(v => v.name === this._setName);
    return -1;
  }
  get elements() {
    let display = this.displayObject;
    if (display === null) return null;
    return display.elements.map(v => this.element(v)).filter(v => true).sort((b, a) => b.order - a.order);
  }
  get views() {
    let display = this.displayObject;
    if (display === null) return null;
    let elements = display.elements;
    let views = this.context.displays.views.filter(view => view.defaultDisplay === display.id || view.elements.every(el => elements.includes(el)));
    return views.map(v => this.view(v.id));
  }
  element(id) {
    return new Element(this.context, id, this.id);
  }
  view(id) {
    return new View(this.context, id, this.id);
  }
  groupElementsIDs(groupId) {
    let display = this.displayObject;
    if (display === null) return null;
    return this.elements.filter(v => v.groupId === groupId).map(v => v.id);
    // let displayElements = display.elements || [];
    // return (
    //   this.context.displays.elements.filter(
    //     (v) => displayElements.includes(v.id) && (v.groupID||v.id) === groupId
    //   ) || []
    // ).map((v) => v.id);
  }

  viewGroupElementIDs(viewGroupId) {
    let display = this.displayObject;
    if (display === null) return null;
    let views = this.views.filter(v => v.groupId === viewGroupId) || [];
    let allElementIDs = [];
    for (let view of views) {
      allElementIDs.push(...view.elements.map(e => e.id));
    }
    console.log("allElementIDs", allElementIDs);
    allElementIDs = new Set(allElementIDs);
    return Array.from(allElementIDs);
  }
  removeViewExclusions(view) {
    let from = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "live";
    let allElements = this.viewGroupElementIDs(view.groupId);
    let currentViewElements = view.viewObject.elements;
    let otherElements = allElements.filter(el => !currentViewElements.includes(el));
    console.log(allElements, currentViewElements, otherElements, this.displayObject, this.displayObject[from]);
    this.displayObject[from] = (this.displayObject[from] || []).filter(el => !otherElements.includes(el));
  }
  setElementLive(elementId) {
    let set = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    let displayObj = this.displayObject;
    if (displayObj === null) return null;
    let elementObj = this.element(elementId);
    if (set) {
      let groupIDs = elementObj.groupElementsIDs();
      displayObj.live = [...displayObj.live.filter(v => !groupIDs.includes(v)), elementId];
    } else {
      displayObj.live = displayObj.live.filter(v => v !== elementId);
    }
  }
  setElementPreview(elementId) {
    let set = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    let displayObj = this.displayObject;
    if (displayObj === null) return null;
    let elementObj = this.element(elementId);
    if (set) {
      let groupIDs = elementObj.groupElementsIDs();
      displayObj.preview = [...displayObj.preview.filter(v => !groupIDs.includes(v)), elementId];
    } else {
      displayObj.preview = displayObj.preview.filter(v => v !== elementId);
    }
  }
  takeShot() {
    let display = this.displayObject;
    if (display === null) return null;
    let oldLive = display.live;
    display.live = display.preview;
    display.preview = oldLive;
  }
  get live() {
    let display = this.displayObject;
    if (display === null) return [];
    return display.live || [];
  }
  set live(set) {
    let display = this.displayObject;
    if (display === null) return [];
    display.live = set;
  }
  get preview() {
    let display = this.displayObject;
    if (display === null) return [];
    return display.preview || [];
  }
  set preview(set) {
    let display = this.displayObject;
    if (display === null) return [];
    display.preview = set;
  }
  get ready() {
    let display = this.displayObject;
    if (display === null) return [];
    return display.ready || [];
  }
  set ready(set) {
    let display = this.displayObject;
    if (display === null || !display.ready) return this;
    display.ready = set;
  }
  get location() {
    let display = this.displayObject;
    if (display === null) return [];
    return display.location;
  }
  set location(set) {
    let display = this.displayObject;
    if (display === null || !display.ready) return this;
    display.location = set;
  }
  get label() {
    let display = this.displayObject;
    if (display === null) return [];
    return display.label || display.name;
  }
  delete() {
    let index = this.displayIndex;
    if (index > -1) {
      this.context.displays.displays.splice(index, 1);
      this._setId = null;
      this._setName = null;
    }
  }
}
export class Location {
  constructor(context, id) {
    this.context = context;
    this._setId = id;
  }
  setId(id) {
    this._setId = id;
    return this;
  }
  setName(name) {
    this._setName = name;
    return this;
  }
  get id() {
    return this._setId || (this.locationObject || {}).id || null;
  }
  get exists() {
    return this.locationObject !== null;
  }
  get locationObject() {
    if (!this.context.displays) return null;
    if (this._setId) return this.context.displays.locations.find(v => v.id === this._setId) || null;
    if (this._setName) return this.context.displays.locations.find(v => v.name === this._setName) || null;
    return null;
  }
  get locationIndex() {
    if (this._setId) {
      return this.context.displays.locations.findIndex(v => v.id === this._setId);
    }
    if (this._setName) return this.context.displays.locations.findIndex(v => v.name === this._setName);
    return -1;
  }
  get displays() {
    let location = this.locationObject;
    if (location === null) return null;
    return this.context.displays.displays.filter(v => v.location === this.id).map(v => new Display(this.context, v.id));
  }
  get label() {
    let location = this.locationObject;
    if (location === null) return null;
    return location.label || location.name;
  }
  get name() {
    let location = this.locationObject;
    if (location === null) return null;
    return location.name;
  }
  delete() {
    let index = this.locationIndex;
    if (index > -1) {
      for (let display of this.displays) {
        display.location = null;
      }
      this.context.displays.locations.splice(index, 1);
      this._setId = null;
      this._setName = null;
    }
  }
}
export class Element {
  constructor(context, id, displayId) {
    this.context = context;
    this._setId = id;
    this._setDisplayId = displayId;
  }
  setId(id) {
    this._setId = id;
    return this;
  }
  setName(name) {
    this._setName = name;
    return this;
  }
  setDisplayId(id) {
    this._setDisplayId = id;
    return this;
  }
  setDisplayName(name) {
    this._setDisplayName = name;
    return this;
  }
  get id() {
    return this._setId || this.elementObject.id || null;
  }
  get elementIndex() {
    if (this._setId) return this.context.displays.elements.findIndex(v => v.id === this._setId);
    if (this._setName) return this.context.displays.elements.findIndex(v => v.name === this._setName);
    return -1;
  }
  get exists() {
    return this.elementObject !== null;
  }
  get elementObject() {
    if (this._setId) return this.context.displays.elements.find(v => v.id === this._setId) || null;
    if (this._setName) return this.context.displays.elements.find(v => v.name === this._setName) || null;
    return null;
  }
  get views() {
    let element = this.elementObject;
    if (element === null) return null;
    let views = this.context.displays.views.filter(view => view.elements.includes(element.id));
    return views.map(v => this.view(v.id));
  }
  get displays() {
    let element = this.elementObject;
    if (element === null) return null;
    let displays = this.context.displays.displays.filter(display => display.elements.includes(element.id));
    return displays.map(v => new Display(this.context, v.id));
  }
  get display() {
    if (!this._setDisplayId) return null;
    return new Display(this.context, this._setDisplayId);
  }
  elementMoveUp() {
    let elementIndex = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.order;
    let elements = onChange.target(this.context.displays.elements);
    elements.splice(elementIndex - 1, 0, elements.splice(elementIndex, 1)[0]);
    this.context.displays.elements = elements;
  }
  elementMoveDown() {
    let elementIndex = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.order;
    let elements = onChange.target(this.context.displays.elements);
    elements.splice(elementIndex + 1, 0, elements.splice(elementIndex, 1)[0]);
    this.context.displays.elements = elements;
  }
  groupElementsIDs(groupId) {
    let display = this.display;
    if (display === null) return null;
    return this.display.groupElementsIDs(groupId);
  }
  set live(set) {
    let display = this.display;
    if (display === null) return;
    let element = this.elementObject;
    if (element === null) return null;
    display.setElementLive(this.elementObject.id, set);
  }
  set preview(set) {
    let display = this.display;
    if (display === null) return;
    let element = this.elementObject;
    if (element === null) return null;
    display.setElementPreview(this.elementObject.id, set);
  }
  get computedType() {
    var _this = this;
    let computedType = function () {
      let elementId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _this.id;
      let element = _this.context.displays.elements.find(v => v.id === elementId) || null;
      if (!element) return null;
      if (element.type !== "clone") return element.type;
      if (!(element.props || {}).elementID) return null;
      let derivedElement = _this.context.displays.elements.find(v => v.id === (element.props || {}).elementID) || null;
      if (!derivedElement) return null;
      console.log();
      return computedType(derivedElement.id);
    };
    return computedType();
  }
  get computedElement() {
    var _this2 = this;
    let computedElement = function () {
      let elementId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _this2.id;
      let current = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let element = _this2.context.displays.elements.find(v => v.id === elementId) || null;
      if (!element) return null;
      element = clone(onChange.target(element));
      if (element.type !== "clone") return merge(element, current, {
        type: element.type
      });
      let derivedElement = _this2.context.displays.elements.find(v => v.id === (element.props || {}).elementID) || null;
      if (!derivedElement) return null;
      derivedElement = clone(onChange.target(derivedElement));
      return computedElement(derivedElement.id, merge(derivedElement, element, current, {
        type: derivedElement.type
      }));
    };
    return computedElement();
  }
  get order() {
    return this.context.displays.elements.findIndex(v => v.id === this.id);
  }
  get groupId() {
    return (this.computedElement || {}).groupID || (this.elementObject || {}).groupID || (this.elementObject || {}).id;
  }
  get live() {
    return this.id && this.display && this.display.live.includes(this.id);
  }
  get preview() {
    return this.id && this.display && this.display.preview.includes(this.id);
  }
  get ready() {
    return this.id && this.display && this.display.ready.includes(this.id);
  }
  set ready(set) {
    if (!this.id) return;
    if (set) this.display.ready.push(this.id);else this.display.ready = this.display.ready.filter(v => v !== this.id);
  }
  get mediaUrl() {
    let element = this.computedElement;
    if (element === null) return null;
    if (element.props.asset) return "https://av.saintmarydelray.com/assets/" + element.props.asset;
    if (element.props.url) return element.props.url;
    if (element.props.src) return element.props.src;
    return null;
  }
  delete() {
    let index = this.elementIndex;
    if (index > -1) {
      for (let display of this.displays) {
        display.displayObject.elements = display.displayObject.elements.filter(v => v !== this.id);
        display.preview = display.displayObject.preview.filter(v => v !== this.id);
        display.live = display.displayObject.live.filter(v => v !== this.id);
        display.ready = display.displayObject.ready.filter(v => v !== this.id);
      }
      for (let view of this.views) {
        view.viewObject.elements = view.viewObject.elements.filter(v => v !== this.id);
      }
      this.context.displays.elements.splice(index, 1);
      this._setId = null;
      this._setName = null;
    }
  }
}
export class View {
  constructor(context, id, displayId) {
    this.context = context;
    this._setId = id;
    this._setDisplayId = displayId;
  }
  setId(id) {
    this._setId = id;
    return this;
  }
  setName(name) {
    this._setName = name;
    return this;
  }
  setDisplayId(id) {
    this._setDisplayId = id;
    return this;
  }
  setDisplayName(name) {
    this._setDisplayName = name;
    return this;
  }
  get id() {
    return this._setId || this.viewObject.id || null;
  }
  get viewObject() {
    if (this._setId) return this.context.displays.views.find(v => v.id === this._setId) || null;
    if (this._setName) return this.context.displays.views.find(v => v.name === this._setName) || null;
    return null;
  }
  get viewIndex() {
    if (this._setId) return this.context.displays.views.findIndex(v => v.id === this._setId);
    if (this._setName) return this.context.displays.views.findIndex(v => v.name === this._setName);
    return -1;
  }
  get exists() {
    return this.viewObject !== null;
  }
  get elements() {
    let view = this.viewObject;
    if (view === null) return null;
    return (view.elements || []).map(v => this.element(v));
  }
  get display() {
    return new Display(this.context, this._setDisplayId);
  }
  get displays() {
    let view = this.viewObject;
    if (view === null) return null;
    let displays = this.context.displays.displays.filter(display => view.elements.every(el => display.elements.includes(el)));
    return displays.map(v => new Display(this.context, v.id));
  }
  element(id) {
    return new Element(this.context, id, this._setDisplayId);
  }
  groupViewsIDs(groupId) {
    let display = this.display;
    if (display === null) return null;
    return this.display.groupViewsIDs(groupId);
  }
  get groupId() {
    return (this.viewObject || {}).groupID || this.id;
  }
  set live(set) {
    let viewObj = this.viewObject;
    if (viewObj === null) return;
    let display = this.display;
    if (display === null) return;
    let elements = this.elements;
    if (elements === null) return null;
    if (viewObj.exclusive === "exc" && set) {
      display.live = viewObj.elements;
    } else {
      if (!this.exists) return null;
      if (viewObj.exclusive === "gr" && set) {
        display.removeViewExclusions(this, "live");
      }
      elements.forEach(el => {
        display.setElementLive(el.id, set);
      });
    }
  }
  set preview(set) {
    let viewObj = this.viewObject;
    if (viewObj === null) return;
    let display = this.display;
    if (display === null) return;
    let elements = this.elements;
    if (elements === null) return null;
    if (viewObj.exclusive === "exc" && set) {
      display.preview = viewObj.elements;
    } else {
      if (!this.exists) return null;
      if (viewObj.exclusive === "gr" && set) {
        display.removeViewExclusions(this, "preview");
      }
      elements.forEach(el => {
        display.setElementPreview(el.id, set);
      });
    }
  }
  get live() {
    let view = this.viewObject;
    if (view === null) return;
    let display = this.display;
    if (display === null) return;
    return view.elements.every(e => (display.live || []).includes(e));
  }
  get preview() {
    let view = this.viewObject;
    if (view === null) return;
    let display = this.display;
    if (display === null) return;
    return view.elements.every(e => (display.preview || []).includes(e));
  }
  get ready() {
    let view = this.viewObject;
    if (view === null) return;
    let display = this.display;
    if (display === null) return;
    return view.elements.every(e => (display.ready || []).includes(e));
  }
  delete() {
    let index = this.viewIndex;
    if (index > -1) {
      this.context.displays.views.splice(index, 1);
      this._setId = null;
      this._setName = null;
    }
  }
}