import React from "react";
export default class IFrame extends React.PureComponent {
  render() {
    return (
      <iframe
        onLoad={() => (this.props.element.ready = true)}
        src={this.props.element.mediaUrl}
        style={{ width: "inherit", height: "inherit", border: "none" }}
        title={"element: " + this.props.element.id}
        allowTransparency={true}
      />
    );
  }
}
