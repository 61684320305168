import React from "react";

export default class Tabs extends React.Component {
  state = {
    openIndex: this.props.defaultOpenIndex || 0,
  };
  render() {
    var elements = (this.props.children || []).filter((v) => v !== null);
    console.log(elements);

    const childrenWithProps = elements.map((v, i) => {
      if (!v) return v;
      return React.cloneElement(v, {
        open: this.state.openIndex === i ? true : false,
        first: i === 0 ? true : false,
        last: i === elements.length - 1 ? true : false,
      });
    });
    return (
      <div
        className={
          "caser-tabs-container" +
          ((this.props.tabPosition || "top") === "bottom" ? " bottom" : "")
        }
      >
        <div className="caser-tabbar-container">
          <div className="caser-tabbar-container-inner">
            {elements
              .filter((v) => v !== null)
              .map((v, i) => (
                <div
                  className={
                    "caser-tabbar-element" +
                    (this.state.openIndex === i ? " open" : "")
                  }
                  key={i}
                  onClick={() => this.setState({ openIndex: i })}
                >
                  {v.props.label}
                </div>
              ))}
          </div>
        </div>
        <div className="caser-tabcontent-container">{childrenWithProps}</div>
        {/* <div style={{ flexGrow: 1 }} /> */}
      </div>
    );
  }
}

export class Tab extends React.Component {
  render() {
    return (
      <div className={"caser-tab-element" + (this.props.open ? " open" : "")}>
        {this.props.renderClosed === false && this.props.open === false
          ? null
          : this.props.children}
      </div>
    );
  }
}
