import React from "react";
import DBContext from "./../DBContext";
// import Displays from "./../classes/displays";
import Display, {
  Location,
  Element,
  View,
} from "avc-client-tools/scripts/displays/index.mjs";
import Media from "avc-client-tools/scripts/videoStream/streaming";
export default class Globals extends React.Component {
  static contextType = DBContext;
  render() {
    if (!this.context.globals) {
      this.context.globals = {};
      // this.context.globals.displays = new Displays(this.context);
      this.context.globals.media = new Media({
        location: "none",
        stream: false,
      });
      this.context.globals.display = (id) => new Display(this.context, id);
      this.context.globals.location = (id) => new Location(this.context, id);
      this.context.globals.element = (id, displayId) =>
        new Element(this.context, id, displayId);
      this.context.globals.view = (id, displayId) =>
        new View(this.context, id, displayId);
    }
    return this.props.children;
  }
}
