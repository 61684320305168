import React from "react";
import {
  CaserRadioField,
  CaserSelectField,
  CaserNameField,
  CaserTextField,
} from "../Tools/mainComponents/Fields";
// import Card from "../Tools/mainComponents/Card";
// import TextBar from "../Tools/mainComponents/textBar";
import DBContext from "../DBContext";
// import { Box } from "../displays/DisplaySettings";
import Tabs, { Tab } from "../Tools/mainComponents/Tabs";
// import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import Schedule from "./components/scheduler";
import Translation from "./Translation";
import Users from "./components/users";
import ControlBasic from "./ControlBasic";
export default class Control extends React.Component {
  static contextType = DBContext;
  state = { password: "" };
  // powerHeirarchy = {
  //   church: {
  //     title: "Church",
  //     children: {
  //       cameras: {
  //         title: "Camera System",
  //         children: {
  //           SonyCams: { title: "Sony Cameras", device: "IPCameraPower" },
  //           VaddioCams: {
  //             title: "ATEM and Vaddio Cameras",
  //             device: "cameraPower",
  //           },
  //         },
  //       },

  //       audio: { title: "Church Audio", device: "audioPower" },
  //       tvs: {
  //         children: {
  //           DeaconTVs: {
  //             title: "Deacon TVs",
  //             children: {
  //               nDTV: {
  //                 title: "North Deacons TV",
  //                 deviceName: "northDeaconTV",
  //                 device: "northDeaconTVPower",
  //               },
  //               sDTV: {
  //                 title: "South Deacons TV",
  //                 device: "southDeaconTVPower",
  //                 deviceName: "southDeaconTV",
  //               },
  //             },
  //           },
  //           NaveTVs: {
  //             title: "Nave TVs",
  //             children: {
  //               nNTV: {
  //                 title: "North Nave TV",
  //                 device: "northNaveTVPower",
  //                 deviceName: "northNaveTV",
  //               },
  //               sNTV: {
  //                 title: "South Nave TV",
  //                 device: "southNaveTVPower",
  //                 deviceName: "southNaveTV",
  //               },
  //             },
  //           },
  //         },
  //         title: "TVs",
  //       },
  //     },
  //   },
  //   hall: {
  //     title: "Hall",
  //     children: {
  //       audio: { title: "Audio Power", device: "hallAudioPower" },
  //       cameras: { title: "Camera Power", device: "hallCameraPower" },
  //     },
  //   },
  // };
  videoMatrixInputs = [
    { optionId: 1, label: "Mac Mini" },
    { optionId: 2, label: "OCL" },
    { optionId: 3, label: "Apple TV" },
    { optionId: 4, label: "Stream" },
  ];
  // powerState(levelArray) {
  //   let level = this.powerLevelFromArray(levelArray);
  //   let deviceStates = [];
  //   let searchInLevel = (level) => {
  //     if (level.children) {
  //       for (let chName in level.children) {
  //         searchInLevel(level.children[chName]);
  //       }
  //     }
  //     if (level.device) {
  //       deviceStates.push(this.props.main.SmartControl.getState(level.device));
  //     }
  //   };
  //   searchInLevel(level);
  //   if (deviceStates.every((val) => val === true)) return true;
  //   if (deviceStates.every((val) => val === false)) return false;
  //   return null;
  // }
  // setPowerState(levelArray, val) {
  //   let level = this.powerLevelFromArray(levelArray);
  //   let setInLevel = (level) => {
  //     if (level.children) {
  //       for (let chName in level.children) {
  //         setInLevel(level.children[chName]);
  //       }
  //     }
  //     if (level.device) {
  //       this.props.main.SmartControl.runAction(level.device, {
  //         value: val,
  //         set: val,
  //         device: level.deviceName,
  //       });
  //     }
  //   };
  //   setInLevel(level);
  // }
  // powerLevelFromArray(levelArray) {
  //   let level = null;
  //   for (let lv of levelArray) {
  //     if (level !== null) level = level.children;
  //     else level = this.powerHeirarchy;
  //     level = level[lv];
  //   }
  //   return level;
  // }
  // renderPowerLevel(levelArray) {
  //   let level = this.powerLevelFromArray(levelArray);
  //   return (
  //     <>
  //       <PowerRow
  //         indentLevel={levelArray.length - 1}
  //         title={level.title}
  //         value={this.powerState(levelArray)}
  //         onChange={(val) => this.setPowerState(levelArray, val)}
  //         underline={level.children ? true : false}
  //       />
  //       {level.children &&
  //         Object.keys(level.children).map((key) =>
  //           this.renderPowerLevel([...levelArray, key])
  //         )}
  //     </>
  //   );
  // }
  render() {
    console.log(this.context);
    return (
      <Tabs>
        <Tab label={"Control"}>
          <Header>Power</Header>
          <ControlBasic />
          {/* {this.renderPowerLevel(["church"])}
          {this.renderPowerLevel(["hall"])} */}
          {/* <Row>
            <Shortcut
              state={
                this.props.main.SmartControl.getState("northDeaconTVPower") &&
                this.props.main.SmartControl.getState("southDeaconTVPower")
                  ? "primary"
                  : "secondary"
              }
              onClick={(val) => {
                let set =
                  this.props.main.SmartControl.getState("northDeaconTVPower") &&
                  this.props.main.SmartControl.getState("southDeaconTVPower")
                    ? false
                    : true;
                this.props.main.SmartControl.runAction("northDeaconTVPower", {
                  set,
                  device: "northDeaconTV",
                });
                this.props.main.SmartControl.runAction("southDeaconTVPower", {
                  set,
                  device: "southDeaconTV",
                });
              }}
            >
              Deacons TVs
            </Shortcut>
            <Shortcut
              state={
                this.props.main.SmartControl.getState("northNaveTVPower") &&
                this.props.main.SmartControl.getState("southNaveTVPower")
                  ? "primary"
                  : "secondary"
              }
              onClick={(val) => {
                let set =
                  this.props.main.SmartControl.getState("northNaveTVPower") &&
                  this.props.main.SmartControl.getState("southNaveTVPower")
                    ? false
                    : true;
                this.props.main.SmartControl.runAction("northNaveTVPower", {
                  set,
                  device: "northNaveTV",
                });

                this.props.main.SmartControl.runAction("southNaveTVPower", {
                  set,
                  device: "southNaveTV",
                });
              }}
            >
              Congregation TVs
            </Shortcut>
            <Shortcut
              state={
                this.props.main.SmartControl.getState("northDeaconTVPower") &&
                this.props.main.SmartControl.getState("southDeaconTVPower") &&
                this.props.main.SmartControl.getState("northNaveTVPower") &&
                this.props.main.SmartControl.getState("southNaveTVPower")
                  ? "primary"
                  : "secondary"
              }
              onClick={(val) => {
                let set =
                  this.props.main.SmartControl.getState("northDeaconTVPower") &&
                  this.props.main.SmartControl.getState("southDeaconTVPower") &&
                  this.props.main.SmartControl.getState("northNaveTVPower") &&
                  this.props.main.SmartControl.getState("southNaveTVPower")
                    ? false
                    : true;
                this.props.main.SmartControl.runAction("northDeaconTVPower", {
                  set,
                  device: "northDeaconTV",
                });
                this.props.main.SmartControl.runAction("southDeaconTVPower", {
                  set,
                  device: "southDeaconTV",
                });

                this.props.main.SmartControl.runAction("northNaveTVPower", {
                  set,
                  device: "northNaveTV",
                });

                this.props.main.SmartControl.runAction("southNaveTVPower", {
                  set,
                  device: "southNaveTV",
                });
              }}
            >
              TVs Power
            </Shortcut>
          </Row> */}
          {/* <PowerRow title={"Church"} /> */}
          {/* <Row>
            <CaserRadioField
              opts={[
                { optionId: false, label: "Off" },
                { optionId: true, label: "On" },
              ]}
              label={"Camera Power"}
              value={this.props.main.SmartControl.getState("cameraPower")}
              onChange={(val) =>
                this.props.main.SmartControl.runAction("cameraPower", {
                  value: val,
                })
              }
            ></CaserRadioField>
            <CaserRadioField
              opts={[
                { optionId: false, label: "Off" },
                { optionId: true, label: "On" },
              ]}
              label={"Church Audio Power"}
              value={this.props.main.SmartControl.getState("audioPower")}
              onChange={(val) =>
                this.props.main.SmartControl.runAction("audioPower", {
                  value: val,
                })
              }
            ></CaserRadioField>
            <CaserRadioField
              opts={[
                { optionId: false, label: "Off" },
                { optionId: true, label: "On" },
              ]}
              label={"Hall Audio Power"}
              value={this.props.main.SmartControl.getState("hallAudioPower")}
              onChange={(val) =>
                this.props.main.SmartControl.runAction("hallAudioPower", {
                  set: val,
                  device: "HallAudio",
                })
              }
            ></CaserRadioField>
          </Row>
          <Row>
            <CaserRadioField
              opts={[
                { optionId: false, label: "Off" },
                { optionId: true, label: "On" },
              ]}
              label={this.props.main.SmartControl.getLabel(
                "northDeaconTVPower"
              )}
              value={this.props.main.SmartControl.getState(
                "northDeaconTVPower"
              )}
              onChange={(val) =>
                this.props.main.SmartControl.runAction("northDeaconTVPower", {
                  set: val,
                  device: "northDeaconTV",
                })
              }
            ></CaserRadioField>
            <CaserRadioField
              opts={[
                { optionId: false, label: "Off" },
                { optionId: true, label: "On" },
              ]}
              label={this.props.main.SmartControl.getLabel(
                "southDeaconTVPower"
              )}
              value={this.props.main.SmartControl.getState(
                "southDeaconTVPower"
              )}
              onChange={(val) =>
                this.props.main.SmartControl.runAction("southDeaconTVPower", {
                  set: val,
                  device: "southDeaconTV",
                })
              }
            ></CaserRadioField>
            <CaserRadioField
              opts={[
                { optionId: false, label: "Off" },
                { optionId: true, label: "On" },
              ]}
              label={this.props.main.SmartControl.getLabel("northNaveTVPower")}
              value={this.props.main.SmartControl.getState("northNaveTVPower")}
              onChange={(val) =>
                this.props.main.SmartControl.runAction("northNaveTVPower", {
                  set: val,
                  device: "northNaveTV",
                })
              }
            ></CaserRadioField>
            <CaserRadioField
              opts={[
                { optionId: false, label: "Off" },
                { optionId: true, label: "On" },
              ]}
              label={this.props.main.SmartControl.getLabel("southNaveTVPower")}
              value={this.props.main.SmartControl.getState("southNaveTVPower")}
              onChange={(val) =>
                this.props.main.SmartControl.runAction("southNaveTVPower", {
                  set: val,
                  device: "southNaveTV",
                })
              }
            ></CaserRadioField>
          </Row> */}
          {/* </Tab>
         
            <Shortcut
              state={
                this.props.main.SmartControl.getState("oclDisplay")
                  ? "primary"
                  : "secondary"
              }
              onClick={(val) =>
                this.props.main.SmartControl.toggleAction("oclDisplay")
              }
            >
              OCL Display
            </Shortcut>
          </div>
          <Row>
            <CaserRadioField
              opts={[
                { optionId: "atem", label: "ATEM" },
                { optionId: "obs", label: "OBS (Software)" },
                { optionId: "wc", label: "Wirecast (Software)" },
              ]}
              label={"Camera Switcher Device"}
              value={this.props.main.SmartControl.getState(
                "ChurchSwitcherDevice"
              )}
              onChange={(val) =>
                this.props.main.SmartControl.runAction("ChurchSwitcherDevice", {
                  value: val,
                })
              }
            ></CaserRadioField>
            <CaserRadioField
              opts={[
                { optionId: "obs", label: "OBS (Software)" },
                { optionId: "wc", label: "Wirecast (Software)" },
              ]}
              label={"Camera Streaming Device"}
              value={this.props.main.SmartControl.getState(
                "ChurchStreamingDevice"
              )}
              onChange={(val) =>
                this.props.main.SmartControl.runAction(
                  "ChurchStreamingDevice",
                  { value: val }
                )
              }
            ></CaserRadioField>
          </Row>
          <Header>OBS</Header>
          <Row>
            <CaserRadioField
              opts={[{ optionId: true, label: "Open" }]}
              label={"Camera Window"}
              value={this.props.main.SmartControl.getState(
                "openStreamingDocument"
              )}
              onChange={(val) =>
                this.props.main.SmartControl.runAction(
                  "openStreamingDocument",
                  {}
                )
              }
            ></CaserRadioField>
            <CaserRadioField
              opts={[
                { optionId: false, label: "Off" },
                { optionId: true, label: "On" },
              ]}
              label={"Camera Recording"}
              value={this.props.main.SmartControl.getState("streamingRecord")}
              onChange={(val) =>
                this.props.main.SmartControl.runAction("streamingRecord", {
                  value: val,
                })
              }
            ></CaserRadioField>
            <CaserRadioField
              opts={[
                { optionId: false, label: "Off" },
                { optionId: true, label: "On" },
              ]}
              label={"Camera Streaming"}
              value={this.props.main.SmartControl.getState("streamingStream")}
              onChange={(val) =>
                this.props.main.SmartControl.runAction("streamingStream", {
                  value: val,
                })
              }
            ></CaserRadioField>
         
            <CaserRadioField
              opts={[{ optionId: true, label: "Open" }]}
              label={"Zoom OBS"}
              value={this.props.main.SmartControl.getState("openTeamsDocument")}
              onChange={(val) =>
                this.props.main.SmartControl.runAction("openTeamsDocument", {})
              }
            ></CaserRadioField>
            <CaserRadioField
              opts={[
                { optionId: false, label: "Off" },
                { optionId: true, label: "On" },
              ]}
              label={"Zoom Recording"}
              value={this.props.main.SmartControl.getState("teamsRecord")}
              onChange={(val) =>
                this.props.main.SmartControl.runAction("teamsRecord", {
                  value: val,
                })
              }
            ></CaserRadioField>
            <CaserRadioField
              opts={[
                { optionId: false, label: "Off" },
                { optionId: true, label: "On" },
              ]}
              label={"Zoom Streaming"}
              value={this.props.main.SmartControl.getState("teamsStream")}
              onChange={(val) =>
                this.props.main.SmartControl.runAction("teamsStream", {
                  value: val,
                })
              }
            ></CaserRadioField>
          </Row> */}
          {/* <Header>Preview Document</Header>
          <Row>
            <CaserRadioField opts={[{ optionId: true, label: "Open" }]} label={"Document"} value={this.props.main.SmartControl.getState("previewDocument")} onChange={(val) => this.props.main.SmartControl.runAction("previewDocument", { value: true })}></CaserRadioField>
          </Row> */}
          {/* </Tab>
        <Tab label={"HDMI"}> */}
          <Header>Video Matrix</Header>
          <Row>
            <Shortcut
              onClick={() =>
                this.props.main.SmartControl.runActions([
                  {
                    name: "matrixControl",
                    params: {
                      output: 1,
                      input: 2,
                    },
                  },
                  {
                    name: "matrixControl",
                    params: {
                      output: 2,
                      input: 2,
                    },
                  },
                  {
                    name: "matrixControl",
                    params: {
                      output: 3,
                      input: 2,
                    },
                  },
                  {
                    name: "matrixControl",
                    params: {
                      output: 4,
                      input: 2,
                    },
                  },
                  {
                    name: "matrixControl",
                    params: {
                      output: 5,
                      input: 2,
                    },
                  },
                  {
                    name: "matrixControl",
                    params: {
                      output: 6,
                      input: 1,
                    },
                  },
                ])
              }
              state={
                this.props.main.SmartControl.getState("matrixControl")[1] ===
                  2 &&
                this.props.main.SmartControl.getState("matrixControl")[2] ===
                  2 &&
                this.props.main.SmartControl.getState("matrixControl")[3] ===
                  2 &&
                this.props.main.SmartControl.getState("matrixControl")[4] ===
                  2 &&
                this.props.main.SmartControl.getState("matrixControl")[5] ===
                  2 &&
                this.props.main.SmartControl.getState("matrixControl")[6] === 1
                  ? "primary"
                  : "secondary"
              }
            >
              OCL Out
            </Shortcut>
            <Shortcut
              onClick={() =>
                this.props.main.SmartControl.runActions([
                  {
                    name: "matrixControl",
                    params: {
                      output: 1,
                      input: 4,
                    },
                  },
                  {
                    name: "matrixControl",
                    params: {
                      output: 2,
                      input: 4,
                    },
                  },
                  {
                    name: "matrixControl",
                    params: {
                      output: 3,
                      input: 4,
                    },
                  },
                  {
                    name: "matrixControl",
                    params: {
                      output: 4,
                      input: 4,
                    },
                  },
                  {
                    name: "matrixControl",
                    params: {
                      output: 5,
                      input: 4,
                    },
                  },
                  {
                    name: "matrixControl",
                    params: {
                      output: 6,
                      input: 1,
                    },
                  },
                ])
              }
              state={
                this.props.main.SmartControl.getState("matrixControl")[1] ===
                  4 &&
                this.props.main.SmartControl.getState("matrixControl")[2] ===
                  4 &&
                this.props.main.SmartControl.getState("matrixControl")[3] ===
                  4 &&
                this.props.main.SmartControl.getState("matrixControl")[4] ===
                  4 &&
                this.props.main.SmartControl.getState("matrixControl")[5] ===
                  4 &&
                this.props.main.SmartControl.getState("matrixControl")[6] === 1
                  ? "primary"
                  : "secondary"
              }
            >
              Streaming Out
            </Shortcut>
            <Shortcut
              onClick={() =>
                this.props.main.SmartControl.runActions([
                  {
                    name: "matrixControl",
                    params: {
                      output: 1,
                      input: 3,
                    },
                  },
                  {
                    name: "matrixControl",
                    params: {
                      output: 2,
                      input: 3,
                    },
                  },
                  {
                    name: "matrixControl",
                    params: {
                      output: 3,
                      input: 3,
                    },
                  },
                  {
                    name: "matrixControl",
                    params: {
                      output: 4,
                      input: 3,
                    },
                  },
                  {
                    name: "matrixControl",
                    params: {
                      output: 5,
                      input: 3,
                    },
                  },
                  {
                    name: "matrixControl",
                    params: {
                      output: 6,
                      input: 1,
                    },
                  },
                ])
              }
              state={
                this.props.main.SmartControl.getState("matrixControl")[1] ===
                  3 &&
                this.props.main.SmartControl.getState("matrixControl")[2] ===
                  3 &&
                this.props.main.SmartControl.getState("matrixControl")[3] ===
                  3 &&
                this.props.main.SmartControl.getState("matrixControl")[4] ===
                  3 &&
                this.props.main.SmartControl.getState("matrixControl")[5] ===
                  3 &&
                this.props.main.SmartControl.getState("matrixControl")[6] === 1
                  ? "primary"
                  : "secondary"
              }
            >
              Apple TV Out
            </Shortcut>
          </Row>
          <CaserRadioField
            label="Output 1: Projector"
            opts={this.videoMatrixInputs}
            value={this.props.main.SmartControl.getState("matrixControl")[1]}
            onChange={(val) =>
              this.props.main.SmartControl.runAction("matrixControl", {
                output: 1,
                input: val,
              })
            }
          ></CaserRadioField>
          <CaserRadioField
            label="Output 2: North Deacon TV"
            opts={this.videoMatrixInputs}
            value={this.props.main.SmartControl.getState("matrixControl")[2]}
            onChange={(val) =>
              this.props.main.SmartControl.runAction("matrixControl", {
                output: 2,
                input: val,
              })
            }
          ></CaserRadioField>
          <CaserRadioField
            label="Output 3: South Deacon TV"
            opts={this.videoMatrixInputs}
            value={this.props.main.SmartControl.getState("matrixControl")[3]}
            onChange={(val) =>
              this.props.main.SmartControl.runAction("matrixControl", {
                output: 3,
                input: val,
              })
            }
          ></CaserRadioField>
          <CaserRadioField
            label="Output 4: Congragation TVs"
            opts={this.videoMatrixInputs}
            value={this.props.main.SmartControl.getState("matrixControl")[4]}
            onChange={(val) =>
              this.props.main.SmartControl.runAction("matrixControl", {
                output: 4,
                input: val,
              })
            }
          ></CaserRadioField>
          <CaserRadioField
            label="Output 5: Bottom AV Monitor"
            opts={this.videoMatrixInputs}
            value={this.props.main.SmartControl.getState("matrixControl")[5]}
            onChange={(val) =>
              this.props.main.SmartControl.runAction("matrixControl", {
                output: 5,
                input: val,
              })
            }
          ></CaserRadioField>
          <CaserRadioField
            label="Output 6: Top AV Monitor"
            opts={this.videoMatrixInputs}
            value={this.props.main.SmartControl.getState("matrixControl")[6]}
            onChange={(val) =>
              this.props.main.SmartControl.runAction("matrixControl", {
                output: 6,
                input: val,
              })
            }
          ></CaserRadioField>
          <CaserRadioField
            label="Output 7: ATEM Input 8"
            opts={this.videoMatrixInputs}
            value={this.props.main.SmartControl.getState("matrixControl")[7]}
            onChange={(val) =>
              this.props.main.SmartControl.runAction("matrixControl", {
                output: 7,
                input: val,
              })
            }
          ></CaserRadioField>
          <CaserRadioField
            label="Output 8: Hall"
            opts={this.videoMatrixInputs}
            value={this.props.main.SmartControl.getState("matrixControl")[8]}
            onChange={(val) =>
              this.props.main.SmartControl.runAction("matrixControl", {
                output: 8,
                input: val,
              })
            }
          ></CaserRadioField>
        </Tab>
        {this.props.main.userInGroup("Translation") ? (
          <Tab label={"Translation"}>
            <Translation {...this.props} />
          </Tab>
        ) : null}
        {this.props.main.userInGroup("Admin") ? (
          <Tab label={"Scheduler"}>
            <Schedule {...this.props} />
          </Tab>
        ) : null}
        {this.props.main.userInGroup("Admin") ? (
          <Tab label={"Users"}>
            <Users {...this.props} />
          </Tab>
        ) : null}
        <Tab label={"Settings"}>
          <Header>Location</Header>
          <CaserSelectField
            value={this.props.main.state.location || "remote"}
            onChange={(key) => this.props.main.switchLocation(key)}
            opts={(this.context.displays.locations || []).map((l) => {
              let loc = this.context.globals.location(l.id);
              return {
                optionId: loc.name,
                label: loc.label,
              };
            })}
            noBounce
            label="Controller Location"
          />
          <Header>IP Settings</Header>
          <CaserTextField
            label={"North Deacon TV"}
            value={this.context.smartControl.NorthDeachTV}
            onChange={(v) => {
              this.context.smartControl.NorthDeachTV = v;
            }}
          />
          <CaserTextField
            label={"South Deacon TV"}
            value={this.context.smartControl.SouthDeachTV}
            onChange={(v) => {
              this.context.smartControl.SouthDeachTV = v;
            }}
          />
          <CaserTextField
            label={"North Nave TV"}
            value={this.context.smartControl.NorthNaveTV}
            onChange={(v) => {
              this.context.smartControl.NorthNaveTV = v;
            }}
          />
          <CaserTextField
            label={"South Nave TV"}
            value={this.context.smartControl.SouthNaveTV}
            onChange={(v) => {
              this.context.smartControl.SouthNaveTV = v;
            }}
          />
          {/* <Header>Computers</Header>
          <LocationsConfig /> */}
          {/* </Tab>

        <Tab label={"Account"}> */}
          <Header>Account Settings</Header>

          <Row>
            <CaserNameField
              label={"Name"}
              value={{ dbObj: this.props.main.user.name || {} }}
              onChange={(v, n) => (this.props.main.user.name[n] = v)}
            />
          </Row>
          <Row>
            <CaserTextField
              label={"Username"}
              value={this.props.main.user.username}
              onChange={(v) => {
                this.props.main.user.username = v;
              }}
            />
            <CaserTextField
              label={"Password"}
              value={this.state.password}
              type={"Password"}
              onChange={(password) => {
                this.setState({ password });
                this.context.ws.requestWithResponse(
                  "Users",
                  "setUserPassword",
                  [this.props.main.user.id, password]
                );
              }}
            />
          </Row>
        </Tab>
        {/* <Tab
          label={
            "Clients (" +
            this.context.mediaServer.mainStates.clients.length +
            ")"
          }
        >
          <Card
            title={"Total"}
            right={
              <div>
                <AiFillCaretUp />{" "}
                {Math.round(
                  this.context.mediaServer.mainStates.totals.producersBitrate /
                    1000
                )}{" "}
                kbps | <AiFillCaretDown />{" "}
                {Math.round(
                  this.context.mediaServer.mainStates.totals.consumersBitrate /
                    1000
                )}{" "}
                kbps
              </div>
            }
          ></Card>
          {this.context.mediaServer.mainStates.clients.map(
            (client, clientIndex) => (
              <Card
                title={`${clientIndex + 1}. ${client.name || client.id}`}
                key={clientIndex}
                right={
                  <div>
                    {client.producerBitrate ? (
                      <>
                        <AiFillCaretUp />{" "}
                        {Math.round(client.producerBitrate / 1000)} kbps{" "}
                      </>
                    ) : null}
                    {client.consumerBitrate ? (
                      <>
                        <AiFillCaretDown />{" "}
                        {Math.round(client.consumerBitrate / 1000)} kbps{" "}
                      </>
                    ) : null}
                  </div>
                }
              >
                {client.producers.length ? (
                  <>
                    <div
                      style={{ padding: 5, fontWeight: 600, color: "darkred" }}
                    >
                      Producers
                    </div>
                    {client.producers.map((producer, index) => (
                      <Box
                        key={index}
                        style={{ padding: "5px 20px" }}
                        right={
                          !producer.open
                            ? "Closed"
                            : !producer.active
                            ? "Paused"
                            : `Health: ${
                                producer.score
                              } | Bitrate: ${Math.round(
                                producer.bitrate / 1000
                              )} kbps`
                        }
                      >
                        {index + 1}.{" "}
                        {
                          this.props.main.streaming.deviceConfig[producer.name]
                            ?.description
                        }
                      </Box>
                    ))}
                  </>
                ) : null}
                {client.consumers.length ? (
                  <>
                    <div
                      style={{
                        padding: 5,
                        fontWeight: 600,
                        color: "darkgreen",
                      }}
                    >
                      Consumers
                    </div>
                    {client.consumers.map((consumer, index) => (
                      <Box
                        key={index}
                        style={{ padding: "5px 20px" }}
                        right={
                          !consumer.open
                            ? "Closed"
                            : !consumer.active
                            ? "Paused"
                            : `Health: ${
                                consumer.score
                              } | Bitrate: ${Math.round(
                                consumer.bitrate / 1000
                              )} kbps`
                        }
                      >
                        {index + 1}.{" "}
                        {
                          this.props.main.streaming.deviceConfig[consumer.name]
                            ?.description
                        }
                      </Box>
                    ))}
                  </>
                ) : null}
              </Card>
            )
          )}
        </Tab> */}
      </Tabs>
    );
  }
}

let Header = (props) => {
  return (
    <div className={"ControlHeader"}>
      <div
        className={"ControlHeader-Line"}
        style={{ width: "20px", flexGrow: 0 }}
      ></div>
      <div className={"ControlHeader-Text"}>{props.children}</div>
      <div className={"ControlHeader-Line"}></div>
    </div>
  );
};
let Row = (props) => (
  <div className={"ControlShortcutContainer"}>{props.children}</div>
);
let Shortcut = (props) => {
  return (
    <div
      className={"ControlShortcutButton " + (props.state || "default")}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

// let PowerRow = (props) => (
//   <>
//     <div
//       className={"ControlShortcutContainer"}
//       style={{ justifyItems: "bottom" }}
//     >
//       <div
//         style={{
//           flexGrow: 0,
//           width: (props.indentLevel ?? 0) * 20 + "px",
//         }}
//       ></div>
//       <div
//         style={{
//           display: "flex",
//           flexGrow: 1,
//           borderBottom: props.underline ? "solid thin black" : "none",
//           alignItems: "center",
//         }}
//       >
//         <div
//           style={{
//             flexGrow: 1,
//             fontWeight: 800,
//             fontSize: 18,
//             marginLeft: 5,
//             height: "22px",
//           }}
//         >
//           {props.title}
//         </div>
//         <CaserRadioField
//           opts={[
//             { optionId: false, label: "Off" },
//             { optionId: true, label: "On" },
//           ]}
//           value={props.value}
//           onChange={props.onChange}
//         ></CaserRadioField>
//       </div>
//     </div>
//   </>
// );
export { Header, Row, Shortcut };
