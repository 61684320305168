import React from "react";
import {
  CaserRadioField,
  CaserNameField,
  CaserTextField,
} from "../../Tools/mainComponents/Fields";
import ConfirmButton from "../../Tools/mainComponents/confirmButton";

import DBContext from "../../DBContext";
import { Title, Box } from "../../displays/DisplaySettings";
import { Row } from "../Control";
import { MdAdd, MdExpandMore, MdExpandLess } from "react-icons/md";
import Button from "../../Tools/mainComponents/Button";
import { v4 } from "uuid";
export default class UsersAndGroups extends React.Component {
  static contextType = DBContext;

  render() {
    return (
      <div>
        <Title
          right={
            <div
              onClick={(e) => {
                this.context.admin.users.push({
                  username: "",
                  name: {},
                  id: v4(),
                  password: "",
                  groups: [],
                  enabled: true,
                });
              }}
            >
              <MdAdd />
            </div>
          }
        >
          Users
        </Title>
        {(this.context.admin.users || []).map((user, index) => (
          <User
            user={user}
            key={user.id}
            users={this.context.admin.users}
            index={index}
            groups={this.context.admin.groups}
            roles={this.context.admin.roles}
          />
        ))}
        <Title
          right={
            <div
              onClick={(e) => {
                this.context.admin.roles.push({
                  name: "",
                  path: "",
                  id: v4(),
                });
              }}
            >
              <MdAdd />
            </div>
          }
        >
          Roles
        </Title>
        {(this.context.admin.roles || []).map((role, index) => (
          <Role
            role={role}
            key={role.id}
            roles={this.context.admin.roles}
            index={index}
          />
        ))}
        <Title
          right={
            <div
              onClick={(e) => {
                this.context.admin.groups.push({
                  name: "",
                  id: v4(),
                });
              }}
            >
              <MdAdd />
            </div>
          }
        >
          Groups
        </Title>
        {(this.context.admin.groups || []).map((group, index) => (
          <Group
            group={group}
            key={group.id}
            groups={this.context.admin.groups}
            index={index}
          />
        ))}
        <Title
          right={
            <div
              onClick={(e) => {
                this.context.admin.sessions = {};
              }}
            >
              Terminate All
            </div>
          }
        >
          Sessions
        </Title>
        {Object.keys(this.context.admin.sessions).map((sessionId, index) => (
          <Session
            session={this.context.admin.sessions[sessionId]}
            sessions={this.context.admin.sessions}
            users={this.context.admin.users}
            sessionId={sessionId}
            key={sessionId}
            index={index}
          />
        ))}
      </div>
    );
  }
}

class User extends React.Component {
  static contextType = DBContext;

  state = {
    FullOpen: false,
    password: "",
  };
  render() {
    return (
      <>
        <Box
          color={"#666"}
          id={this.props.id}
          left={
            <Button
              onClick={(e) => {
                this.setState({
                  FullOpen: this.state.FullOpen ? false : true,
                });
                e.stopPropagation();
              }}
            >
              {!this.state.FullOpen ? <MdExpandMore /> : <MdExpandLess />}
            </Button>
          }
          right={
            this.props.user.username && "(" + this.props.user.username + ")"
          }
          bottom={
            this.state.FullOpen && (
              <div
                style={{
                  backgroundColor: "#fff",
                  width: "100%",
                  margin: 10,
                  padding: 10,
                  borderRadius: 10,
                }}
              >
                <Row>
                  <CaserNameField
                    label={"Name"}
                    value={{ dbObj: this.props.user.name || {} }}
                    onChange={(v, n) => (this.props.user.name[n] = v)}
                  />
                </Row>
                <Row>
                  <CaserTextField
                    label={"Username"}
                    value={this.props.user.username}
                    onChange={(v) => {
                      this.props.user.username = v;
                    }}
                  />
                  <CaserTextField
                    label={"Password"}
                    value={this.state.password}
                    type={"Password"}
                    onChange={(password) => {
                      this.setState({ password });
                      this.context.ws.requestWithResponse(
                        "Users",
                        "setUserPassword",
                        [this.props.user.id, password]
                      );
                    }}
                  />
                </Row>
                <Row>
                  <CaserRadioField
                    label={"User Role"}
                    value={this.props.user.role}
                    opts={this.props.roles.map((role) => ({
                      optionId: role.name,
                      label: role.name,
                    }))}
                    onChange={(val) => (this.props.user.role = val)}
                  />
                </Row>
                <Row>
                  <CaserRadioField
                    label={"User Enabled"}
                    value={this.props.user.enabled}
                    opts={[
                      { optionId: false, label: "Disabled" },
                      { optionId: true, label: "Enabled" },
                    ]}
                    onChange={(val) => (this.props.user.enabled = val)}
                  />
                </Row>

                <Row>
                  <div className={"caser-input-label"}>Groups</div>
                </Row>

                {(this.props.groups || [])
                  .sort((a, b) => {
                    if (a.name > b.name) return 1;
                    else if (a.name < b.name) return -1;
                    else return 0;
                  })
                  .map((group, index) => (
                    <Button
                      type={
                        this.props.user.groups.includes(group.id)
                          ? "primary"
                          : "secondary"
                      }
                      onClick={() => {
                        let groupIndex = this.props.user.groups.indexOf(
                          group.id
                        );
                        if (groupIndex > -1)
                          this.props.user.groups.splice(groupIndex, 1);
                        else this.props.user.groups.push(group.id);
                      }}
                    >
                      {group.name}
                    </Button>
                  ))}
                <div style={{ textAlign: "center", margin: 10 }}>
                  <ConfirmButton
                    onClick={() => this.props.users.splice(this.props.index, 1)}
                  >
                    Delete User
                  </ConfirmButton>
                </div>
              </div>
            )
          }
        >
          {this.props.user.name.firstname} {this.props.user.name.lastname}
        </Box>
      </>
    );
  }
}

class Group extends React.Component {
  state = {
    FullOpen: false,
  };
  render() {
    return (
      <>
        <Box
          id={this.props.id}
          color={"#222"}
          left={
            <Button
              onClick={(e) => {
                this.setState({
                  FullOpen: this.state.FullOpen ? false : true,
                });
                e.stopPropagation();
              }}
            >
              {!this.state.FullOpen ? <MdExpandMore /> : <MdExpandLess />}
            </Button>
          }
          bottom={
            this.state.FullOpen && (
              <div
                style={{
                  backgroundColor: "#fff",
                  width: "100%",
                  margin: 10,
                  padding: 10,

                  borderRadius: 10,
                }}
              >
                <Row>
                  <CaserTextField
                    label={"Group Name"}
                    value={this.props.group.name || ""}
                    onChange={(v) => (this.props.group.name = v)}
                  />
                </Row>
                <div style={{ textAlign: "center", margin: 10 }}>
                  <ConfirmButton
                    onClick={() =>
                      this.props.groups.splice(this.props.index, 1)
                    }
                  >
                    Delete Group
                  </ConfirmButton>
                </div>
              </div>
            )
          }
        >
          {this.props.group.name}
        </Box>
      </>
    );
  }
}

class Role extends React.Component {
  state = {
    FullOpen: false,
  };
  render() {
    return (
      <>
        <Box
          id={this.props.id}
          color={"#444"}
          left={
            <Button
              onClick={(e) => {
                this.setState({
                  FullOpen: this.state.FullOpen ? false : true,
                });
                e.stopPropagation();
              }}
            >
              {!this.state.FullOpen ? <MdExpandMore /> : <MdExpandLess />}
            </Button>
          }
          bottom={
            this.state.FullOpen && (
              <div
                style={{
                  backgroundColor: "#fff",
                  width: "100%",
                  margin: 10,
                  padding: 10,

                  borderRadius: 10,
                }}
              >
                <Row>
                  <CaserTextField
                    label={"Role Name"}
                    value={this.props.role.name || ""}
                    onChange={(v) => (this.props.role.name = v)}
                  />
                </Row>
                <Row>
                  <CaserTextField
                    label={"Default Path"}
                    value={this.props.role.path || ""}
                    onChange={(v) => (this.props.role.path = v)}
                  />
                </Row>
                <div style={{ textAlign: "center", margin: 10 }}>
                  <ConfirmButton
                    onClick={() => this.props.roles.splice(this.props.index, 1)}
                  >
                    Delete Role
                  </ConfirmButton>
                </div>
              </div>
            )
          }
        >
          {this.props.role.name}
        </Box>
      </>
    );
  }
}

class Session extends React.Component {
  state = {
    FullOpen: false,
  };
  get user() {
    return this.props.users.find((v) => v.id === this.props.session.userId);
  }
  render() {
    return (
      <>
        <Box
          id={this.props.id}
          color={"#000"}
          // left={
          //   <Button
          //     onClick={(e) => {
          //       this.setState({
          //         FullOpen: this.state.FullOpen ? false : true,
          //       });
          //       e.stopPropagation();
          //     }}
          //   >
          //     {!this.state.FullOpen ? <MdExpandMore /> : <MdExpandLess />}
          //   </Button>
          // }
          right={
            <ConfirmButton
              onClick={() => delete this.props.sessions[this.props.sessionId]}
            >
              Terminate Session
            </ConfirmButton>
          }
          // bottom={
          //   this.state.FullOpen && (
          //     <div
          //       style={{
          //         backgroundColor: "#fff",
          //         width: "100%",
          //         margin: 10,
          //         padding: 10,

          //         borderRadius: 10,
          //       }}
          //     >
          //       <Row>
          //         <CaserTextField
          //           label={"Role Name"}
          //           readOnly
          //           value={this.props.session.id || ""}
          //           onChange={(v) => {}}
          //         />
          //       </Row>
          //       <div style={{ textAlign: "center", margin: 10 }}>
          //         <ConfirmButton
          //           onClick={() =>
          //             delete this.props.sessions[this.props.sessionId]
          //           }
          //         >
          //           Terminate Session
          //         </ConfirmButton>
          //       </div>
          //     </div>
          //   )
          // }
        >
          {this.user.name.firstname} {this.user.name.lastname} (
          {this.user.username}): {this.props.session.device}
        </Box>
      </>
    );
  }
}
