import React from "react";

let OCL = class extends React.Component {


  render() {
    return (
      <object
        data={"https://av.saintmarydelray.com/ocl/#/HTML/2"}
        // data={"https://google.com"}

        style={{ width: "100%", "height": "100%" }}
      >OCL</object>
    );
  }
};



export default OCL;
