import React from "react";
export default class Audio extends React.PureComponent {
  audioFile = React.createRef();
  componentDidUpdate(prevProps) {
    console.log(this.props.live, prevProps.live);
    if (this.props.live && !prevProps.live) {
      this.audioFile.current.currentTime = 0;
      this.audioFile.current.play();
    } else if (!this.props.live && prevProps.live) {
      this.audioFile.current.pause();
      this.audioFile.current.currentTime = 0;
    }
    // console.log("play", prevProps);
  }
  render() {
    return (
      <audio
        src={this.props.element.mediaUrl}
        ref={this.audioFile}
        muted={false}
        autoPlay={this.props.element.live}
        preload={"auto"}
        onEnded={() => (this.props.element.live = false)}
        onCanPlayThrough={() => (this.props.element.ready = true)}
      />
    );
  }
}
