import React from "react";

export default class Media extends React.Component {
  constructor() {
    super();
    this.state = {
      active: false,
    };
    this.canvas = React.createRef();
  }
  componentDidMount() {
    this.attachToCamView();
    this.onLive();
    this.Interval = setInterval(this.checkMedia, 1000);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.view !== this.props.view) {
      this.setMedia();
    }
  }
  componentWillUnmount() {
    this.detachFromCamView();
    clearInterval(this.Interval);
  }
  attachToCamView = () => {
    if (!this.device) {
      console.log("Device not loaded", this.device);
      return;
    }
    this.device.on("active", this.onLive);
    this.device.on("inactive", this.onEnd);
    this.attached = true;
  };
  detachFromCamView = () => {
    if (!this.device) {
      console.log("Device not loaded", this.device);
      return;
    }
    this.device.off("active", this.onLive);
    this.device.off("inactive", this.onEnd);
    this.attached = false;
  };
  savePNG = () => {
    var canvas = document.createElement("canvas");
    let camConfig = this.view.config;
    let border = camConfig.border || 4;
    let mainWidth = this.device.width;
    let mainHeight = this.device.height;
    canvas.width = mainWidth / camConfig.scale - 2 * border;
    canvas.height = mainHeight / camConfig.scale - 2 * border;
    var ctx = canvas.getContext("2d");

    ctx.drawImage(
      this.canvas.current,
      mainWidth * camConfig.anchor.x + border,
      mainHeight * camConfig.anchor.y + border,
      canvas.width,
      canvas.height,
      0,
      0,
      canvas.width,
      canvas.height
    );
    console.log(
      "savePreset",
      this.canvas.current,
      mainWidth * camConfig.anchor.x + border,
      mainHeight * camConfig.anchor.y + border,
      canvas.width,
      canvas.height,
      canvas.toDataURL("image/png")
    );
    return canvas.toDataURL("image/png");
  };
  setMedia = () => {
    if (this.canvas.current === null || !this.device) return;
    this.device.attach(this.canvas.current, { force: true });
  };
  checkMedia = () => {
    if (!this.device.shouldStream) return;
    if (!this.attached) this.attachToCamView();
    if (this.state.active && (!this.device || !this.device.active)) {
      console.log(this.state.active, this.device, this.device.active);
      this.onEnd();
    } else if (!this.state.active && this.device && this.device.active)
      this.onLive();
  };
  onLive = () => {
    if (!this.device.shouldStream) return;
    console.log("live");
    this.setState({ active: true });
    this.setMedia();
    this.props.onLive && this.props.onLive();
  };
  onEnd = () => {
    console.log("end");
    this.setState({ active: false });
    this.props.onEnd && this.props.onEnd();
  };
  get view() {
    if (
      !this.props.streaming ||
      !this.props.streaming.cameras ||
      !this.props.streaming.cameras[this.props.view]
    ) {
      return null;
    }
    return this.props.streaming.cameras[this.props.view];
  }
  get device() {
    if (
      !this.props.streaming.devices ||
      !this.props.streaming.devices[this.deviceName]
    ) {
      return null;
    }
    return this.props.streaming.devices[this.deviceName];
  }
  get deviceName() {
    if (this.view) return this.view.device;
    return this.props.view;
  }
  get VideoStyle() {
    if ((this.device || []).type === "audioinput")
      return {
        position: "absolute",
        top: 0,
        left: 0,
        width: 0,
        height: 0,
        padding: 0,
        margin: 0,
      };
    let res = {
      position: "absolute",
      top: 0,
      left: 0,
      width: 100 + "%",
    };
    if (this.view && this.view.config) {
      res.top =
        ((this.view.config.anchor || {}).y || 0) *
          (this.view.config.scale || 1) *
          -100 +
        "%";
      res.left =
        ((this.view.config.anchor || {}).x || 0) *
          (this.view.config.scale || 1) *
          -100 +
        "%";
      res.width = (this.view.config.scale || 1) * 100 + "%";
    }
    return res;
  }
  get ContainerStyle() {
    if ((this.device || []).type === "audioinput")
      return {
        position: "relative",
        top: 0,
        left: 0,
        width: 0,
        height: 0,
        padding: 0,
        margin: 0,
      };
    let res = {
      background: "#212121",
      width: "100%",
      paddingTop: this.props.manualConfig ? "auto" : "56.25%",
      height: this.props.manualConfig ? "100%" : "auto",
      position: "relative",
    };

    return res;
  }
  render() {
    return (
      <div style={this.ContainerStyle}>
        {(this.device || []).type === "audioinput" ? (
          <audio ref={this.canvas} id={this.props.id} />
        ) : (
          <video style={this.VideoStyle} ref={this.canvas} id={this.props.id} />
        )}
      </div>
    );
  }
}
