let PANDIRECTION = {
  LEFT: "left",
  STOP: "stop",
  RIGHT: "right",
};
let TILTDIRECTION = {
  UP: "up",
  STOP: "stop",
  DOWN: "down",
};
let ZOOMDIRECTION = {
  OUT: "out",
  STOP: "stop",
  IN: "in",
};
let FOCUSDIRECTION = {
  OUT: -1,
  STOP: 0,
  IN: 1,
};
// 
export default class CameraControl {
  constructor(main) {
    this.main = main;
    this.cameras = {
      1: new Camera(this, 1),
      2: new Camera(this, 2),
      3: new Camera(this, 3),
      4: new Camera(this, 4),
      5: new Camera(this, 5),
    };
  }
  shutterValues = {
    0: "1/1",
    1: "1/2",
    2: "1/4",
    3: "1/8",
    4: "1/15",
    5: "1/30",
    6: "1/60",
    7: "1/90",
    8: "1/100",
    9: "1/125",
    10: "1/180",
    11: "1/250",
    12: "1/350",
    13: "1/500",
    14: "1/725",
    15: "1/1000",
    16: "1/1500",
    17: "1/2000",
    18: "1/3000",
    19: "1/4000",
    20: "1/6000",
    21: "1/10000",
  };
  irisValues = {
    12: {
      0: "Close",
      5: "N/A",
      6: "N/A",
      7: "F9.6",
      8: "F8",
      9: "F6.8",
      10: "F5.6",
      11: "F4.8",
      12: "F4",
      13: "F3.3",
      14: "F2.8",
      15: "F2.4",
      16: "F2",
      17: "F1.8",
    },
    30: {
      0: "Close",
      5: "F14",
      6: "F11",
      7: "F9.6",
      8: "F8",
      9: "F6.8",
      10: "F5.6",
      11: "F4.8",
      12: "F4",
      13: "F3.4",
      14: "F2.8",
      15: "F2.4",
      16: "F2",
      17: "F1.6",
    },
  };
  expCompValues = {
    0: { iris: "-7", gain: "-10.5 dB" },
    1: { iris: "-6", gain: "-9 dB" },
    2: { iris: "-5", gain: "-7.5 dB" },
    3: { iris: "-4", gain: "-6 dB" },
    4: { iris: "-3", gain: "-4.5 dB" },
    5: { iris: "-2", gain: "-3 dB" },
    6: { iris: "-1", gain: "-1.5 dB" },
    7: { iris: "0", gain: "0 dB" },
    8: { iris: "+1", gain: "+1.5 dB" },
    9: { iris: "+2", gain: "+3 dB" },
    10: { iris: "+3", gain: "+4.5 dB" },
    11: { iris: "+4", gain: "+6 dB" },
    12: { iris: "+5", gain: "+7.5 dB" },
    13: { iris: "+6", gain: "+9 dB" },
    14: { iris: "+7", gain: "+10.5 dB" },
  };

  setFocus(camera, config) {}
  setWB(camera, config) {}
  setIris(camera, config) {}
  setChroma(camera, config) {}
  setRed(camera, config) {}
  setBlue(camera, config) {}
  setDetail(camera, config) {}
}

class Camera {
  constructor(control, camera) {
    this.control = control;
    this.main = control.main;
    this.camera = camera;
    this.drivesActive = [];
  }
  setPanTiltZoomDrive(config) {
    config = Object.assign(
      {
        panExp: 1,
        tiltExp: 1,
        zoomExp: 1.3,
        panSpeedMax: 13,
        tiltSpeedMax: 11,
        zoomSpeedMax: 7,
        pan: 0,
        tilt: 0,
        zoom: 0,
      },
      config
    );
    let command = {
      pan: {
        direction: "stop",
        speed: 0,
      },
      tilt: {
        direction: "stop",
        speed: 0,
      },
      zoom: {
        direction: "stop",
        speed: 0,
      },
    };

    //Pan
    command.pan.speed = Math.ceil(
      config.panSpeedMax * Math.pow(Math.abs(config.pan), config.panExp)
    );
    if (command.pan.speed !== 0) {
      if (!this.drivesActive.includes("pan")) this.drivesActive.push("pan");
      if (config.pan < 0) {
        command.pan.direction = PANDIRECTION.LEFT;
      } else if (config.pan > 0) {
        command.pan.direction = PANDIRECTION.RIGHT;
      }
    } else if (this.drivesActive.includes("pan")) {
      this.drivesActive.splice(this.drivesActive.indexOf("pan"), 1);
    }

    //Tilt
    command.tilt.speed = Math.ceil(
      config.tiltSpeedMax * Math.pow(Math.abs(config.tilt), config.tiltExp)
    );
    if (command.tilt.speed !== 0) {
      if (!this.drivesActive.includes("tilt")) this.drivesActive.push("tilt");

      if (config.tilt < 0) {
        command.tilt.direction = TILTDIRECTION.UP;
      } else if (config.tilt > 0) {
        command.tilt.direction = TILTDIRECTION.DOWN;
      }
    } else if (this.drivesActive.includes("tilt")) {
      this.drivesActive.splice(this.drivesActive.indexOf("tilt"), 1);
    }

    //Zoom
    command.zoom.speed = Math.ceil(
      config.zoomSpeedMax * Math.pow(Math.abs(config.zoom), config.zoomExp)
    );
    if (command.zoom.speed !== 0) {
      if (!this.drivesActive.includes("zoom")) this.drivesActive.push("zoom");
      if (config.zoom < 0) {
        command.zoom.direction = ZOOMDIRECTION.OUT;
      } else if (config.zoom > 0) {
        command.zoom.direction = ZOOMDIRECTION.IN;
      }
    } else if (this.drivesActive.includes("zoom")) {
      this.drivesActive.splice(this.drivesActive.indexOf("zoom"), 1);
    }
    console.log(command.pan);
    //Send Command
    this.main.ws.request("CameraControl", "sendCommandPTZ", [
      this.camera,
      command,
    ]);
  }
  setPanTiltZoomAbsolute(config) {
    let command = Object.assign(
      {
        speed: 1,
        pan: 0,
        tilt: 0,
        zoom: 0,
      },
      config
    );
    this.main.ws.request("CameraControl", "sendCommand", [
      this.camera,
      "setPTZAbsolute",
      command,
    ]);
    this.panTiltZoomAbsolute = command;
  }
  async getPanTiltZoomAbsolute() {
    this.panTiltZoomAbsolute = await this.main.ws.requestWithResponse(
      "CameraControl",
      "method",
      [this.camera, "getPTZ"]
    );
    return this.panTiltZoomAbsolute;
  }

  setFocusDrive(config) {
    config = Object.assign(
      {
        exp: 1,
        speedMax: 7,
        focus: 0,
      },
      config
    );
    let command = {
      focus: {
        direction: "stop",
        speed: 0,
      },
    };

    command.focus.speed = Math.ceil(
      config.speedMax * Math.pow(Math.abs(config.focus), config.exp)
    );
    if (command.focus.speed !== 0) {
      if (!this.drivesActive.includes("focus")) this.drivesActive.push("focus");
      if (config.focus < 0) {
        command.focus.direction = FOCUSDIRECTION.OUT;
      } else if (config.focus > 0) {
        command.focus.direction = FOCUSDIRECTION.IN;
      }
    } else if (this.drivesActive.includes("focus")) {
      this.drivesActive.splice(this.drivesActive.indexOf("focus"), 1);
    }
    this.main.ws.request("CameraControl", "sendCommand", [
      this.camera,
      "setFocus",
      command,
    ]);
  }
  set(command, data) {
    console.log(command);
    this.main.ws.requestWithResponse("CameraControl", "sendCommand", [
      this.camera,
      command,
      data,
    ]);
  }
  async get(command, data) {
    console.log("SEND QUERY");
    let res = await this.main.ws.requestWithResponse(
      "CameraControl",
      "sendQuery",
      [this.camera, command, data]
    );
    console.log("SEND QUERY-Done", res);

    return res;
  }
}
