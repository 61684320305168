import MSClient from "./MediaSoup.mjs";
import EventEmitter from "wolfy87-eventemitter";
export default class Streaming {
  constructor() {
    let config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
      location: "remote"
    };
    let devices = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let cameras = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    this.deviceName = null;
    this.shouldStream = true;
    this.devices = {};
    this.deviceConfig = devices;
    this.cameras = cameras;
    this.config = config;
    this.isLocal = this.config.location !== "remote";
    this.init();
    window.streaming = this;
  }
  async init() {
    if (this.config.stream !== false) this.msClient = new MSClient(this, this.isLocal);
    if (this.config.location === "oclMP") {
      this.initOCLCameraFeeds();
    } else if (this.config.location === "streamingMP" || this.config.location === "Streamer") {
      this.initStreamingCameraFeeds();
    } else if (this.config.location === "none") {} else {
      this.initRemoteCameraFeeds();
      // window.onfocus = this.initRemoteCameraFeeds.bind(this);
    }
  }

  async reInit() {
    await this.msClient.disconnect();
    this.msClient = new MSClient(this, this.isLocal);
    this.init();
  }
  async initRTC() {
    await this.msClient.connect();
    console.log("list", await this.listDevices());
  }
  addDevice(deviceName, type, config) {
    if (type === "local") this.devices[deviceName] = new LocalStreamingDevice(this, config);else this.devices[deviceName] = new RemoteStreamingDevice(this, config);
  }
  removeDevice(deviceName, obj) {
    delete this.devices[deviceName];
  }
  async initOCLCameraFeeds() {
    // if (!this.deviceConfig.mv) return;

    this.devices = {
      mv: new RemoteStreamingDevice(this, this.deviceConfig.mv),
      presonus: new RemoteStreamingDevice(this, this.deviceConfig.presonus)
    };
    await this.initRTC();
  }
  async initStreamingCameraFeeds() {
    this.devices = {
      mv: new LocalStreamingDevice(this, this.deviceConfig.mv),
      presonus: new LocalStreamingDevice(this, this.deviceConfig.presonus)
    };
    await this.initRTC();
  }
  async initRemoteCameraFeeds() {
    // if (!this.deviceConfig.mv) return;
    this.devices = {
      mv: new RemoteStreamingDevice(this, this.deviceConfig.mv),
      // ndiVideo: new RemoteStreamingDevice(this, this.deviceConfig.ndiVideo),
      // ndiAudio: new RemoteStreamingDevice(this, this.deviceConfig.ndiAudio),
      presonus: new RemoteStreamingDevice(this, this.deviceConfig.presonus)
    };
    await this.initRTC();
  }
  async wait() {
    let time = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1000;
    await new Promise(r => setTimeout(r, time));
  }
  get streams() {
    let streams = {};
    if (typeof this.devices === "object") for (let DeviceName in this.devices) {
      if (this.devices[DeviceName].stream !== null) streams[DeviceName] = this.devices[DeviceName].stream;
    }
    return streams;
  }
  get streamsReady() {
    for (let DeviceName in this.devices) {
      if (!this.devices[DeviceName].ready) return false;
    }
    return true;
  }
  async listDevices() {
    if (!navigator.mediaDevices) return [];
    let devices = (await navigator.mediaDevices.enumerateDevices()).map(v => {
      return {
        id: v.deviceId,
        groupId: v.groupId,
        type: v.kind,
        name: v.label
      };
    });
    if (window.require) {
      const {
        desktopCapturer
      } = window.require("electron");
      let screens = (await desktopCapturer.getSources({
        types: ["screen", "window"]
      })).map(v => {
        v.type = "desktopinput";
        delete v.thumbnail;
        return v;
      });
      devices = [...devices, ...screens];
    }
    return devices.filter(v => !v.name.includes("output"));
  }
}
export class StreamingDevice extends EventEmitter {
  constructor(streaming) {
    let config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    super();
    this.streaming = streaming || {};
    this.deviceId = null;
    this.deviceStream = null;
    console.log("Go", config);
    this.width = config.width || null;
    this.height = config.height || null;
    this.type = config.type;
    this.label = config.label;
    this.title = config.title || this.label;
    this.deviceShouldStream = config.shouldStream;
    this.constraintFunc = config.constraints;
    this.HB = setInterval(this.heartBeat.bind(this), 1000);
    this.staleActive = false;
    if (this.type === "videoinput" || this.type === "desktopinput") this.video = document.createElement("video");
  }
  get shouldStream() {
    return this.deviceShouldStream;
  }
  heartBeat() {}
  get getUserMedia() {
    return navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
  }
  attach(domElement) {
    let config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      muted: false,
      force: false
    };
    // if (this.type === "videoinput") {
    //   this.streaming.attachCanvas(domElement, this.title);
    //   return;
    // }
    if (!domElement) return false;
    // console.log((domElement.srcObject || {}).id, (this.stream || {}).id);
    if (config.force !== true && domElement.srcObject && this.stream && (domElement.srcObject || {}).id === (this.stream || {}).id) return false;
    domElement.setAttribute("playsinline", true);
    domElement.setAttribute("autoplay", true);
    domElement.setAttribute("muted", false);
    domElement.volume = 1;
    domElement.onloadedmetadata = function (e) {
      e.target.play();
      // e.target.muted = (config.muted && this.type === "audioinput");
    };

    domElement.srcObject = this.stream;
    if (this.stream) {
      this.stream.getTracks()[0].enabled = true;
    }
    if (!this.active) return false;
    return true;
  }
  play() {
    if (!this.video || !this.active) return false;
    if (this.video.srcObject && this.stream && (this.video.srcObject || {}).id === (this.stream || {}).id) return false;
    this.video.setAttribute("playsinline", true);
    this.video.setAttribute("autoplay", true);
    this.video.setAttribute("muted", false);
    if (this.width) this.video.setAttribute("width", this.width);
    if (this.height) this.video.setAttribute("height", this.height);
    this.video.volume = 1;
    this.video.onloadedmetadata = e => {
      e.target.play();
      // this.timerCallback();
      // e.target.muted = (config.muted && this.type === "audioinput");
    };

    this.video.srcObject = this.stream;
    if (this.stream) {
      this.stream.getTracks()[0].enabled = true;
    }
    if (!this.active) return false;
    return true;
  }
  detach(domElement) {
    domElement.srcObject = null;
  }
  // attachCanvases() {
  //   for (let canvasObj of canvases) {
  //     if (canvasObj.attached) continue;
  //     canvasObj.onAttach(this.video,this,canvasObj);
  //     canvasObj.attached = true;
  //   }
  // }
  // timerCallback() {
  //   // if (!this.video || !this.active) {
  //   //   return;
  //   // }
  //   // this.computeFrame();
  //   // setTimeout(() => {
  //   //   this.timerCallback();
  //   // }, 100);
  // }
  // computeFrame() {
  //   // if (!this.video || !this.active) {
  //   //   return;
  //   // }

  //   // // console.time("computeFrame" + this.title);
  //   // let canvases = this.streaming.getAttachedCanvases(this.title);
  //   // // console.log("Canvases", canvases.length);
  //   // for (let canvasObj of canvases) {
  //   //   let context = canvasObj.canvas.getContext("2d");
  //   //   // context.imageSmoothingQuality = "high";
  //   //   context.imageSmoothingEnabled = false;

  //   //   // context.ima = true;
  //   //   let config = {
  //   //     sx: 0,
  //   //     sy: 0,
  //   //     sWidth: this.video.videoWidth,
  //   //     sHeight: this.video.videoHeight,
  //   //     dx: 0,
  //   //     dy: 0,
  //   //     dWidth: "width",
  //   //     dHeight: "ScaleToWidth",
  //   //     ...this.streaming.cameras[canvasObj.camera].config,
  //   //     ...canvasObj.config,
  //   //   };

  //   //   if (config.dWidth === "width")
  //   //     config.dWidth = canvasObj.canvas.clientWidth;
  //   //   if (config.dHeight === "height")
  //   //     config.dWidth = canvasObj.canvas.clientHeight;
  //   //   if (config.dWidth === "ScaleToHeight")
  //   //     this.video.style.height =
  //   //       Math.round((config.dWidth / config.sWidth) * config.sHeight) + "px";

  //   //   if (config.dHeight === "ScaleToWidth")
  //   //     this.video.style.width =
  //   //       Math.round((config.dHeight / config.sHeight) * config.sWidth) + "px";

  //   //   // config.dWidth = Math.round(
  //   //   //   (config.dHeight / config.sHeight) * config.sWidth
  //   //   // );

  //   //   // config.dHeight = Math.round(
  //   //   //   (config.dWidth / config.sWidth) * config.sHeight
  //   //   // );

  //   //   context.drawImage(
  //   //     this.video,
  //   //     config.sx,
  //   //     config.sy,
  //   //     config.sWidth,
  //   //     config.sHeight,
  //   //     config.dx,
  //   //     config.dy,
  //   //     canvasObj.canvas.width,
  //   //     canvasObj.canvas.height
  //   //   );
  //   //   // context.translate(0.5, 0.5);
  //   // }
  //   // return;
  // }
  // onStreamActive = () => {
  //     console.log("DEVICES:active")

  //     this.emitEvent("active");
  // }
  // onStreamInactive = () => {
  //     this.emitEvent("inactive");
  //     console.log("DEVICES:inactive")
  // }

  get available() {
    return this.deviceId !== null;
  }
  get constraints() {
    return null;
  }
  get connected() {
    return this.available && this.deviceStream !== null;
  }
  get ready() {
    return this.available && this.connected;
  }
  get active() {
    if (!this.connected) return false;
    return this.connected && this.deviceStream.active && this.firstTrack.muted === false;
  }
  get stream() {
    if (!this.connected) return null;
    return this.deviceStream;
  }
  set stream(stream) {
    if (this.deviceStream) {
      //     if (stream.deviceId === this.deviceStream.deviceId) return;
      //     this.deviceStream.removeEventListener("active",this.onStreamActive)
      //     this.deviceStream.removeEventListener("inactive",this.onStreamInactive)
      if (stream === null) this.emitEvent("disconnected");else this.emitEvent("changed");
    }
    this.deviceStream = stream;
    if (this.deviceStream) {
      this.emitEvent("connected");
      //     this.deviceStream.onactive = this.onStreamActive()
      //     this.deviceStream.oninactive = this.onStreamInactive
      //     // this.deviceStream.addEventListener("inactive",this.onStreamInactive)
    }
  }

  get tracks() {
    if (this.stream === null) return null;
    return this.deviceStream.getTracks();
  }
  get videoTracks() {
    if (this.stream === null) return null;
    return this.deviceStream.getVideoTracks();
  }
  get audioTracks() {
    if (this.stream === null) return null;
    return this.deviceStream.getAudioTracks();
  }
  get firstTrack() {
    if (!Array.isArray(this.tracks) || !this.tracks.length) return null;
    return this.tracks[0];
  }
  get firstTrackCapabilities() {
    if (!Array.isArray(this.tracks) || !this.tracks.length) return null;
    return this.tracks[0].getCapabilities();
  }
  get firstTrackSettings() {
    if (!Array.isArray(this.tracks) || !this.tracks.length) return null;
    return this.tracks[0].getSettings();
  }
  get firstTrackConstraints() {
    if (!Array.isArray(this.tracks) || !this.tracks.length) return null;
    return this.tracks[0].getConstraints();
  }
  get firstVideoTrack() {
    if (!Array.isArray(this.videoTracks) || !this.videoTracks.length) return null;
    return this.tracks[0];
  }
  get firstAudioTrack() {
    if (!Array.isArray(this.audioTracks) || !this.audioTracks.length) return null;
    return this.tracks[0];
  }
  get info() {
    return {
      active: this.active,
      available: this.available,
      streaming: this.streaming,
      connected: this.connected,
      tracks: this.tracks,
      deviceStream: this.deviceStream,
      ready: this.ready,
      type: this.type,
      label: this.label,
      title: this.title,
      shouldStream: this.shouldStream
    };
  }
}
export class LocalStreamingDevice extends StreamingDevice {
  constructor(streaming, config) {
    super(streaming, config);
    this.isRemote = false;
    this.isLocal = true;
    this.producer = null;
  }
  heartBeat() {
    if (!this.available) {
      this.getID();
      return;
    }
    // if (!this.shouldStream) {
    //   if (this.producer && this.producer.pause) this.producer.pause();
    //   // this.serverDisconnect();
    //   return;
    // } else {
    //   if (this.producer && this.producer.resume) this.producer.resume();
    // }
    if (!this.connected) {
      console.log("connect");
      this.connect();
      return;
    }
    this.produce();
    if (this.active !== this.staleActive) {
      if (this.active) this.onStreamActive();else this.onStreamInactive();
      this.staleActive = this.active;
    }
    if (!this.active) {
      this.stream = null;
      this.deviceId = null;
      this.deviceIDChanged();
      return;
    }
  }
  async getID() {
    console.log("screens", this.type);
    try {
      if (this.type === "desktopinput") {
        const {
          desktopCapturer
        } = window.require("electron");
        let screens = await desktopCapturer.getSources({
          types: ["screen", "window"]
        });
        this.getIDFromList(screens);
        console.log("screens", screens);
      } else this.getIDFromList(await navigator.mediaDevices.enumerateDevices());
    } catch (e) {}
  }
  getIDFromList(list) {
    for (let device of list) {
      if (this.type === "desktopinput") {
        if (device.name.startsWith(this.label)) {
          this.setDeviceId(device.id);
          console.log("screens-found", device, device.id);
          return;
        }
      } else if (device.kind === this.type && device.label.startsWith(this.label)) {
        this.setDeviceId(device.deviceId);
        console.log("devicefound", device);
        return;
      }
    }
    this.setDeviceId(null);
  }
  setDeviceId(id) {
    if (id !== this.deviceId) {
      if (id === null) this.emitEvent("available");
      if (this.deviceId === null) this.emitEvent("removed");
      this.deviceId = id;
      this.deviceIDChanged();
    }
  }
  deviceIDChanged() {
    //I don't think the next line is necessary, but we will see if it causes problems to remove it.
    // this.stream = null;
    this.emitEvent("changed");
    this.connect();
  }
  async connect() {
    if (!this.available) return;
    try {
      // console.log(
      //   "connect",
      //   this.available,
      //   this.constraints,
      //   navigator.mediaDevices.getUserMedia
      // );
      let stream = await navigator.mediaDevices.getUserMedia(this.constraints);
      console.log(stream);
      if (stream) {
        this.stream = stream;
        return;
      }
    } catch (e) {
      console.error(e);
    }
    if (this.deviceStream) {
      this.stream = null;
    }
  }
  onStreamActive() {
    this.play();
    this.produce();
    if (this.shouldStream && this.streaming.msClient && this.streaming.msClient.connected) this.producer = this.streaming.msClient.produce(this.title, this);else this.staleActive = false;
    this.emitEvent("active");
  }
  onStreamInactive() {
    this.emitEvent("inactive");
    console.log("DEVICES:inactive");
  }
  get constraints() {
    if (!this.available) return null;
    return this.constraintFunc(this.deviceId);
  }
  serverDisconnect() {
    this.producer = null;
  }
  produce() {
    if (this.producer !== null) return;
    if (this.shouldStream && this.streaming.msClient.connected) {
      console.log("DEVY", "produceTrack");
      this.producer = this.streaming.msClient.produce(this.title, this);
    }
  }
}
export class RemoteStreamingDevice extends StreamingDevice {
  constructor(streaming, config) {
    super(streaming, config);
    this.consumer = null;
    this.isRemote = true;
    this.isLocal = false;
  }
  heartBeat() {
    // console.log("DEVY", this.info);

    if (!this.deviceShouldStream) {
      if (this.consumer) {
        this.streaming.msClient.socket.request("pauseConsumer", this.title);
        this.consumer.pause();
      } // this.serverDisconnect();
      return;
    } else {
      if (this.consumer) {
        this.streaming.msClient.socket.request("resumeConsumer", this.title);
        this.consumer.resume();
      }
    }
    if (!this.connected) {
      this.serverDisconnect();
      this.serverConnect(true);

      // return;
    }

    this.serverConnect();
    if (this.active !== this.staleActive) {
      if (this.active) this.onStreamActive();else this.onStreamInactive();
      this.staleActive = this.active;
    }
    if (!this.active) {
      this.stream = null;
      this.deviceId = null;
      return;
    }
  }
  async serverConnect() {
    let force = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    if (this.consumer !== null && this.consumer.closed) this.serverDisconnect();
    if (this.consumer !== null && force === false) return;
    if (this.streaming.msClient && this.streaming.msClient.connected)
      // if (this.consumer) this.consumer.close();
      this.consumer = await this.streaming.msClient.consume(this.title);
    // console.log(this.consumer);
    if (!this.consumer) {
      this.deviceId = null;
      this.stream = null;
      this.consumer = null;
      return;
    }
    let stream = new MediaStream([this.consumer.track]);
    console.log(this.consumer, stream);
    // this.consumer = consumer;
    this.deviceId = stream.id;
    this.stream = stream;
    // await this.consumer.resume();
    // await this.streaming.msClient.resume();
  }

  onStreamActive() {
    console.log("DEVICES:active");
    this.play();
    this.emitEvent("active");
  }
  onStreamInactive() {
    console.log("DEVICES:inactive");
    this.emitEvent("inactive");
  }
  serverDisconnect() {
    console.log("DEVY", "serverDisconnected");
    this.consumer = null;
    this.deviceId = null;
    this.stream = null;
  }
}