import React from "react";
import Hammer from "hammerjs";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
// import { throttle } from "throttle-debounce";

export default class Joystick extends React.Component {
  constructor(props) {
    super(props);
    this.front = React.createRef();
    this.state = { touched: false };
    this.offSetDistanceX = 0;
    this.offSetDistanceY = 0;
    this.sentX = null;
    this.sentY = null;
    this.sentStatus = null;

    this.x = null;
    this.y = null;
    this.status = null;

    this.sendAxis = setInterval(() => {
      if (
        this.x === this.sentX &&
        this.y === this.sentY &&
        this.status === this.sentStatus
      )
        return;

        
      if (this.status === "ended") {
        this.sentStatus = this.status;
        this.props.onStop();
      } else {
        this.props.onMove({
          x: this.x,
          y: this.y,
        });
      }
      this.sentX = this.x;
      this.sentY = this.y;
      this.sentStatus = this.status;
    }, 100);
  }
  componentDidMount() {
    this.setGestures();
    // this.onMove = throttle(100, this.props.onMove);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.touched === false && this.state.touched === true) {
      if (this.props.onStart) {
        this.props.onStart();
      }
    }
    if (prevState.touched === true && this.state.touched === false) {
      if (this.props.onStop) {
        this.props.onStop();
      }
    }
  }
  setGestures() {
    var _this = this;
    var PTFront = this.front.current;
    this.Pager = new Hammer(PTFront, {
      transform: false,
      recognizers: [
        [
          Hammer.Pan,
          {
            event: "pan",
            threshold: 1,
          },
        ],
        // [Hammer.Press, {
        //     event: 'longpress',
        //     time: 400
        // }],
        // 				[Hammer.Press, { event: 'touch',time:0 } ],
      ],
    });
    this.Pager.on("panmove", function (ev) {
      var deltaX = 0;
      var deltaY = 0;
      if (_this.props.size.h > 0) {
        deltaX = ev.deltaX - _this.offSetDistanceX;
      } else {
        deltaX = 0 - _this.offSetDistanceX;
      }
      if (Math.abs(deltaX) > _this.props.size.h / 2) {
        if (deltaX > _this.props.size.h / 2) {
          deltaX = _this.props.size.h / 2;
        } else {
          deltaX = 0 - _this.props.size.h / 2;
        }
      }

      if (_this.props.size.v > 0) {
        deltaY = ev.deltaY - _this.offSetDistanceY;
      } else {
        deltaY = 0 - _this.offSetDistanceY;
      }

      // window.requestAnimationFrame(() => {

      // });
      if (Math.abs(deltaY) > _this.props.size.v / 2) {
        if (deltaY > _this.props.size.v / 2) {
          deltaY = _this.props.size.v / 2;
        } else {
          deltaY = 0 - _this.props.size.v / 2;
        }
      }
      PTFront.style.transform = "translate(" + deltaX + "px, " + deltaY + "px)";

      if (_this.props.invert) {
        deltaX = -deltaX;
        deltaY = -deltaY;
      }

      _this.x = deltaX / (_this.props.size.h / 2);
      _this.y = deltaY / (_this.props.size.v / 2);
      _this.status = "moving";
      // if (_this.onMove && _this.state.touched) {
      //     if (_this.props.invert) {
      //         deltaX = -deltaX;
      //         deltaY = -deltaY;
      //     }
      //     _this.onMove({
      //         x: deltaX / (_this.props.size.h / 2),
      //         y: deltaY / (_this.props.size.v / 2),
      //     })
      // }
    });
    this.Pager.on("panstart", function (ev) {
      _this.setState({ touched: true });
      // window.requestAnimationFrame(() => {
      console.log(_this.offSetDistanceX, _this.offSetDistanceY);
      if (_this.props.size.h > 0) {
        _this.offSetDistanceX = ev.deltaX;
      }
      if (_this.props.size.v > 0) {
        _this.offSetDistanceY = ev.deltaY;
      }
      // console.log(_this.offSetDistanceX, _this.offSetDistanceY);
      // })
    });
    this.Pager.on("panend", function (ev) {
      setTimeout(() => {
        _this.setState({ touched: false });
        PTFront.style.transform = "";
        if (_this.props.size.h > 0) {
          _this.offSetDistanceX = 0;
        }
        if (_this.props.size.v > 0) {
          _this.offSetDistanceY = 0;
        }
        _this.x = 0;
        _this.y = 0;
        _this.status = "ended";
        console.log(_this.offSetDistanceX, _this.offSetDistanceY);
        // if (_this.props.onStop) {
        //     _this.props.onStop()
        // }
      }, 50);
    });
  }
  render() {
    var sizeL = this.props.size.v;
    if (this.props.size.v < this.props.size.h) {
      sizeL = this.props.size.h;
    }
    var sizeMini = sizeL / 4;
    var LargeW = this.props.size.h * 1.25;
    if (LargeW < sizeMini) {
      LargeW = sizeMini * 0.2;
    }
    var LargeV = this.props.size.v * 1.25;
    if (LargeV < sizeMini) {
      LargeV = sizeMini;
    }

    return (
      <div className={"JoystickContainer"} style={this.props.style}>
        <div
          className={this.state.touched ? "back enabled" : "back nAnimate"}
          style={{ width: LargeW, height: LargeV }}
        >
          <div
            ref={this.front}
            className={this.state.touched ? "front enabled" : "front nAnimate"}
            style={{
              width: sizeMini,
              height: sizeMini,
              position: "absolute",
            }}
          ></div>
        </div>
      </div>
    );
  }
}
