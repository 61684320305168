import React from "react";
import DBContext from "./../DBContext";
import "./display.css";
import Element from "./elements";
class Display extends React.Component {
  static contextType = DBContext;
  state = {
    display:
      this.props.display ||
      this.context.globals
        .display(this.props.displayID)
        .setName(
          this.props.displayName ||
            this.props.match.params.displayName
        ),
  };
  // constructor(props) {
  //   super(props);
  //   this.display.ready = []
  // }
  componentDidMount() {
    window.document.title = this.label;
    this.display.ready = [];
  }
  get display() {
    return this.state.display || {};
  }
  get label() {
    return (
      this.display.label || this.props.match.params.displayName + " Display"
    );
  }
  render() {
    console.log(this.state.display);
    return (
      <div className={"display-outer"}>
        {this.display.exists ? (
          <div
            className={"display-inner"}
            style={{
              ...this.display.displayObject.element,
            }}
          >
            {this.display.elements.map((v, i) => (
              <Element
                key={v.id}
                order={v.order}
                element={v}
                displayObj={this.display.displayObject}
                display={this}
              />
            ))}
          </div>
        ) : null}
      </div>
    );
  }
}

export default Display;
