import React from "react";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"
import CaserTextField from "./mainComponents/Fields";
import Button from "./mainComponents/Button";
export default class CaserJSONField extends CaserTextField {
  state = {
    value: JSON.stringify(this.props.value),
    newVal: null,
    changed: false,
  };
  componentDidUpdate() {
    this.updateValue();
  }
  updateValue() {
    if (this.state.changed === true) {
      if (this.state.newVal === this.stringify(this.props.value)) {
        return;
      }
      this.setState({ newVal: this.stringify(this.props.value) });
    } else {
      if (this.state.value === this.stringify(this.props.value)) {
        return;
      }
      this.setState({ value: this.stringify(this.props.value) });
    }
  }
  stringify(ob) {
    return JSON.stringify(ob, null, "\t");
  }
  // onChange(event) {
  //   if (!this.checkEditable()) return;
  //   let value = event.target.value;
  //   // let value = h2p.fromString(event.target.value,{
  //   //       wordwrap: 130,
  //   //       preserveNewlines:true,
  //   //       singleNewLineParagraphs:true
  //   // });
  //   // console.log(value)
  //   this.setState({
  //     value,
  //   });
  //   if (this.props.noDebounce !== true) this.sendDebounceUpdate(value);
  //   else this.sendDirectUpdate(value);
  // }

  onChange(code) {
    this.setState({
      value: code,
      changed: true,
    });
  }
  onSave = () => {
    try {
      let val = JSON.parse(this.state.value);
      this.props.onChange(val);
      this.setState({ changed: false });
    } catch (e) {
      console.log(e);
    }
  };
  render() {
    return (
      <div className={"caser-input-container"}>
        <div className={"caser-input-label"}>{this.props.label}</div>
        <div           className={"caser-input"}
>
        <Editor
          type={"text"}
          highlight={(code) => highlight(code, languages.js)}
          // textareaClassName={"caser-input"}
          // preClassName={"caser-input"}
          // style={{ resize: "none" }}
          padding={10}
          value={this.state.value || ""}
          onValueChange={this.onChange.bind(this)}
        />
        </div>
        {this.state.changed ? (
          <Button onClick={this.onSave}>Save</Button>
        ) : null}
      </div>
    );
  }
}
