import ws from "websocket-as-promised";
class WSClient extends ws {
  constructor() {
    arguments[1] = Object.assign(arguments[1], {
      packMessage: data => JSON.stringify(data),
      unpackMessage: message => JSON.parse(message),
      attachRequestId: (data, requestId) => Object.assign({
        id: requestId
      }, data),
      // attach requestId to message as `id` field
      extractRequestId: data => data && data.id
    });
    super(...arguments);
    this.timefactor = 1.005;
    this.onClose.addListener(event => {
      console.log("close");
      this.triggerReconnect();
    });
    this.onError.addListener(event => {
      console.log("err", event);
      this.triggerReconnect();
    });
    this.reconnecting = false;
  }
  triggerReconnect() {
    let length = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 500;
    let force = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    if (this.reconnecting && !force) return;
    this.reconnecting = true;
    let self = this;
    setTimeout(() => {
      console.log("trying to reconnect-electron", length);
      self.open().then(() => self.reconnecting = false).catch(err => {
        console.log(err);
        self.triggerReconnect(length * self.timefactor, true);
      });
    }, length);
  }
  async call(module, method) {
    let params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    if (!this.isOpened) return null;
    return new Promise((r, e) => {
      this.sendRequest({
        type: "request",
        module,
        method,
        params
      }).then(messageData => {
        if (messageData.status === 1) r(messageData.data);else if (messageData.status === 0) e(messageData.data);
      });
    });
  }
}
export default WSClient;