import React from "react";
import {
  CaserTextField,
  CaserRadioField,
  CaserSelectField,
  CaserNumberField,
  CaserLongTextField,
} from "../../Tools/mainComponents/Fields";
import DBContext from "../../DBContext";
import { Title, Box, Heading } from "../../displays/DisplaySettings";
import { MdAdd, MdExpandMore, MdExpandLess } from "react-icons/md";
import Card from "../../Tools/mainComponents/Card";
import Button from "../../Tools/mainComponents/Button";
import ConfirmButton from "../../Tools/mainComponents/confirmButton";

export default class Scheduler extends React.Component {
  static contextType = DBContext;

  render() {
    return (
      <div>
        <Title
          right={
            <div
              onClick={(e) => {
                this.props.main.ws.request("Scheduler", "createJob", []);

                // this.context.schedule.jobs.splice(0, 0, {
                //   name: "Untitled Scene",
                //   id: v4(),
                //   elements: [],
                //   defaultDisplay: this.props.display.id,
                // });
              }}
            >
              <MdAdd />
            </div>
          }
        >
          Scheduled Jobs
        </Title>
        {Object.keys(this.context.schedule.scheduledJobs || {}).map((id) => (
          <Job {...this.props} id={id} key={id} />
        ))}
        <Title
          right={
            <div
              onClick={(e) => {
                this.props.main.ws.request("Scheduler", "createTask", []);
              }}
            >
              <MdAdd />
            </div>
          }
        >
          Tasks
        </Title>
        {Object.keys(this.context.schedule.scheduledTasks || {}).map((id) => (
          <Tasks {...this.props} id={id} key={id} />
        ))}
      </div>
    );
  }
}

class Job extends React.Component {
  static contextType = DBContext;
  state = {
    FullOpen: false,
  };
  rescheduleJob = () => {
    this.props.main.ws.request("Scheduler", "updateJobSchedule", [
      this.props.id,
    ]);
  };
  render() {
    let jobState = this.context.schedule.scheduledJobs[this.props.id];
    return (
      <Box
        id={this.props.id}
        right={
          <div style={{ display: "flex", flexDirection: "row" }}>
            <ConfirmButton
              onClick={() => {
                this.props.main.ws.request("Scheduler", "deleteJob", [
                  this.props.id,
                ]);
              }}
            >
              Delete
            </ConfirmButton>
            <Button
              onClick={() => {
                this.props.main.ws.request("Scheduler", "runJob", [
                  this.props.id,
                ]);
              }}
            >
              Run
            </Button>
          </div>
        }
        left={
          <Button
            onClick={(e) => {
              this.setState({
                FullOpen: this.state.FullOpen ? false : true,
              });
              e.stopPropagation();
            }}
          >
            {!this.state.FullOpen ? <MdExpandMore /> : <MdExpandLess />}
          </Button>
        }
        bottom={
          this.state.FullOpen && (
            <div
              style={{
                padding: 10,
                background: "#ffffff77",
                color: "#000",
                borderRadius: 5,
                marginBottom: 10,
                width: "100%",
                flexGrow: 1,
              }}
            >
              <Card title="Settings">
                <CaserTextField
                  label={"Job Name"}
                  value={jobState.name}
                  onChange={(val) => {
                    jobState.name = val;
                  }}
                />
                <CaserTextField
                  label={"Job Id"}
                  value={this.props.id}
                  readOnly
                />
                <CaserRadioField
                  label={"Job Status"}
                  opts={[
                    { optionId: false, label: "Disabled" },
                    { optionId: true, label: "Enabled" },
                  ]}
                  value={jobState.enabled}
                  onChange={(val) => {
                    jobState.enabled = val;
                  }}
                />
              </Card>
              <Card title={"Schedule"}>
                <Heading>Months</Heading>
                <div style={{ display: "flex" }}>
                  {[
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                    "All",
                    "Reset",
                  ].map((month, monthIndex) => (
                    <Button
                      type={
                        month !== "All" &&
                        month !== "Reset" &&
                        jobState.schedule.month.includes(monthIndex) &&
                        "primary"
                      }
                      onClick={() => {
                        if (month === "All") {
                          jobState.schedule.month = [
                            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
                          ];
                        } else if (month === "Reset") {
                          jobState.schedule.month = [0];
                        } else if (
                          jobState.schedule.month.includes(monthIndex)
                        ) {
                          jobState.schedule.month =
                            jobState.schedule.month.filter(
                              (m) => m !== monthIndex
                            );
                        } else {
                          jobState.schedule.month.push(monthIndex);
                        }
                        this.rescheduleJob();
                      }}
                    >
                      {month}
                    </Button>
                  ))}
                </div>

                <Heading>Days of the Month</Heading>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {[
                    "1st",
                    "2nd",
                    "3rd",
                    "4th",
                    "5th",
                    "6th",
                    "7th",
                    "8th",
                    "9th",
                    "10th",
                    "11th",
                    "12th",
                    "13th",
                    "14th",
                    "15th",
                    "16th",
                    "17th",
                    "18th",
                    "19th",
                    "20th",
                    "21st",
                    "22nd",
                    "23rd",
                    "24th",
                    "25th",
                    "26th",
                    "27th",
                    "28th",
                    "29th",
                    "30th",
                    "31st",
                    "All",
                    "Reset",
                  ].map((date, dateIndex) => (
                    <Button
                      type={
                        date !== "All" &&
                        date !== "Reset" &&
                        jobState.schedule.date.includes(dateIndex + 1) &&
                        "primary"
                      }
                      onClick={() => {
                        if (date === "All") {
                          jobState.schedule.date = [
                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                            16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
                            29, 30, 31,
                          ];
                        } else if (date === "Reset") {
                          jobState.schedule.date = [1];
                        } else if (
                          jobState.schedule.date.includes(dateIndex + 1)
                        ) {
                          jobState.schedule.date =
                            jobState.schedule.date.filter(
                              (m) => m !== dateIndex + 1
                            );
                        } else {
                          jobState.schedule.date.push(dateIndex + 1);
                        }
                        this.rescheduleJob();
                      }}
                    >
                      {date}
                    </Button>
                  ))}
                </div>

                <Heading>Days of the Week</Heading>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {[
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "All",

                    "Weekdays",
                    "Weekends",
                    "Reset",
                  ].map((dayOfWeek, dayOfWeekIndex) => (
                    <Button
                      type={
                        dayOfWeek !== "All" &&
                        dayOfWeek !== "Reset" &&
                        dayOfWeek !== "Weekdays" &&
                        dayOfWeek !== "Weekends" &&
                        jobState.schedule.dayOfWeek.includes(dayOfWeekIndex) &&
                        "primary"
                      }
                      onClick={() => {
                        if (dayOfWeek === "All") {
                          jobState.schedule.dayOfWeek = [0, 1, 2, 3, 4, 5, 6];
                        } else if (dayOfWeek === "Reset") {
                          jobState.schedule.dayOfWeek = [0];
                        } else if (dayOfWeek === "Weekdays") {
                          jobState.schedule.dayOfWeek = [1, 2, 3, 4, 5];
                        } else if (dayOfWeek === "Weekends") {
                          jobState.schedule.dayOfWeek = [0, 6];
                        } else if (
                          jobState.schedule.dayOfWeek.includes(dayOfWeekIndex)
                        ) {
                          jobState.schedule.dayOfWeek =
                            jobState.schedule.dayOfWeek.filter(
                              (m) => m !== dayOfWeekIndex
                            );
                        } else {
                          jobState.schedule.dayOfWeek.push(dayOfWeekIndex);
                        }
                        this.rescheduleJob();
                      }}
                    >
                      {dayOfWeek}
                    </Button>
                  ))}
                </div>

                <Heading>Hours</Heading>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {[
                    "12 am",
                    "1 am",
                    "2 am",
                    "3 am",
                    "4 am",
                    "5 am",
                    "6 am",
                    "7 am",
                    "8 am",
                    "9 am",
                    "10 am",
                    "11 am",
                    "12 pm",
                    "1 pm",
                    "2 pm",
                    "3 pm",
                    "4 pm",
                    "5 pm",
                    "6 pm",
                    "7 pm",
                    "8 pm",
                    "9 pm",
                    "10 pm",
                    "11 pm",
                    "Every Hour",
                    "Reset",
                  ].map((hour, hourIndex) => (
                    <Button
                      type={
                        hour !== "Every Hour" &&
                        hour !== "Reset" &&
                        jobState.schedule.hour.includes(hourIndex) &&
                        "primary"
                      }
                      onClick={() => {
                        if (hour === "Every Hour") {
                          jobState.schedule.hour = [
                            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
                            15, 16, 17, 18, 19, 20, 21, 22, 23,
                          ];
                        } else if (hour === "Reset") {
                          jobState.schedule.hour = [0];
                        } else if (jobState.schedule.hour.includes(hourIndex)) {
                          jobState.schedule.hour =
                            jobState.schedule.hour.filter(
                              (m) => m !== hourIndex
                            );
                        } else {
                          jobState.schedule.hour.push(hourIndex);
                        }
                        this.rescheduleJob();
                      }}
                    >
                      {hour}
                    </Button>
                  ))}
                </div>

                <Heading>Minutes</Heading>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {[
                    "00",
                    "01",
                    "02",
                    "03",
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "09",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "36",
                    "37",
                    "38",
                    "39",
                    "40",
                    "41",
                    "42",
                    "43",
                    "44",
                    "45",
                    "46",
                    "47",
                    "48",
                    "49",
                    "50",
                    "51",
                    "52",
                    "53",
                    "54",
                    "55",
                    "56",
                    "57",
                    "58",
                    "59",
                    "Every Minute",
                    "Reset",
                  ].map((minute, minuteIndex) => (
                    <Button
                      type={
                        minute !== "Every Minute" &&
                        minute !== "Reset" &&
                        jobState.schedule.minute.includes(minuteIndex) &&
                        "primary"
                      }
                      onClick={() => {
                        if (minute === "Every Minute") {
                          jobState.schedule.minute = [
                            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
                            15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
                            28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
                            41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53,
                            54, 55, 56, 57, 58, 59,
                          ];
                        } else if (minute === "Reset") {
                          jobState.schedule.minute = [0];
                        } else if (
                          jobState.schedule.minute.includes(minuteIndex)
                        ) {
                          jobState.schedule.minute =
                            jobState.schedule.minute.filter(
                              (m) => m !== minuteIndex
                            );
                        } else {
                          jobState.schedule.minute.push(minuteIndex);
                        }
                        this.rescheduleJob();
                      }}
                    >
                      {minute}
                    </Button>
                  ))}
                </div>

                <Heading>Seconds</Heading>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {[
                    "00",
                    "01",
                    "02",
                    "03",
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "09",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "36",
                    "37",
                    "38",
                    "39",
                    "40",
                    "41",
                    "42",
                    "43",
                    "44",
                    "45",
                    "46",
                    "47",
                    "48",
                    "49",
                    "50",
                    "51",
                    "52",
                    "53",
                    "54",
                    "55",
                    "56",
                    "57",
                    "58",
                    "59",
                    "Every Second",
                    "Reset",
                  ].map((second, secondIndex) => (
                    <Button
                      type={
                        second !== "Every Second" &&
                        second !== "Reset" &&
                        jobState.schedule.second.includes(secondIndex) &&
                        "primary"
                      }
                      onClick={() => {
                        if (second === "Every Second") {
                          jobState.schedule.second = [
                            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
                            15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
                            28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
                            41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53,
                            54, 55, 56, 57, 58, 59,
                          ];
                        } else if (second === "Reset") {
                          jobState.schedule.second = [0];
                        } else if (
                          jobState.schedule.second.includes(secondIndex)
                        ) {
                          jobState.schedule.second =
                            jobState.schedule.second.filter(
                              (m) => m !== secondIndex
                            );
                        } else {
                          jobState.schedule.second.push(secondIndex);
                        }
                        this.rescheduleJob();
                      }}
                    >
                      {second}
                    </Button>
                  ))}
                </div>
              </Card>
              <Card
                title={"Job Tasks"}
                right={
                  <div
                    onClick={(e) => {
                      jobState.tasks.push("");
                    }}
                  >
                    <MdAdd />
                  </div>
                }
              >
                {jobState.tasks.map((task, taskIndex) => (
                  <Box
                    key={taskIndex}
                    bottom={
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {taskIndex > 0 && (
                          <Button
                            onClick={() => {
                              jobState.tasks.splice(
                                taskIndex - 1,
                                0,
                                jobState.tasks.splice(taskIndex, 1)[0]
                              );
                            }}
                          >
                            Up
                          </Button>
                        )}
                        <ConfirmButton
                          onClick={() => jobState.tasks.splice(taskIndex, 1)}
                        >
                          Delete
                        </ConfirmButton>

                        {taskIndex < jobState.tasks.length - 1 && (
                          <Button
                            onClick={() => {
                              jobState.tasks.splice(
                                taskIndex + 1,
                                0,
                                jobState.tasks.splice(taskIndex, 1)[0]
                              );
                            }}
                          >
                            Down
                          </Button>
                        )}
                      </div>
                    }
                  >
                    <div
                      style={{
                        // padding: 10,
                        // background: "#ffffff77",
                        // color: "#000",
                        // fontWeight: 800,
                        // fontSize: "120%",
                        // borderRadius: 5,
                        // margin: 10,
                        // marginBottom: 10,
                        width: "100%",
                      }}
                    >
                      <CaserSelectField
                        value={jobState.tasks[taskIndex]}
                        onChange={(val) => {
                          jobState.tasks[taskIndex] = val;
                        }}
                        opts={Object.keys(
                          this.context.schedule.scheduledTasks
                        ).map((v) => {
                          return {
                            optionId: v,
                            label: this.context.schedule.scheduledTasks[v].name,
                          };
                        })}
                      />
                    </div>
                  </Box>
                ))}
              </Card>
            </div>
          )
        }
      >
        {jobState.name}
      </Box>
    );
  }
}

class Tasks extends React.Component {
  static contextType = DBContext;
  state = {
    FullOpen: false,
  };
  render() {
    let taskState = this.context.schedule.scheduledTasks[this.props.id] || {};
    return (
      <Box
        id={this.props.id}
        right={
          <div style={{ display: "flex", flexDirection: "row" }}>
            <ConfirmButton
              onClick={() => {
                this.props.main.ws.request("Scheduler", "deleteTask", [
                  this.props.id,
                ]);
              }}
            >
              Delete
            </ConfirmButton>
            <Button
              onClick={() => {
                this.props.main.ws.request("Scheduler", "runTask", [
                  this.props.id,
                ]);
              }}
            >
              Run
            </Button>
          </div>
        }
        left={
          <Button
            onClick={(e) => {
              this.setState({
                FullOpen: this.state.FullOpen ? false : true,
              });
              e.stopPropagation();
            }}
          >
            {!this.state.FullOpen ? <MdExpandMore /> : <MdExpandLess />}
          </Button>
        }
        bottom={
          this.state.FullOpen && (
            <div
              style={{
                padding: 10,
                background: "#ffffff77",
                color: "#000",
                borderRadius: 5,
                marginBottom: 10,
                width: "100%",
                flexGrow: 1,
              }}
            >
              <Card title={"Settings"}>
                <CaserTextField
                  label={"Task Name"}
                  value={taskState.name}
                  onChange={(val) => {
                    taskState.name = val;
                  }}
                />
                <CaserTextField
                  label={"Task Id"}
                  value={this.props.id}
                  readOnly
                />
              </Card>
              <Card
                title={"Task Actions"}
                right={
                  <div
                    onClick={(e) => {
                      taskState.actions.push({
                        action: null,
                        params: {},
                      });

                      // this.context.schedule.jobs.splice(0, 0, {
                      //   name: "Untitled Scene",
                      //   id: v4(),
                      //   elements: [],
                      //   defaultDisplay: this.props.display.id,
                      // });
                    }}
                  >
                    <MdAdd />
                  </div>
                }
              >
                {taskState.actions.map((action, actionIndex) => (
                  <Box
                    key={actionIndex}
                    bottom={
                      <>
                        {action.name &&
                          this.context.smartControl.actions[action.name] &&
                          this.context.smartControl.actions[action.name]
                            .params && (
                            <div
                              style={{
                                padding: 10,
                                background: "#ffffff77",
                                color: "#000",
                                fontWeight: 800,
                                borderRadius: 5,
                                marginBottom: 10,
                                width: "100%",
                              }}
                            >
                              Parameters
                              {this.context.smartControl.actions[
                                action.name
                              ].params.map((v) => {
                                if (v.readOnly === true) return null;
                                let value =
                                  typeof action.params[v.name] !== "undefined"
                                    ? action.params[v.name]
                                    : v.default || null;

                                if (v.type === "bool") {
                                  return (
                                    <CaserRadioField
                                      key={v.name}
                                      label={v.label || v.name}
                                      opts={[
                                        { optionId: false, label: "False" },
                                        { optionId: true, label: "True" },
                                      ]}
                                      value={value}
                                      onChange={(val) => {
                                        action.params[v.name] = val;
                                        console.log(
                                          "VALL",
                                          action.params[v.name],
                                          val
                                        );
                                      }}
                                    />
                                  );
                                } else if (v.options) {
                                  return (
                                    <CaserSelectField
                                      key={v.name}
                                      label={v.label || v.name}
                                      opts={v.options.map((op) => ({
                                        optionId: op,
                                        label: op,
                                      }))}
                                      value={value}
                                      onChange={(val) => {
                                        action.params[v.name] = val;
                                      }}
                                    />
                                  );
                                } else if (v.taskList) {
                                  return (
                                    <CaserSelectField
                                      key={v.name}
                                      label={v.label || v.name}
                                      opts={Object.keys(
                                        this.context.schedule.scheduledTasks
                                      )
                                        .filter((v) => v !== this.props.id)
                                        .map((op) => ({
                                          optionId: op,
                                          label:
                                            this.context.schedule
                                              .scheduledTasks[op].name,
                                        }))}
                                      value={value}
                                      onChange={(val) => {
                                        action.params[v.name] = val;
                                      }}
                                    />
                                  );
                                } else if (v.type === "int") {
                                  return (
                                    <CaserNumberField
                                      key={v.name}
                                      label={v.label || v.name}
                                      value={value}
                                      onChange={(val) => {
                                        action.params[v.name] = val;
                                      }}
                                    />
                                  );
                                } else if (v.type === "object") {
                                  return (
                                    <CaserLongTextField
                                      label={v.label || v.name}
                                      value={JSON.stringify(value)}
                                      onChange={(val) => {
                                        try {
                                          action.params[v.name] =
                                            JSON.parse(val);
                                        } catch (e) {}
                                      }}
                                    />
                                  );
                                }
                                return (
                                  <CaserTextField
                                    label={v.label || v.name}
                                    value={value}
                                    onChange={(val) => {
                                      action.params[v.name] = val;
                                    }}
                                  />
                                );
                              })}
                            </div>
                          )}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          {actionIndex > 0 && (
                            <Button
                              onClick={() => {
                                taskState.actions.splice(
                                  actionIndex - 1,
                                  0,
                                  taskState.actions.splice(actionIndex, 1)[0]
                                );
                              }}
                            >
                              Up
                            </Button>
                          )}
                          <ConfirmButton
                            onClick={() =>
                              taskState.actions.splice(actionIndex, 1)
                            }
                          >
                            Delete
                          </ConfirmButton>

                          {actionIndex < taskState.actions.length - 1 && (
                            <Button
                              onClick={() => {
                                taskState.actions.splice(
                                  actionIndex + 1,
                                  0,
                                  taskState.actions.splice(actionIndex, 1)[0]
                                );
                              }}
                            >
                              Down
                            </Button>
                          )}
                        </div>
                      </>
                    }
                  >
                    <div
                      style={{
                        // padding: 10,
                        // background: "#ffffff77",
                        // color: "#000",
                        // fontWeight: 800,
                        // fontSize: "120%",
                        // borderRadius: 5,
                        // margin: 10,
                        // marginBottom: 10,
                        width: "100%",
                      }}
                    >
                      <CaserSelectField
                        value={action.name}
                        onChange={(val) => {
                          action.name = val;
                          action.params = {};
                        }}
                        opts={Object.keys(
                          this.context.smartControl.actions
                        ).map((v) => {
                          return {
                            optionId: v,
                            label: this.context.smartControl.actions[v].label,
                          };
                        })}
                      />
                    </div>
                  </Box>
                ))}
              </Card>
            </div>
          )
        }
      >
        {taskState.name}
      </Box>
    );
  }
}
