import ws from "./WSClient.mjs";
import websocket from "websocket";
export default class Socket {
  constructor() {
    let config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
      url: "wss://av.saintmarydelray.com/main",
      onDataReceive: () => {}
    };
    this.config = config;
    this.request = this.requestWithResponse;
    this.onDataReceive = this.config.onDataReceive;
    this.onOpen = this.config.onOpen;
    let wsConfig = {
      packMessage: data => JSON.stringify(data),
      unpackMessage: message => JSON.parse(message),
      attachRequestId: (data, requestId) => Object.assign({
        id: requestId
      }, data),
      // attach requestId to message as `id` field
      extractRequestId: data => data && data.id
    };
    if (typeof window === "undefined") {
      const W3CWebSocket = websocket.w3cwebsocket;
      // console.log("really", W3CWebSocket);
      wsConfig.createWebSocket = url => {
        let client = new W3CWebSocket(url);
        client.onerror = function (e) {
          console.log("Connection Error", e);
        };
        return client;
      };
    }
    // console.log("not");
    this.ws = new ws(this.config.url, wsConfig);
    this.ws.onUnpackedMessage.addListener(this.onDataReceive);
    // this.ws.onerror.addListener();
    // this.ws.onOpen.addListener(() => console.log("Go!"));
    this.ws.open().then().catch(err => console.log(err));
    // setInterval(() => console.log("WS is openning", this.ws.isOpening), 1000);
    // this.ws.on("message", this.onDataReceive);
  }

  async call(module, method, params) {
    if (!this.ws.isOpened) {
      await this.ws.open();
    }
    return new Promise(async (r, e) => {
      if (!this.isOpened) {
        // e();
        // return;
      }
      let res = await this.ws.sendRequest({
        type: "request",
        module,
        method,
        params
      });
      // console.log("RESPONSE", res);
      if (res.status === 1) r(res.data);else if (res.status === 0) e(res.data);
    });
  }
  async requestWithResponse(category, func, params) {
    if (!this.ws.isOpened) {
      await this.ws.open();
    }
    return new Promise(async (r, e) => {
      if (!this.isOpened) {
        // e();
        // return;
      }
      let res = await this.ws.sendRequest({
        type: "request",
        category,
        func,
        params
      });
      // console.log("RESPONSE", res);
      if (res.status === 1) r(res.data);else if (res.status === 0) e(res.data);
    });
  }
}
export { ws };