import React, { Component } from "react";
import { Container, Button } from "@material-ui/core";
import Card from "./../Tools/mainComponents/Card";
import {
  CaserTextField,
  CaserSelectField,
} from "./../Tools/mainComponents/Fields";
import Alert from "@material-ui/lab/Alert";
class Login extends Component {
  state = {
    username: "",
    device: window.localStorage.getItem("defaultDevice") || "Device",
    password: "",
    error: null,
  };
  async handleLoginClick() {
    try {
      this.props.touched();
      this.props.authenticate({
        username: this.state.username,
        password: this.state.password,
        device: this.state.device,
      });
    } catch (e) {
      console.log(e.message);
      this.setState({ error: e.message });
    }
  }

  render() {
    return (
      <div>
        <Container maxWidth={"xs"} style={{ paddingTop: 150 }}>
          <Card
            header={"Login to the AV Controlller"}
            ContentProps={{ style: { background: "white" } }}
          >
            {/* {this.state.error ? (
              <MuiAlert severity="error">{this.state.error}</MuiAlert>
            ) : null} */}
            <CaserTextField
              fullWidth
              label="Username"
              value={this.state.username}
              onChange={(val) =>
                this.setState({
                  username: val,
                })
              }
            />
            <br />
            <CaserSelectField
              fullWidth
              label="Device"
              opts={[
                { optionId: "iPhone", label: "iPhone" },
                { optionId: "iPad", label: "iPad" },
                { optionId: "MacBook", label: "MacBook" },
                { optionId: "MacBook Air", label: "MacBook Air" },
                { optionId: "MacBook Pro", label: "MacBook Pro" },
                { optionId: "iMac", label: "iMac" },
                { optionId: "Mac Mini", label: "Mac Mini" },
                { optionId: "Mac Pro", label: "Mac Pro" },
                { optionId: "Android Phone", label: "Android Phone" },
                { optionId: "Android Tablet", label: "Android Tablet" },
                { optionId: "Chrome OS Tablet", label: "Chrome OS Tablet" },
                { optionId: "Chrome OS Laptop", label: "Chrome OS Laptop" },
                { optionId: "Chrome OS Desktop", label: "Chrome OS Desktop" },
                { optionId: "Linux Tablet", label: "Linux Tablet" },
                { optionId: "Linux Laptop", label: "Linux Laptop" },
                { optionId: "Linux Desktop", label: "Linux Desktop" },
                { optionId: "Windows Tablet", label: "Windows Tablet" },
                { optionId: "Windows Laptop", label: "Windows Laptop" },
                { optionId: "Windows Desktop", label: "Windows Desktop" },
                { optionId: "Other Device", label: "Device" },
              ]}
              value={this.state.device}
              onChange={(val) => {
                this.setState({
                  device: val,
                });
                window.localStorage.setItem("defaultDevice", val);
              }}
            />
            <br />
            <CaserTextField
              type="password"
              fullWidth
              label="Password"
              value={this.state.password}
              onChange={(val) => this.setState({ password: val })}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  this.handleLoginClick();
                }
              }}
            />
            <br />
            {this.props.authError && (
              <Alert severity="error">{this.props.authError}</Alert>
            )}

            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => this.handleLoginClick(event)}
            >
              Login
            </Button>
          </Card>
        </Container>
      </div>
    );
  }
}

export default Login;
