// let actions = {
//   matrixControl: {
//     label: "HDMI Matrix",
//     run: {
//       module: "HDMIMatrix",
//       method: "selectCommand",
//     },
//     params: [{ name: "value", default: "" }],
//     toggleParam: "value",
//     state: ["smartControl", "matrixStates"],
//     defaultState: { A: null, B: null, C: null, D: null },
//   },
//   cameraPower: {
//     label: "Camera Power",
//     run: {
//       module: "main",
//       method: "power",
//     },
//     params: [{ name: "value", default: true }],
//     toggleParam: "value",
//     state: ["camera", "cameraPowerState"],
//   },
//   audioPower: {
//     label: "Church Audio Power",
//     run: {
//       module: "VeraControl",
//       method: "audioPower",
//     },
//     params: [{ name: "value", default: true }],
//     toggleParam: "value",
//     state: ["camera", "audioPowerState"],
//   },
//   hallAudioPower: {
//     label: "Hall Audio Power",
//     run: {
//       module: "DeviceControl",
//       method: "power",
//     },
//     params: [
//       { name: "device", default: "HallAudio" },
//       { name: "set", default: true },
//     ],
//     toggleParam: "set",
//     state: ["smartControl", "devices", "HallAudio"],
//   },
//   northDeaconTVPower: {
//     label: "North Deacon TV Power",
//     run: {
//       module: "DeviceControl",
//       method: "power",
//     },
//     params: [
//       { name: "device", default: "northDeaconTV" },
//       { name: "set", default: true },
//     ],
//     toggleParam: "value",
//     state: ["smartControl", "devices", "northDeaconTV"],
//   },
//   southDeaconTVPower: {
//     label: "South Deacon TV Power",
//     run: {
//       module: "DeviceControl",
//       method: "power",
//     },
//     params: [
//       { name: "device", default: "southDeaconTV" },
//       { name: "set", default: true },
//     ],
//     toggleParam: "value",
//     state: ["smartControl", "devices", "southDeaconTV"],
//   },
//   northNaveTVPower: {
//     label: "North Nave TV Power",
//     run: {
//       module: "DeviceControl",
//       method: "power",
//     },
//     params: [
//       { name: "device", default: "northNaveTV" },
//       { name: "set", default: true },
//     ],
//     toggleParam: "value",
//     state: ["smartControl", "devices", "northNaveTV"],
//   },
//   southNaveTVPower: {
//     label: "South Nave TV Power",
//     run: {
//       module: "DeviceControl",
//       method: "power",
//     },
//     params: [
//       { name: "device", default: "southNaveTV" },
//       { name: "set", default: true },
//     ],
//     toggleParam: "value",
//     state: ["smartControl", "devices", "southNaveTV"],
//   },
//   oclDisplay: {
//     label: "OCL Display",
//     run: {
//       module: "ChurchSwitcherStreamer",
//       method: "setOCL",
//     },
//     params: [{ name: "value", default: true }],
//     toggleParam: "value",
//     state: ["camera", "oclActive"],
//   },
//   ChurchSwitcherDevice: {
//     label: "Church Switcher",
//     run: {
//       module: "ChurchSwitcherStreamer",
//       method: "setSwitcher",
//     },
//     params: [{ name: "value", default: "atem" }],
//     state: ["smartControl", "switcher", "device"],
//   },
//   ChurchStreamingDevice: {
//     label: "Church Streamer",
//     run: {
//       module: "ChurchSwitcherStreamer",
//       method: "setStreamer",
//     },
//     params: [{ name: "value", default: "wc" }],
//     state: ["smartControl", "streamer", "device"],
//   },
//   selectPreview: {
//     label: "Select Preview Input",
//     run: {
//       module: "ChurchSwitcherStreamer",
//       method: "setPreviewInput",
//     },
//     params: [{ name: "value", default: 1 }],
//     state: ["camera", "preview"],
//   },
//   selectLive: {
//     label: "Select Live Input",
//     run: {
//       module: "ChurchSwitcherStreamer",
//       method: "setLiveInput",
//     },
//     params: [{ name: "value", default: 1 }],
//     state: ["camera", "live"],
//   },
//   switcherTake: {
//     label: "Select Live Input",
//     run: {
//       module: "ChurchSwitcherStreamer",
//       method: "take",
//     },
//   },
//   churchRecord: {
//     label: "Record",
//     run: {
//       module: "ChurchSwitcherStreamer",
//       method: "setRecording",
//     },
//     params: [{ name: "value", default: true }],
//     toggleParam: "value",
//     state: ["camera", "recording"],
//   },
//   churchStream: {
//     label: "Stream",
//     run: {
//       module: "ChurchSwitcherStreamer",
//       method: "setStreaming",
//     },
//     params: [{ name: "value", default: true }],
//     toggleParam: "value",
//     state: ["camera", "streaming"],
//   },
//   ChurchPIPInput: {
//     label: "Church Switcher",
//     run: {
//       module: "ChurchSwitcherStreamer",
//       method: "setPIPInput",
//     },
//     params: [
//       { name: "input", default: null },
//       { name: "location", default: "right" },
//     ],
//     state: ["camera", "pip"],
//   },

//   streamingRecord: {
//     label: "Record in Streaming",
//     run: {
//       module: "WireCastControl",
//       method: "streamingDocRecord",
//     },
//     params: [{ name: "value", default: true }],
//     toggleParam: "value",
//     state: ["camera", "recordingInStreaming"],
//   },
//   streamingStream: {
//     label: "Stream in Streaming",
//     run: {
//       module: "WireCastControl",
//       method: "streamingDocStream",
//     },
//     params: [{ name: "value", default: true }],
//     toggleParam: "value",
//     state: ["camera", "streaming"],
//   },
//   teamsRecord: {
//     label: "Record in Zoom",
//     run: {
//       module: "OBSZoom",
//       method: "SetRecording",
//     },
//     params: [{ name: "value", default: true }],
//     toggleParam: "value",
//     state: ["teams", "recording"],
//   },
//   teamsStream: {
//     label: "Stream in Zoom",
//     run: {
//       module: "OBSZoom",
//       method: "SetRecording",
//     },
//     params: [{ name: "value", default: true }],
//     toggleParam: "value",
//     state: ["teams", "streaming"],
//   },
//   // previewDocument: {
//   //     label: "Preview Document",
//   //     run: {
//   //         module: "WireCastControl",
//   //         method: "previewDocument",
//   //     },
//   //     params: [],
//   //     state: ["camera", "previewDocOpen"]

//   // },
//   // previewOutput: {
//   //     label: "Preview Output",
//   //     run: {
//   //         module: "WireCastControl",
//   //         method: "previewOutput",
//   //     },
//   //     params: [],
//   //     state: ["camera", "previewDocVirtualCamera"]

//   // },
//   // openRecordingDocument: {
//   //   label: "Open Recording Document",
//   //   run: {
//   //     module: "WireCastControl",
//   //     method: "openRecordingDocument",
//   //   },
//   //   params: [],
//   //   state: ["camera", "recordingDocOpen"],
//   // },
//   openStreamingDocument: {
//     label: "Open Streaming Document",
//     run: {
//       module: "WireCastControl",
//       method: "openStreamingDocument",
//     },
//     params: [],
//     state: ["camera", "streamingDocOpen"],
//   },
//   openTeamsDocument: {
//     label: "Open Teams Document",
//     run: {
//       module: "WireCastControl",
//       method: "openTeamsDocument",
//     },
//     params: [],
//     state: ["teams", "teamsDocOpen"],
//   },
//   wait: {
//     label: "Wait",
//     run: {
//       module: "main",
//       method: "wait",
//     },
//     params: [{ name: "timeout", default: 1000 }],
//   },
//   ptControl: {
//     label: "Pan/Tilt",
//     run: {
//       module: "CameraControl",
//       method: "ptMove",
//     },
//     params: [
//       { name: "Camera", default: 1 },
//       { name: "PTObject", default: {} },
//     ],
//   },
//   ptzControl: {
//     label: "Pan/Tilt/Zoom",
//     run: {
//       module: "CameraControl",
//       method: "ptzMove",
//     },
//     params: [
//       { name: "Camera", default: 1 },
//       { name: "PTZObject", default: {} },
//     ],
//   },
//   zControl: {
//     label: "Zoom",
//     run: {
//       module: "CameraControl",
//       method: "zoomMove",
//     },
//     params: [
//       { name: "Camera", default: 1 },
//       { name: "ZObject", default: {} },
//     ],
//   },
//   fControl: {
//     label: "Focus",
//     run: {
//       module: "CameraControl",
//       method: "focusMove",
//     },
//     params: [
//       { name: "Camera", default: 1 },
//       { name: "FObject", default: {} },
//     ],
//   },
//   wbControl: {
//     label: "White Balance",
//     run: {
//       module: "CameraControl",
//       method: "whiteBalanceMove",
//     },
//     params: [
//       { name: "Camera", default: 1 },
//       { name: "wbObject", default: {} },
//     ],
//   },
//   iControl: {
//     label: "Iris",
//     run: {
//       module: "CameraControl",
//       method: "irisMove",
//     },
//     params: [
//       { name: "Camera", default: 1 },
//       { name: "iObject", default: {} },
//     ],
//   },
//   chControl: {
//     label: "Chroma",
//     run: {
//       module: "CameraControl",
//       method: "chromaMove",
//     },
//     params: [
//       { name: "Camera", default: 1 },
//       { name: "ChObject", default: {} },
//     ],
//   },
//   rControl: {
//     label: "Red",
//     run: {
//       module: "CameraControl",
//       method: "redMove",
//     },
//     params: [
//       { name: "Camera", default: 1 },
//       { name: "rObject", default: {} },
//     ],
//   },
//   bControl: {
//     label: "Blue",
//     run: {
//       module: "CameraControl",
//       method: "blueMove",
//     },
//     params: [
//       { name: "Camera", default: 1 },
//       { name: "BObject", default: {} },
//     ],
//   },
//   dControl: {
//     label: "Detail",
//     run: {
//       module: "CameraControl",
//       method: "detailMove",
//     },
//     params: [
//       { name: "Camera", default: 1 },
//       { name: "dObject", default: {} },
//     ],
//   },
//   goToPreset: {
//     label: "Preset",
//     run: {
//       module: "CameraControl",
//       method: "goToPreset",
//     },
//     params: [
//       { name: "Camera", default: 1 },
//       { name: "PresetNumber", default: {} },
//     ],
//   },
//   openZoom: {
//     label: "Open Zoom",
//     run: {
//       module: "WireCastControl",
//       method: "exec",
//     },
//     params: [{ name: "value", default: "./automationZoom.sh" }],
//   },
//   execScript: {
//     label: "execute Script on Secondary",
//     run: {
//       module: "ScriptAutomation",
//       method: "exec",
//     },
//     params: [
//       { name: "value", default: "automationZoom.sh" },
//       { name: "location", default: "server" },
//     ],
//   },
// };

export default class SmartControl {
  constructor(main) {
    this.main = main;
    // console.log("actions", this.actions);
  }
  get actions() {
    return this.main.context.smartControl.actions || {};
  }
  async wait(time) {
    return new Promise((r) => setTimeout(r, time));
  }
  async runAction(name, params) {
    let action = this.actions[name];
    if (typeof params !== "object") params = { value: params };
    let paramArray = this.prepParams(name, params);
    console.log(params, paramArray);
    try {
      await this.main.context.ws.request(
        action.run.module,
        action.run.method,
        paramArray
      );
    } catch (e) {
      console.log(e);
    }
  }
  async toggleAction(name) {
    let action = this.actions[name];
    if (!action.toggleParam)
      throw new Error(
        "The action " +
          action.name +
          " cannot be toggled. Set a toggleParam to enable toggling."
      );
    let currentState = this.getState(name);
    let params = this.prepParams(name, {}, currentState);
    try {
      await this.main.context.ws.request(
        action.run.module,
        action.run.method,
        params
      );
    } catch (e) {
      console.log(e);
    }
  }
  async runActions(actionArray) {
    if (!Array.isArray(actionArray)) actionArray = [actionArray];
    for (let action of actionArray) {
      console.log(action.name);
      this.runAction(action.name, action.params);
      console.log(action.name, "done");
      await this.wait(50);
    }
  }
  prepParams = (actionName, params, toggleFrom) => {
    let ret = [];
    let action = this.actions[actionName];
    if (action.params && action.params.length)
      for (let param of action.params) {
        // console.log(toggleFrom, typeof toggleFrom !== "undefined", params[param.name], params, param.name)
        if (
          typeof toggleFrom !== "undefined" &&
          action.toggleParam === param.name
        )
          ret.push(toggleFrom ? false : true);
        else if (typeof params[param.name] !== "undefined")
          ret.push(params[param.name]);
        else if (typeof param.default !== "undefined") ret.push(param.default);
        else ret.push(null);
      }
    return ret;
  };
  getState(name) {
    let action = this.actions[name];
    if (!action) return null;
    let res = this.main.context;

    for (let acLeg of action.state) {
      // console.log(res, res[acLeg], acLeg)
      if (typeof res[acLeg] === "undefined") {
        res = action.defaultState;
        break;
      }
      res = res[acLeg];
    }
    // console.log(res)
    return res;
  }
  getLabel(name) {
    return (this.actions[name] || {}).label || null;
  }
}
