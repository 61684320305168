import onChange from "on-change";
import { Socket } from "./../../index.mjs";
import isEqual from "lodash.isequal";
import EventEmitter from "wolfy87-eventemitter";
// var EventEmitter = require("wolfy87-eventemitter");
export default class State extends EventEmitter {
  constructor() {
    let config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    super();
    this.state = {};
    this.loaded = false;
    this.onLoad = config.onLoad;
    this.onChange = config.onChange;
    this.wsReceive = config.wsReceive;
    this.domains = config.domains || ["admin", "camera", "displays", "presets", "teams", "smartControl", "schedule", "mediaServer"];
    this.host = config.host || "wss://av.saintmarydelray.com:443/main";
    this.init();
  }
  async init() {
    this.ws = new Socket({
      url: this.host,
      onDataReceive: data => {
        if (!data) return;
        if (data.type === "valueChanged") {
          this.loaded && this.updateValueLocally(data.data);
        } else if (data.type === "messageReceived") {
          this.loaded && this.messageReceived(data.data);
        } else {
          this.wsReceive && this.wsReceive(data);
        }
      }
    });
    this.ws.ws.onOpen.addListener(async () => {
      let all = await this.ws.request("DB", "register", [this.domains, "_socket"]);
      this.state = {
        manifest: all.manifest,
        socketID: all.socketID
      };
      this.loadData(all.data);
    });
  }
  loadData(data) {
    for (let domain of this.domains) {
      let full = data[domain];
      let manifest = this.state.manifest[domain];
      if (typeof this.state[domain] === "undefined") this.state[domain] = {};
      let domainValues = {};
      for (let name in manifest) {
        let value = full[name];
        if (typeof value === undefined) value = manifest[name].default;
        domainValues[name] = value;
        // this.updateValueLocally({ domain, name, value }, true);
      }

      this.state[domain] = onChange(domainValues, path => {
        this.updateValueRemotely({
          domain,
          name: path[0],
          value: this.state[domain][path[0]],
          path
        });
      }, {
        pathAsArray: true,
        ignoreSymbols: true
      });
    }
    // this.state.displays.displays = [];
    this.loaded = true;
    if (typeof this.onLoad === "function") this.onLoad(this.state);
  }
  messageReceived(_ref) {
    let {
      domain,
      name,
      value
    } = _ref;
    this.emitEvent("".concat(domain), [name, value]);
    this.emitEvent("".concat(domain, ":").concat(name), [value]);
  }
  updateValueLocally(_ref2) {
    let {
      domain,
      name,
      value
    } = _ref2;
    // if (isEqual(onChange.target(this.state[domain])[name], value)) return;
    onChange.target(this.state[domain])[name] = value;
    this.onChange && this.onChange(this.state, {
      domain,
      name,
      value,
      location: "remote"
    });
  }
  updateValueRemotely(_ref3) {
    let {
      domain,
      name,
      value,
      path
    } = _ref3;
    this.ws.request("DB", "updateValueRemotely", [domain, name, this.state[domain][name], path, this.state.socketID]);
    // if (isEqual(onChange.target(this.state[domain])[name], value)) return;
    this.onChange && this.onChange(this.state, {
      domain,
      name,
      value,
      path,
      location: "local"
    });
  }
  sendMessage(_ref4) {
    let {
      domain,
      name,
      value
    } = _ref4;
    this.ws.request("DB", "message", [domain, name, this.state[domain][name], path, this.state.socketID]);
    // if (isEqual(onChange.target(this.state[domain])[name], value)) return;
    this.onChange && this.onChange(this.state, {
      domain,
      name,
      value,
      path,
      location: "local"
    });
  }
}