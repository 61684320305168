import React, { Component } from "react";
import pagesManifest from "../../Sections/";
import "./AppNew.css";
class PageTabs extends Component {
  constructor() {
    super();
    this.state = {
      currentPage: -1,
      pages: new Map(),
      pageOpenerState: false,
    };
  }
  hot_keys = {
    "meta+w": {
      // combo from mousetrap
      priority: 1,
      handler: (event) => {
        this.removePage(this.state.currentPage);
        event.preventDefault();
      },
    },
    "meta+e": {
      // combo from mousetrap
      priority: 1,
      handler: (event) => {
        this.removePage(this.state.currentPage);
      },
    },
    "meta+t": {
      // combo from mousetrap
      priority: 1,
      handler: (event) => {
        this.addPage(1);
      },
    },
  };

  componentDidMount() {
    // this.OS = this.props.match.params.OS;
    // this.context.pageAdapter = this;
    // this.context.addTab = this.addPage.bind(this);
    this.addDefaultPages();
    this.chooseDefaultOpenPage();
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.defaultOpenKey !== this.props.defaultOpenKey &&
      this.props.defaultOpenKey !== this.state.currentPage
    )
      this.setActivePage(this.props.defaultOpenKey);
  }
  addDefaultPages() {
    for (let pagekey of pagesManifest.keys()) {
      if (pagesManifest.get(pagekey).defaultAdd) {
        console.log(pagekey);
        this.addPage(pagekey, false);
      }
    }
  }
  reorderPages() {
    // if (this.state.pages.length < 2) return;
    var pages = new Map(
      [...this.state.pages.entries()].sort((a, b) => {
        return a[1].pinned < b[1].pinned;
      })
    );
    this.setState({ pages });
  }
  chooseDefaultOpenPage() {
    // if (this.state.pages.length < 2) return;
    let defaultOpenPageKey = this.props.defaultOpenKey || null;

    if (defaultOpenPageKey === null) {
      for (let key of this.state.pages.keys()) {
        // if (!page.pinned) {
        defaultOpenPageKey = key;
        //   break;
        // }
        // console.log(page);
      }
    }
    this.setActivePage(defaultOpenPageKey);
  }
  addPage = (key, show = true, props = {}) => {
    const pageManifest = pagesManifest.get(key);
    const pageIndex = pageManifest.name;
    this.setState({
      pages: this.state.pages.set(pageIndex, {
        title: pageManifest.name,
        pageManifestIndex: key,
        pinned: pageManifest.pinned ? 1 : 0,
        icon: pageManifest.icon,
        props,
      }),
    });
    if (show) this.setActivePage(pageIndex);
    // this.reorderPages();
  };

  removePage = function (index) {
    if (this.state.pages.size < 2) {
      return;
    }
    var currentPage = this.state.currentPage;
    if (this.state.currentPage === index) {
      let keys = Array.from(this.state.pages.keys());
      if (keys.indexOf(index) > 0) {
        currentPage = keys[keys.indexOf(index) - 1];
      } else {
        currentPage = keys[keys.indexOf(index) + 1];
      }
    }
    this.state.pages.delete(index);
    this.setState({
      pages: this.state.pages,
      currentPage: currentPage,
    });
  };
  page(index) {
    if (!this.state.pages.has(index)) {
      return null;
    }
    return this.state.pages.get(index);
  }
  currentPage() {
    return this.page(this.state.currentPage);
  }
  pagesByType(type) {
    // let arr = [];
    // for (let [key, page] of this.state.pages) {
    // 	if (!page.pinned) {
    // 		let defaultOpenPageKey = key;
    // 		break;
    // 	}
    // 	console.log(page);
    // }
  }
  setActivePage = function (index) {
    if (this.props.onChooseSection) this.props.onChooseSection(index);
    this.setState({
      currentPage: index,
    });
  };
  isActivePage = function (index) {
    if (index === this.state.currentPage) {
      return "current";
    }
    return "";
  };
  setPageTitle(index, title) {
    var pgs = this.state.pages;
    if (pgs.get(index).title !== title) {
      pgs.get(index).title = title;
      this.setState({
        pages: pgs,
      });
    }
  }
  tabClick = (event, newValue) => {
    console.log("YES");
    this.setActivePage(newValue);
  };
  leftDivider = (pageIndex) => {
    if (pageIndex === this.state.currentPage) return false;

    let keys = Array.from(this.state.pages.keys());
    // console.log(keys);
    let index = keys.indexOf(pageIndex);
    if (index === 0) return false;
    if (keys[index - 1] === this.state.currentPage) return false;
    return true;
  };
  render() {
    var self = this;
    return (
      <div
        className={
          "caser-pageTabs-container" +
          ((this.props.tabPosition || "top") === "bottom" ? " bottom" : "")
        }
      >
        {!this.props.tabKiosk && (
          <div className="caser-pageTabs-tabbar-container">
            <div className="caser-pageTabs-tabbar-container-inner">
              {Array.from(this.state.pages.keys()).map((i) => {
                var tab = this.state.pages.get(i);
                let pinned = tab.pinned;
                if (pinned) {
                  return (
                    <PinnedTab
                      key={i}
                      tab={tab}
                      value={i}
                      // leftDivider={this.leftDivider(i)}
                      selected={this.state.currentPage === i}
                      onChange={this.tabClick}
                    />
                  );
                } else {
                  return (
                    <ClosableTab
                      key={i}
                      tab={tab}
                      value={i}
                      // leftDivider={this.leftDivider(i)}
                      selected={this.state.currentPage === i}
                      onChange={this.tabClick}
                      onClose={() => this.removePage(i)}
                    />
                  );
                }
              })}

              {/* <Dropdown overlay={menu} trigger={["click"]}>
              <IconButton
                size={"small"}
                style={{ margin: "5px" }}
                onClick={this.handleClick}
              >
                <AddIcon fontSize={"small"} />
              </IconButton>
            </Dropdown> */}
              <div style={{ flexGrow: 1 }} />
              <div style={{ flexGrow: 0 }}>{this.props.right}</div>
            </div>
          </div>
        )}
        <div className="caser-pageTabs-tabcontent-container fadeIn">
          <div className="caser-pageTabs-tabcontent-container-innner">
            {Array.from(this.state.pages.keys()).map((index) => {
              var current = this.state.pages.get(index);
              // console.log("GO", index, this.state.currentPage === index)
              return (
                <div
                  key={index}
                  className={
                    "caser-pageTab-element" +
                    (this.state.currentPage === index ? " open" : "")
                  }
                >
                  <PageRender
                    index={index}
                    page={current}
                    main={this.props.main}
                    isOpen={this.state.currentPage === index}
                    setTitle={(title) => {
                      self.setPageTitle(index, title);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      //   <div>
      //     <TextBar
      //       bodyStyle={{ paddingLeft: 0 }}
      //       className={"tabBar"}
      //       right={
      //         <>

      //         </>
      //       }
      //     >
      //       <Tabs
      //         value={
      //           this.state.currentPage === -1 ? false : this.state.currentPage
      //         }
      //         classes={{ flexContainer: "tabBarFlexContainer" }}
      //         // indicatorColor={"#00000000"}
      //         variant="scrollable"
      //         onChange={this.tabClick}
      //       >
      //         {Array.from(this.state.pages.keys()).map((i) => {
      //           var tab = this.state.pages.get(i);
      //           let pinned = tab.pinned;
      //           if (pinned) {
      //             return (
      //               <PinnedTab
      //                 key={i}
      //                 tab={tab}
      //                 value={i}
      //                 leftDivider={this.leftDivider(i)}
      //               />
      //             );
      //           } else {
      //             return (
      //               <ClosableTab
      //                 key={i}
      //                 tab={tab}
      //                 value={i}
      //                 leftDivider={this.leftDivider(i)}
      //                 // selected={
      //                 // 	this.state.currentPage === i ? 1 : 0
      //                 // }
      //                 onClose={() => this.removePage(i)}
      //               />
      //             );
      //           }
      //         })}
      //         <div
      //           key={-1}
      //           style={{
      //             height: 35,
      //             width: 35,
      //             textAlign: "center",
      //             zIndex: 100,
      //           }}
      //         >
      //           <Dropdown overlay={menu} trigger={["click"]}>
      //             <IconButton
      //               style={{ marginTop: "8px" }}
      //               onClick={this.handleClick}
      //             >
      //               <AddIcon fontSize={"small"} />
      //             </IconButton>
      //           </Dropdown>
      //         </div>
      //       </Tabs>
      //     </TextBar>

      //     <div className={"pageShell"}>
      //       {Array.from(this.state.pages.keys()).map((index) => {
      //         var current = this.state.pages.get(index);
      //         return (
      //           <PageRender
      //             key={index}
      //             index={index}
      //             page={current}
      //             selected={this.state.currentPage === index}
      //             setTitle={(title) => {
      //               self.setPageTitle(index, title);
      //             }}
      //             pageAdapter={this}
      //             style={styles.page}
      //           />
      //         );
      //       })}
      //     </div>
      //   </div>
    );
  }
}

class ClosableTab extends React.PureComponent {
  closeTab = (e) => {
    e.stopPropagation();
    this.props.onClose();
  };
  render() {
    console.log(this.props);
    return (
      <div
        className={
          "caser-pageTabs-tabbar-element" + (this.props.selected ? " open" : "")
        }
        onClick={() => this.props.onChange(undefined, this.props.value)}
      >
        {this.props.tab.title}
        {/* <IconButton onClick={this.closeTab} size="small">
          <CloseIcon fontSize="small" />
        </IconButton> */}
      </div>
      //   <>
      //     {/* {this.props.leftDivider ? (
      // 				<div
      // 					style={{
      // 						width: "1.5px",
      // 						height: "28px",
      // 						marginTop: "6px",
      // 						background: "#888"
      // 					}}
      // 				/>
      // 			) : (
      // 				<div
      // 					style={{
      // 						width: "1.5px",
      // 						height: "28px",
      // 						marginTop: "6px"
      // 					}}
      // 				/>
      // 			)} */}
      //     <TextBar
      //       bodyStyle={{ padding: "0px 10px" }}
      //       rightStyle={{ paddingRight: "0px" }}
      //       right={
      //         <IconButton onClick={this.closeTab}>
      //           <CloseIcon fontSize="small" />
      //         </IconButton>
      //       }
      //       {...this.props}
      //       sel={this.props.selected ? "true" : "false"}
      //       className={"caserTabButton"}
      //       onClick={() => this.props.onChange(undefined, this.props.value)}
      //     >
      //       {this.props.tab.title}
      //     </TextBar>
      //   </>
    );
  }
}

class PinnedTab extends React.PureComponent {
  render() {
    console.log(this.props);
    return (
      <div
        className={
          "caser-pageTabs-tabbar-element pinned" +
          (this.props.selected ? " open" : "")
        }
        onClick={() => this.props.onChange(undefined, this.props.value)}
      >
        {this.props.tab.title}
      </div>
    );
  }
}
class PageRender extends React.Component {
  setPageTitle(title) {
    this.props.page.title = title;
  }
  TagName = Object.assign(
    pagesManifest.get(this.props.page.pageManifestIndex).class,
    {}
  );
  // shouldComponentUpdate() {
  //   return false;
  // }
  render() {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          flexDirection: "column",
        }}
      >
        {!this.props.isOpen &&
        pagesManifest.get(this.props.page.pageManifestIndex).renderClosed ===
          false ? null : (
          <this.TagName
            setPageTitle={this.props.setTitle}
            main={this.props.main}
            isOpen={this.props.isOpen}
            {...(this.props.page.props || {})}
          />
        )}
      </div>
    );
  }
}

// export default hotkeys(PageTabs);
export default PageTabs;
