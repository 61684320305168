import ws from "../ws/WSClient.mjs";
import mediasoup from "mediasoup-client";
class MSClient {
  constructor(component, isLocal) {
    this.component = component;
    this.hostname = window.location.hostname;
    this.socket = null;
    this.device = null;
    this.producers = new Map();
    this.producerTransport = null;
    this.consumerTransport = null;
    this.isLocal = isLocal;
    this.subscribable = !isLocal;
    this.producable = isLocal;
    this.connected = false;
    this.connectedWait = null;
    this.consumerData = null;
    this.availableProducers = [];
  }
  connect() {
    return new Promise((r, e) => {
      this.socket = new ws("wss://av.saintmarydelray.com/server", {
        packMessage: data => JSON.stringify(data),
        unpackMessage: data => JSON.parse(data),
        attachRequestId: (data, requestId) => Object.assign({
          id: requestId
        }, data),
        // attach requestId to message as `id` field
        extractRequestId: data => data && data.requestId // read requestId from message `id` field
      });

      this.socket.request = async (method, data) => {
        return new Promise((r, e) => {
          if (this.socket.isOpened) this.socket.sendRequest({
            type: "request",
            method,
            data
          }).then(messageData => {
            if (messageData.status === 1) r(messageData.data);else if (messageData.status === 0) e(messageData.data);
          }).catch(e);
        });
      };
      this.socket.onOpen.addListener(async () => {
        this.subscribable = !this.isLocal;
        this.producable = this.isLocal;

        // if (this.component.deviceName)
        //   await this.socket.request("setName", this.component.deviceName);

        await this.loadDevice(await this.socket.request("getRouterRtpCapabilities"));
        this.connected = true;
        if (this.isLocal) {
          await this.publish();
          for (let producerName in this.component.devices) {
            let device = this.component.devices[producerName];
            if (device.isLocal) device.produce();
          }
        }
        this.subscribe();
        r();
      });
      this.socket.onClose.addListener(() => {
        if (this.producerTransport) this.producerTransport.close();
        this.connected = false;
        for (let cliientName in this.component.devices) {
          let device = this.component.devices[cliientName];
          device.serverDisconnect();
        }
        this.device = null;
        this.producers = new Map();
        this.producerTransport = null;
        this.consumerTransport = null;
        this.connected = false;
        this.connectedWait = null;
        this.consumerData = null;
        this.availableProducers = [];
      });
      this.socket.onError.addListener(error => {
        console.error("could not connect to %s%s (%s)", error.message);
        e("could not connect to %s%s (%s)" + error.message);
        if (this.isLocal) this.producable = false;else this.subscribable = true;
        this.connected = false;
      });
      this.socket.onUnpackedMessage.addListener(data => {
        if (data.type === "notification") this.onNotification(data.name, data.data);
        // console.log("NEW PRODUCER");
        // if (this.isLocal === false)
        //   this.connected ? this.subscribe() : (this.hasProducer = true);
      });

      this.socket.open();
    });
  }
  async disconnect() {
    await this.socket.request("close");
    await new Promise((r, e) => {
      this.socket.onClose.addListener(r);
      this.socket.close();
    });
  }
  async onNotification(name, data) {
    console.log("newProducers");
    if (name === "newProducers") {
      console.log("newProducer-notification");
      if (Array.isArray(data)) {
        for (let producer of data) {
          let device = this.component.devices[producer.producerName];
          if (device.isRemote) device.serverConnect();
        }
      }
    }
    if (name === "removedProducers") {
      if (Array.isArray(data)) {
        for (let producer of data) {
          if (this.availableProducers.includes(producer.producerName)) this.availableProducers.splice(this.availableProducers.indexOf(producer.producerName), 1);
        }
      }
    }
  }
  async loadDevice(routerRtpCapabilities) {
    try {
      this.device = new mediasoup.Device();
    } catch (error) {
      if (error.name === "UnsupportedError") {
        console.error("browser not supported");
      }
      console.log(error);
    }
    await this.device.load({
      routerRtpCapabilities
    });
  }
  async publish() {
    if (!this.producable) {
      return null;
    }
    if (!this.device) {
      try {
        await new Promise((r, e) => {
          let time = 0;
          let interv = setInterval(() => {
            if (this.device) r();
            if (time > 10) {
              clearInterval(interv);
              e("Timeout");
            }
            ++time;
          }, 1000);
        });
      } catch (e) {
        console.log(e);
        return null;
      }
    }
    const data = await this.socket.request("createProducerTransport", {
      forceTcp: false,
      rtpCapabilities: this.device.rtpCapabilities
    });
    if (data.error) {
      console.error(data.error);
      return null;
    }
    this.producerTransport = this.device.createSendTransport(data);
    this.producerTransport.on("connect", async (_ref, callback, errback) => {
      let {
        dtlsParameters
      } = _ref;
      this.socket.request("connectProducerTransport", {
        dtlsParameters
      }).then(callback).catch(errback);
    });
    this.producerTransport.on("produce", async (params, callback, errback) => {
      try {
        const {
          id
        } = await this.socket.request("produce", {
          transportId: this.producerTransport.id,
          kind: params.kind,
          rtpParameters: params.rtpParameters,
          appData: params.appData
        });
        callback({
          id
        });
      } catch (err) {
        errback(err);
      }
    });
    let closeProducers = async () => {
      for (let producer of this.producers.values()) {
        await producer.close();
        this.producers.delete(producer.id);
      }
    };
    this.producerTransport.on("connectionstatechange", state => {
      switch (state) {
        case "connecting":
          this.producable = false;
          // $txtPublish.innerHTML = 'publishing...';
          // $fsPublish.disabled = true;
          // $fsSubscribe.disabled = true;
          break;
        case "connected":
          // document.querySelector('#local_video').srcObject = stream;
          // $txtPublish.innerHTML = 'published';
          // $fsPublish.disabled = true;
          // $fsSubscribe.disabled = false;
          break;
        case "failed":
          this.producable = true;
          closeProducers();
          // this.producerTransport.close();

          break;
        case "closed":
          this.producable = true;
          // this.producerTransport.close();
          closeProducers();
          break;
        case "disconnected":
          this.producable = true;
          // this.producerTransport.close();
          closeProducers();
          break;
        default:
          break;
      }
    });
  }
  async produce(name, streamingDevice) {
    if (this.producerTransport === null) return null;
    let track = streamingDevice.firstTrack;
    if (track === null) return null;
    if (this.producers.has(name)) {
      await this.producers.get(name).close();
      this.producers.delete(name);
    }
    let producer = await this.producerTransport.produce({
      track: track,
      stopTracks: false,
      appData: {
        streamName: name,
        active: streamingDevice.stream.active
      }
    });
    this.producers.set(name, producer);
    return producer;
  }
  async subscribe() {
    // await this.loadDevice(
    //   await this.socket.request("getRouterRtpCapabilities")
    // );
    this.subscribable = false;
    const data = await this.socket.request("createConsumerTransport", {
      forceTcp: false
    });
    if (data.error) {
      console.error(data.error);
      this.subscribable = true;
      return;
    }
    if (!this.device.loaded) return;
    this.consumerTransport = this.device.createRecvTransport(data);
    // const onNewStream = this.consume(transport);
    setInterval(() => this.socket.request("sendProducers"), 10000);
    this.consumerTransport.on("connect", (_ref2, callback, errback) => {
      let {
        dtlsParameters
      } = _ref2;
      this.socket.request("connectConsumerTransport", {
        transportId: this.consumerTransport.id,
        dtlsParameters
      }).then(callback).catch(errback);
    });
    let conTimeout = null;
    this.consumerTransport.on("connectionstatechange", async state => {
      switch (state) {
        case "connecting":
          this.subscribable = false;
          conTimeout = setTimeout(() => console.error("DidNotConnect"), 3000);
          // $txtSubscription.innerHTML = 'subscribing...';
          // $fsSubscribe.disabled = true;
          this.consumerTransportConnected = false;
          break;
        case "connected":
          clearTimeout(conTimeout);
          this.consumerTransportConnected = true;
          // const streams = await onNewStream;
          // await this.socket.request("resume");
          // this.component.setState({ streams, newStream: true })

          break;
        case "failed":
          this.consumerTransportConnected = false;
          this.consumerTransport.close();
          // $txtSubscription.innerHTML = 'failed';
          // $fsSubscribe.disabled = false;
          this.subscribable = true;
          break;
        default:
          this.consumerTransportConnected = false;
          break;
      }
    });
    // let streams = await onNewStream;
    // await this.socket.request("resume");
    // this.component.setState({ streams, newStream: true })
  }

  async consume(name) {
    if (this.consumerTransport === null) return null;
    const {
      rtpCapabilities
    } = this.device;
    // if (this.consumerData === null)
    this.consumerData = await this.socket.request("consume", {
      rtpCapabilities,
      streamName: name
    });
    // if (!Array.isArray(this.consumerData) || !this.consumerData.length) {
    //   this.consumerData = null;
    //   return null;
    // }
    // const streams = {};

    // for (let producerData of this.consumerData) {
    if (this.consumerData === null) return;
    const {
      producerId,
      id,
      kind,
      rtpParameters,
      appData
    } = this.consumerData;
    let codecOptions = {};
    // if (appData.streamName === name) {
    const consumer = await this.consumerTransport.consume({
      id,
      producerId,
      kind,
      rtpParameters,
      codecOptions,
      appData
    });
    // consumer.track.enabled = false;
    consumer.observer.on("pause", () => consumer.pause());
    consumer.observer.on("resume", () => consumer.resume());
    return consumer;
    // this.component.devices[consumer.appData.streamName].connect(new MediaStream([consumer.track]));
    // break;
    // }
    // // }
    // return null;
  }

  async resume() {
    // await this.socket.request("resume");
  }
}
export default MSClient;