import React from "react";

export default class Video extends React.Component {
  constructor() {
    super();
    this.state = {
      active: false,
    };
    this.canvas = React.createRef();
  }
  componentDidMount() {
    this.attachToCamView();
    this.onLive();
    this.Interval = setInterval(this.checkVideo, 1000);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.view !== this.props.view) {
      this.setVideo();
    }
  }
  componentWillUnmount() {
    this.detachFromCamView();
    clearInterval(this.Interval);
  }
  attachToCamView = () => {
    if (!this.device) {
      console.log("Device not loaded", this.device);
      return;
    }
    this.device.on("active", this.onLive);
    this.device.on("inactive", this.onEnd);
    this.attached = true;
  };
  detachFromCamView = () => {
    if (!this.device) {
      console.log("Device not loaded", this.device);
      return;
    }
    this.device.off("active", this.onLive);
    this.device.off("inactive", this.onEnd);
    this.attached = false;
  };
  setVideo = () => {
    if (this.canvas.current === null || !this.device) return;
    this.device.attach(this.canvas.current, { force: true });
  };
  checkVideo = () => {
    if (!this.attached) this.attachToCamView();
    if (this.state.active && (!this.device || !this.device.active))
      this.onEnd();
    else if (!this.state.active && this.device && this.device.active)
      this.onLive();
  };
  onLive = () => {
    this.setState({ active: true });
    this.setVideo();
  };
  onEnd = () => {
    this.setState({ active: false });
  };
  get view() {
    if (
      !this.props.streaming ||
      !this.props.streaming.cameras ||
      !this.props.streaming.cameras[this.props.view]
    ) {
      return null;
    }
    return this.props.streaming.cameras[this.props.view];
  }
  get device() {
    if (
      !this.props.streaming.devices ||
      !this.props.streaming.devices[this.deviceName]
    ) {
      return null;
    }
    return this.props.streaming.devices[this.deviceName];
  }
  get deviceName() {
    if (this.view) return this.view.device;
    return this.props.view;
  }
  get VideoStyle() {
    let res = {
      position: "absolute",
      top: 0,
      left: 0,
      width: 100 + "%",
    };
    if (this.view && this.view.config) {
      res.top =
        ((this.view.config.anchor || {}).y || 0) *
          (this.view.config.scale || 1) *
          -100 +
        "%";
      res.left =
        ((this.view.config.anchor || {}).x || 0) *
          (this.view.config.scale || 1) *
          -100 +
        "%";
      res.width = (this.view.config.scale || 1) * 100 + "%";
    }
    return res;
  }
  render() {
    return (
      <div
        style={{
          background: "#212121",
          width: "100%",
          paddingTop: "56.25%",
          position: "relative",
        }}
      >
        <video style={this.VideoStyle} ref={this.canvas} id={this.props.id} />
      </div>
    );
  }
}
