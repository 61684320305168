import {
  FaFileVideo,
  FaFileImage,
  FaFileAudio,
  FaClone,
  FaGlobe,
} from "react-icons/fa";
import { RiLiveLine } from "react-icons/ri";
import { MdScreenShare } from "react-icons/md";
import { BiColorFill } from "react-icons/bi";
let schema = {
  iframe: {
    label: "Webpage",
    properties: {
      src: { type: "string" },
      asset: { type: "string" },
    },
    icon: FaGlobe,
  },
  text: {
    label: "Text",
    properties: {
      text: { type: "string" },
      class: {
        type: "radio",
        label: "Text Type",
        opts: [
          {
            optionId: "textElement-title",
            label: "Title",
          },
          {
            optionId: "textElement-heading",
            label: "Heading",
          },
          {
            optionId: "textElement-subheading",
            label: "Subheading",
          },
          {
            optionId: "textElement-text",
            label: "Text",
          },
        ],
      },
    },
    icon: FaGlobe,
  },
  translation: {
    label: "Translation",
    properties: {
      text: { type: "longstring" },
    },
    icon: FaGlobe,
  },
  zoom: {
    label: "Zoom",
    properties: {
      meetingId: { type: "string" },
    },
    singleton: true,
    icon: FaFileVideo,
  },
  image: {
    label: "Image",
    properties: {
      src: { type: "string" },
      asset: { type: "string" },
      viewType: {
        type: "radio",
        label: "View Type",
        opts: [
          {
            optionId: "contain",
            label: "Contain",
          },
          {
            optionId: "cover",
            label: "Cover",
          },
        ],
      },
      viewPosition: {
        type: "select",
        label: "View Baseline",
        opts: [
          {
            optionId: "Center",
            label: "center center",
          },
          {
            optionId: "Top",
            label: "center top",
          },
          {
            optionId: "Bottom",
            label: "center bottom",
          },
          {
            optionId: "Left",
            label: "left center",
          },
          {
            optionId: "Right",
            label: "right center",
          },
          {
            optionId: "Top Left",
            label: "left top",
          },
          {
            optionId: "Top Right",
            label: "right top",
          },
          {
            optionId: "Bottom Left",
            label: "left bottom",
          },
          {
            optionId: "Bottom Right",
            label: "right bottom",
          },
        ],
      },
    },
    icon: FaFileImage,
  },
  audio: {
    label: "Audio",
    properties: {
      src: { type: "string" },
      asset: { type: "string" },
    },
    icon: FaFileAudio,
  },
  video: {
    label: "Video",
    properties: {
      src: { type: "string" },
      asset: { type: "string" },
    },
    icon: FaFileVideo,
  },
  stream: {
    label: "Stream",
    properties: {
      location: {
        type: "select",
        label: "Local Location",
        opts: "getLocations",
      },
      streamLabel: {
        show: (props) => props.element.props && props.element.props.location,
        type: "select",
        label: "Streaming Device",
        opts: "getLocationStreamingDevices",
        onChangeFunctionName: "updateStreamingDevice",
      },
      type: {
        show: (props) => false,
        type: "string",
        label: "device type",
      },
      shouldStream: {
        type: "radio",
        label: "Should Stream",
        opts: [
          {
            optionId: false,
            label: "No",
          },
          {
            optionId: true,
            label: "Yes",
          },
        ],
      },
      constraints: {
        show: (props) => props.element.props && props.element.props.type,
        type: "object",
        label: "Device Constraints",
        default: "constraintDefault",
      },
    },
    icon: RiLiveLine,
  },
  screen: {
    label: "Screen",
    properties: {
      screenLabel: { type: "string", label: "Screen Label" },
      streamLabel: {
        show: (props) => props.element.props && props.element.props.location,
        type: "select",
        label: "Streaming Device",
        opts: "getLocationStreamingDevices",
        onChangeFunctionName: "updateStreamingDevice",
      },
    },
    icon: MdScreenShare,
  },
  color: {
    label: "Solid Color",
    properties: {
      color: { type: "string", label: "Background Color" },
    },
    icon: BiColorFill,
  },
  clone: {
    label: "Cloned Emenet",
    properties: {
      elementName: { type: "string" },
    },
    icon: FaClone,
  },
};
export default schema;
