import React from "react";
import { MdPictureInPicture } from "react-icons/md";

export default class PIPButton extends React.Component {
  render() {
    let pipState = this.props.SmartControl.getState("ChurchPIPInput");
    return (
      <div
        className={
          "pipButton " +
          this.props.location +
          (pipState.input === this.props.input &&
          pipState.location === this.props.location
            ? " open"
            : "")
        }
        onClick={(e) => {
          console.log("PIP", {
            input:
              pipState.input === this.props.input &&
              pipState.location === this.props.location
                ? null
                : this.props.input,
            location: this.props.location,
          });
          this.props.SmartControl.runAction("ChurchPIPInput", {
            input:
              pipState.input === this.props.input &&
              pipState.location === this.props.location
                ? null
                : this.props.input,
            location: this.props.location,
          });
          e.stopPropagation();
        }}
      >
        <MdPictureInPicture style={{ height: "100%", width: "100%" }} />
      </div>
    );
  }
}
