import React from "react";
export default class IFrame extends React.PureComponent {
  componentDidMount() {
    this.props.element.ready = true;
  }
  render() {
    return (
      <div
        style={{
          width: "inherit",
          height: "inherit",
          backgroundColor:
            this.props.element.elementObject.props.color || "black",
        }}
      ></div>
    );
  }
}
