import isElectron from "is-electron";
// import WebHid from "./../Tools/webhid";
const EventEmitter = require("wolfy87-eventemitter");

let XKEYS_MAPPING = {
  MODE_STREAM: 0,
  MODE_TEAMS: 1,
  MODE_CONTROL: 2,
  RECORD: 3,
  POWER_CAMERA: 4,
  POWER_AUDIO: 5,
  PREVIEW_CAM1: 6,
  LIVE_CAM1: 7,
  VIEW_COLOR: 8,
  OCL_PREVIOUS: 9,
  OCL_LEFT: 10,
  STREAM: 11,
  POWER_WIRECAST: 12,
  POWER_TVs: 13,
  PREVIEW_CAM2: 14,
  LIVE_CAM2: 15,
  VIEW_AUDIO: 16,
  OCL_NEXT: 17,
  OCL_RIGHT: 18,
  OCL_MODE: 19,
  POWER_TEXT: 20,
  POWER_PROJECTOR: 21,
  PREVIEW_CAM3: 22,
  LIVE_CAM3: 23,
  VIEW_VIDEO: 24,
  OCL_DECREASE_SIZE: 25,
  OCL_INCREASE_SIZE: 26,
  PREVIEW_CAM4: 30,
  LIVE_CAM4: 31,
  PRESET_1: 32,
  PRESET_7: 33,
  PRESET_13: 34,
  PREVIEW_CAM5: 38,
  LIVE_CAM5: 39,
  PRESET_2: 40,
  PRESET_8: 41,
  PRESET_14: 42,
  CONTROL_CAM1: 46,
  AUTO_PREVIEW: 47,
  PRESET_3: 48,
  PRESET_9: 49,
  PRESET_15: 50,
  CONTROL_CAM2: 54,
  AUTO_CONTROL: 55,
  PRESET_4: 56,
  PRESET_10: 57,
  PRESET_16: 58,
  PRESET_ADD: 59,
  PRESET_SPEED_SLOW: 60,
  ADJUST_ZOOM: 61,
  CONTROL_CAM3: 62,
  AUTO_LIVE: 63,
  PRESET_5: 64,
  PRESET_11: 65,
  PRESET_17: 66,
  PRESET_DELETE: 67,
  PRESET_SPEED_NORMAL: 68,
  ADJUST_TOGGLE: 69,
  CONTROL_CAM4: 70,
  TAKE: 71,
  PRESET_6: 72,
  PRESET_12: 73,
  PRESET_18: 74,
  PRESET_REPLACE: 75,
  PRESET_SPEED_FAST: 76,
  ADJUST_AUTO: 77,
  CONTROL_CAM5: 78,
};
export { XKEYS_MAPPING };

export default class extends EventEmitter {
  constructor(main) {
    super();
    this.main = main;
    this.KEYMAP = XKEYS_MAPPING;
    this.backlights = {};
    // this.webhid = new WebHid();
    this.init();
  }
  async init() {
    // console.log("HID", await this.webhid.getDevices());
    console.log("keyPressed", window.require);

    if (isElectron() && window.require) {
      console.log("keyPressed");
      const { ipcRenderer } = window.require("electron");
      this.ipc = ipcRenderer;
      this.initXkeys();
    }
  }
  initXkeys() {
    console.log("keyPressed", "init");
    this.ipc.on("connected", () => {
      this.setAllBacklight();
    });

    this.ipc.on("keyPressed", (event, keyIndex) => {
      console.log("keyPressed", keyIndex);
      this.emitEvent("pressed", [keyIndex]);
    });
    this.ipc.on("keyReleased", (event, keyIndex) => {
      this.emitEvent("released", [keyIndex]);
    });
    this.ipc.on("ptChanged", (event, position) => {
      this.emitEvent("ptChanged", [position]);
    });
    this.ipc.on("zChanged", (event, position) => {
      this.emitEvent("zChanged", [position]);
    });
  }

  resetZBaseline() {
    if (this.active)
      this.ipc.send("xkeysMethod", {
        method: "resetZBaseline",
        args: [...arguments],
      });
  }
  setBacklight() {
    if (this.active) {
      let key = parseInt(arguments[0]) + 1;
      let show = arguments[1] ? true : false;
      let color = arguments[2] === true ? "red" : "blue";
      if (typeof this.backlights[key + color] === "undefined")
        this.backlights[key + color] = null;
      if (this.backlights[key + color] !== show) {
        let setColor = show ? color : false;
        if (!show) {
          if (color === "blue" && this.backlights[key + "red"]) {
            setColor = "red";
          } else if (color === "red" && this.backlights[key + "blue"]) {
            setColor = "blue";
          }
        }
        if (show) {
          if (color === "blue" && this.backlights[key + "red"]) {
            setColor = "redblue";
          } else if (color === "red" && this.backlights[key + "blue"]) {
            setColor = "redblue";
          }
        }
        this.ipc.send("xkeysAction", {
          method: "setBacklight",
          args: [key, setColor],
        });
        this.backlights[key + color] = show;
        if (show === false) {
        }
      }
    }
  }
  setAllBacklight() {
    if (this.active)
      this.ipc.send("xkeysAction", {
        method: "setAllBacklight",
        args: [...arguments],
      });
  }
  setBacklightIntensity() {
    if (this.active)
      this.ipc.send("xkeysAction", {
        method: "setBacklightKeyIntensity",
        args: [...arguments],
      });
  }
  get active() {
    return this.ipc ? true : false;
  }
}
