// import "./App.css";
import "./AppNew.css";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import isElectron from "is-electron";
import "antd/dist/antd.css";
import $ from "jquery";
import Login from "./Sections/Login";
import Touch from "./Sections/Touch";
import Wait from "./Sections/Wait";
import xkeys from "./classes/xkeys";
import SmartControl from "./classes/smartControl";
import CameraControl from "./classes/cameraControl";
import PageTabs from "./Tools/mainComponents/PageTabs";
import Clock from "react-live-clock";
import { BsVolumeMute, BsVolumeUp } from "react-icons/bs";
import "./Tools/mainComponents/AppNew.css";
import { Streaming } from "avc-client-tools";
import DBContext, { DB } from "./DBContext";
import Display from "./displays/Display";
import Globals from "./Tools/Globals";
import StreamMedium from "./Tools/StreamMedium";
import ControlBasic from "./Sections/ControlBasic";

let deviceConfig = {
  mv: {
    type: "videoinput",
    label: "USB Capture HDMI",
    description: "ATEM Multiviewer 1",
    title: "mv",
    shouldStream: true,
    width: 1920,
    height: 1080,
    constraints: (deviceId) => {
      return {
        video: {
          deviceId: {
            exact: deviceId,
          },
          width: {
            exact: 1920,
          },
          height: {
            exact: 1080,
          },
          // frameRate: {
          //   exact: 30,
          // },
        },
      };
    },
  },
  presonus: {
    type: "audioinput",
    label: "From Mixer (Virtual)",
    description: "Church Audio",
    title: "presonus",
    shouldStream: true,
    constraints: (deviceId) => {
      return {
        audio: {
          deviceId: {
            exact: deviceId,
          },
          // echoCancellation: false,
          // noiseSuppression: false,
          channelCount: 2,
          // autoGainControl: false,
        },
      };
    },
  },
};
let cameraConfig = {
  1: {
    device: "mv",
    config: {
      anchor: { x: 0, y: 0.5 },
      scale: 4,
      sx: 960,
      sy: 540,
      sWidth: 960,
      sHeight: 540,
    },
  },
  2: {
    device: "mv",
    config: {
      anchor: { x: 0.25, y: 0.5 },
      scale: 4,
      sx: 960,
      sy: 540,
      sWidth: 960,
      sHeight: 540,
    },
  },
  3: {
    device: "mv",
    config: {
      anchor: { x: 0.5, y: 0.5 },
      scale: 4,
      sx: 960,
      sy: 540,
      sWidth: 960,
      sHeight: 540,
    },
  },
  4: {
    device: "mv",
    config: {
      anchor: { x: 0.75, y: 0.5 },
      scale: 4,
      sx: 960,
      sy: 540,
      sWidth: 960,
      sHeight: 540,
    },
  },
  5: {
    device: "mv",
    config: {
      anchor: { x: 0, y: 0.75 },
      scale: 4,
      sx: 960,
      sy: 540,
      sWidth: 960,
      sHeight: 540,
    },
  },
  6: {
    device: "mv",
    config: {
      anchor: { x: 0.25, y: 0.75 },
      scale: 4,
      sx: 960,
      sy: 540,
      sWidth: 960,
      sHeight: 540,
    },
  },
  7: {
    device: "mv",
    config: {
      anchor: { x: 0.5, y: 0.75 },
      scale: 4,
      sx: 960,
      sy: 540,
      sWidth: 960,
      sHeight: 540,
    },
  },
  8: {
    device: "mv",
    config: {
      anchor: { x: 0.75, y: 0.75 },
      scale: 4,
      sx: 960,
      sy: 540,
      sWidth: 960,
      sHeight: 540,
    },
  },
  Preview: {
    device: "mv",
    config: {
      scale: 2,
      anchor: { x: 0, y: 0 },

      sWidth: 960,
      sHeight: 540,

    },
  },
  Live: {
    device: "mv",
    config: {
      scale: 2,
      anchor: { x: 0.5, y: 0 },

      sWidth: 960,
      sHeight: 540,
    },
  },
  Teams: {
    device: "ndiVideo",
    config: {},
  },
};

class Main extends React.Component {
  static contextType = DBContext;
  constructor(props) {
    super(props);
    this.xkeys = new xkeys(this);
    this.initXkeys();
    this.CameraControl = new CameraControl(this);
    console.log(this.props.routeProps);

    this.state = {
      audioPlaying: false,
      location: window.localStorage.getItem("defaultLocation") || "remote",
      authenticated: null,
      touched: isElectron() ? true : false,
      sessionId: window.localStorage.getItem("sessionId") || "",
      // defaultUN: window.localStorage.getItem("defaultUN") || "",
      // defaultDN: window.localStorage.getItem("defaultDN") || "",
      // defaultPW: window.localStorage.getItem("defaultPW") || "",
      session: {},
      openSection: window.localStorage.getItem("defaultSection") || "Stream",
      direct: true,
      selectedCam: 1,
    };
    this.streaming = new Streaming(
      { location: this.state.location },
      deviceConfig,
      cameraConfig
    );
    this.updateStreamingDevices();
    this.HB = setInterval(() => {
      // this.playAudioStream();
      this.updateStreamingDevices();
    }, 30000);
  }
  async componentDidMount() {
    this.SmartControl = new SmartControl(this);

    this.ws = this.context.ws;
    if (isElectron()) document.body.className += " isElectron";
    // if (isElectron() && window.localStorage.getItem("sessionId"))
    await this.authenticateSession();
  }
  async updateStreamingDevices() {
    //List Streaming Devices
    if (this.state.location !== "remote") {
      console.log(await this.streaming.listDevices());
      this.context.globals
        .location()
        .setName(this.state.location).locationObject.streamingDevices =
        await this.streaming.listDevices();
    }
  }
  get isLocal() {
    return (
      this.state.location === "oclMP" || this.state.location === "streamingMP"
    );
  }
  get user() {
    if (this.state.user)
      return this.context.admin.users.find((v) => v.id === this.state.user.id);
    return null;
  }
  userInGroup(groupName) {
    let user = this.user;
    if (!user) return false;
    let groupObj = this.context.admin.groups.find((v) => v.name === groupName);
    if (!groupObj || !groupObj.id) return false;
    return user.groups.includes(groupObj.id);
  }
  async authenticateSession() {
    console.log("auth");
    let authResult = await this.context.ws.requestWithResponse(
      "Users",
      "authenticateSession",
      [this.state.sessionId]
    );
    console.log("auth", authResult);

    if (authResult.auth)
      await this.onAuth(
        authResult.sessionId,
        authResult.session,
        authResult.user
      );
    else this.onUnauth();
  }
  async authenticate({ username, password, device } = {}) {
    console.log("auth");
    let authResult = await this.context.ws.requestWithResponse(
      "Users",
      "authenticate",
      [
        {
          sessionId: this.state.sessionId,
          username,
          password,
          other: { device },
        },
      ]
    );
    if (authResult.auth)
      await this.onAuth(
        authResult.sessionId,
        authResult.session,
        authResult.user
      );
    else {
      this.onUnauth();
      this.setState({ authError: authResult.desc });
    }
    console.log("auth", authResult);
  }
  onAuth(sessionId, session, user) {
    this.setState({ authenticated: true, sessionId, session, user });
    window.localStorage.setItem("sessionId", sessionId);
    if (
      this.streaming.msClient.socket &&
      this.streaming.msClient.socket.isOpened
    ) {
      this.streaming.msClient.socket.request(
        "setName",
        `${user.name.firstname} ${user.name.lastname}: ${session.device}`
      );
    }
    if (this.streaming) {
      this.streaming.deviceName = `${user.name.firstname} ${user.name.lastname}: ${session.device}`;
    }
  }
  onUnauth(res) {
    this.setState({ authenticated: false });
  }
  async logOut() {
    this.setState({ authenticated: false });

    await this.context.ws.requestWithResponse("Users", "logout", [
      this.state.sessionId,
    ]);
  }
  async init() {
    if (!this.state.authenticated) return;
    console.log("LOCAL", this.isLocal);
    //  else {
    //   this.initRemoteRTC();
    // }
  }
  initXkeys() {
    this.onKXeyPressed = (keyIndex) => {
      keyIndex = parseInt(keyIndex, 10);
      switch (keyIndex) {
        case this.xkeys.KEYMAP.MODE_STREAM:
          this.switchSection("Stream");
          break;
        case this.xkeys.KEYMAP.MODE_TEAMS:
          this.switchSection("Zoom");
          break;
        case this.xkeys.KEYMAP.MODE_CONTROL:
          this.switchSection("Settings");
          break;
        case this.xkeys.KEYMAP.VIEW_AUDIO:
          this.toggleAudio();
          break;
        default:
          break;
      }
    };
    this.xkeys.on("pressed", this.onKXeyPressed);
  }
  componentWillUnmount() {
    if (this.xkeys.active) {
      this.xkeys.off("pressed", this.onKXeyPressed);
    }
    clearInterval(this.HB);
  }
  toggleAudio = () => {
    this.setState({
      audioPlaying: this.state.audioPlaying ? false : true,
    });
    // if (this.audioPlayer1.current) {
    //   this.audioPlayer1.current.play();
    //   this.audioPlayer1.current.volume = 1.0;
    // }
    // if (this.audioPlayer2.current) {
    //   this.audioPlayer2.current.play();
    //   this.audioPlayer2.current.volume = 1.0;
    // }
    // if (this.audioPlayer3.current) {
    //   this.audioPlayer3.current.play();
    //   this.audioPlayer3.current.volume = 1.0;
    // }
  };

  switchSection = (openSection) => {
    this.setState({
      openSection,
    });
    window.localStorage.setItem("defaultSection", openSection);
  };
  switchLocation = (name) => {
    this.setState({
      location: name,
    });
    window.localStorage.setItem("defaultLocation", name);
    window.location.reload();
  };

  // async initLocalRTC() {
  //   if (this.msClient.connected) await this.msClient.publish()
  // }
  // async initRemoteRTC() {
  //   this.msClient.isLocal = false
  // }
  setKeypressShortcuts() {
    var keypressRes = function (event) {
      console.log(event);
      switch (event.which) {
        case 121:
          break;
        default:
          return false;
      }
      return true;
    };
    $("body").keydown(function (event) {
      console.log(event);
      var kp = keypressRes(event);
      if (kp) {
        event.preventDefault();
      }
    });
  }
  render() {
    if (this.state.authenticated === null) return <Wait />;
    if (this.state.authenticated === false)
      return (
        <Login
          authenticate={this.authenticate.bind(this)}
          touched={() => this.setState({ touched: true })}
          authError={this.state.authError}
        />
      );
    let role = this.context.admin.roles.find((v) => v.name === this.user.role);
    console.log("Path", role);
    if (role.path && window.location.pathname !== role.path) {
      window.location.pathname = role.path;
      return <Wait />;
    }
    if (
      this.state.touched === false &&
      (role.name === "AV Team" || role.name === "Camera Operator")
    )
      return (
        <Touch
          touched={() => this.setState({ touched: true })}
          session={this.state.session}
          user={this.user}
          logout={this.logOut.bind(this)}
        />
      );
    if (this.xkeys.active) {
      this.xkeys.setBacklight(
        this.xkeys.KEYMAP.MODE_STREAM,
        this.state.openSection === "Stream"
      );

      this.xkeys.setBacklight(
        this.xkeys.KEYMAP.MODE_TEAMS,
        this.state.openSection === "Teams"
      );

      this.xkeys.setBacklight(
        this.xkeys.KEYMAP.MODE_CONTROL,
        this.state.openSection === "Control"
      );
      //AudioMode
      this.xkeys.setBacklight(
        this.xkeys.KEYMAP.VIEW_AUDIO,
        this.state.audioPlaying
      );
    }
    return (
      <div
        className={this.state.direct ? "" : "hideInteractions"}
        style={{
          display: "relative",
          height: "100%",
          width: "100%",
        }}
      >
        <PageTabs
          defaultOpenKey={
            ((this.props.match || {}).params || {}).tabName ||
            this.state.openSection
          }
          tabKiosk={
            ((this.props.match || {}).params || {}).tabName ? true : false
          }
          onChooseSection={this.switchSection}
          right={
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="caser-pageTabs-tabbar-element"
                onClick={this.toggleAudio.bind(this)}
              >
                {this.state.audioPlaying ? (
                  <>
                    <BsVolumeUp size="1.5em" />
                    <StreamMedium
                      streaming={this.streaming}
                      manualConfig={false}
                      view={"presonus"}
                      id={"audioPlayer1"}
                      width={0}
                      height={0}
                    />
                    {/* <StreamMedium
                      streaming={this.streaming}
                      manualConfig={true}
                      view={"ndiAudio"}
                      id={"audioPlayer2"}
                      width={0}
                      height={0}
                    /> */}
                  </>
                ) : (
                  <BsVolumeMute size="1.5em" />
                )}
              </div>
              <div
                className="caser-pageTabs-tabbar-element"
                onClick={() => this.logOut()}
              >
                <Clock
                  format={"h:mm:ss A"}
                  timezone={"US/Eastern"}
                  ticking={true}
                />
              </div>
            </div>
          }
          main={this}
        />
      </div>
    );
  }
}

class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route
            path="/display/:displayName"
            render={(routeProps) => (
              <DB domains={["displays"]}>
                <Globals>
                  <Display {...routeProps} />
                </Globals>
              </DB>
            )}
          />
          <Route
            path="/control"
            render={(routeProps) => (
              <DB domains={["smartControl"]}>
                <Globals>
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      overflow: "scroll",
                    }}
                  >
                    <ControlBasic />
                  </div>
                </Globals>
              </DB>
            )}
          />
          <Route
            path="/tab/:tabName"
            render={(routeProps) => (
              <DB>
                <Globals>
                  <Main {...routeProps} />
                </Globals>
              </DB>
            )}
          />
          <Route
            path="/"
            render={() => (
              <DB>
                <Globals>
                  <Main />
                </Globals>
              </DB>
            )}
          />
        </Switch>
      </Router>
    );
  }
}
export default App;
