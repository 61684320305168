import React from "react";
import DBContext from "./../DBContext";
import Button from "../Tools/mainComponents/Button";
import {
  CaserNumberField,
  CaserRadioField,
  CaserSelectField,
  CaserTextField,
} from "../Tools/mainComponents/Fields";
import ConfirmButton from "../Tools/mainComponents/confirmButton";
import ElementSchema from "./elements/schema";
import CaserJSONField from "../Tools/JSONEditorField";
import CaserSliderField from "../Tools/CaserSliderField";
import { v4 } from "uuid";
import { MdRefresh } from "react-icons/md";
import { ImDisplay } from "react-icons/im";
import { AiFillPlayCircle } from "react-icons/ai";
import { BiFileBlank } from "react-icons/bi";
import { VscPreview } from "react-icons/vsc";
import { RiStackshareLine } from "react-icons/ri";
import { CgViewList } from "react-icons/cg";
import { IconContext } from "react-icons";
import { MdAdd, MdSettings, MdExpandMore, MdExpandLess } from "react-icons/md";
import ReactModal from "react-modal";
import TextBar from "../Tools/mainComponents/textBar";
// import Reorder, { reorder } from "react-reorder";
// import { ReactSortable } from "react-sortablejs";

export default class DisplaySettings extends React.PureComponent {
  static contextType = DBContext;
  onReorder(event, previousIndex, nextIndex, fromId, toId) {
    // reorder(this.context.displays.elements, previousIndex, nextIndex);
  }

  render() {
    let elements = (this.context.displays.elements || [])
      .map((element) => {
        element = this.context.globals.element(element.id);
        if (!element.elementObject) return null;
        return element;
      })
      .filter((e) => e);
    return (
      <div
        style={{
          textAlign: "left",
          padding: "7px",
          color: "",
          height: "100%",
          overflowY: "scroll",
        }}
        id="MainControllerWindow"
      >
        <Title
          right={
            <div
              onClick={(e) => {
                this.context.displays.locations.splice(0, 0, {
                  name: "Untitled Computer",
                  label: "",
                  id: v4(),
                });
              }}
            >
              <MdAdd />
            </div>
          }
        >
          Computers and Display Windows
        </Title>
        {this.context.displays.locations
          .sort((b, a) => {
            if (a.label > b.label) return -1;
            if (a.label < b.label) return 1;
            return 0;
          })
          .map((l) => (
            <div key={l.id}>
              <LocationConfig location={l} />

              {this.context.displays.displays.filter(
                (dis) => dis.location === l.id
              ).length ? (
                this.context.displays.displays
                  .filter((dis) => dis.location === l.id)
                  .map((v) => <DisplayController key={v.id} displayId={v.id} />)
              ) : (
                <div
                  style={{
                    color: "#000",
                    padding: 5,
                    width: "100%",
                    size: 14,
                    textAlign: "center",
                    background: "#0063B133",
                    borderRadius: 5,
                  }}
                >
                  No Displays
                </div>
              )}
            </div>
          ))}
        <div style={{ height: 20 }} />
        <Title
          right={
            <div
              onClick={(e) => {
                let newElementId = v4();
                this.context.displays.elements.splice(0, 0, {
                  name: "Untitled Element",
                  id: newElementId,
                  element: {},
                  container: {},
                  props: {},
                });
              }}
            >
              <MdAdd />
            </div>
          }
        >
          Elements
        </Title>
        {/* <ReactSortable
          list={this.context.displays.elements}
          setList={(newState) => {
            // if (Array.isArray(newState) && newState.length)
            //   this.context.displays.elements = newState;
            console.log("newState", newState);
          }}
          tag="div"
          animation={150}
        > */}
        {elements.map((element) => (
          <ElementConfig
            controllerID={this.props.controllerID}
            key={element.id}
            element={element.elementObject}
          />
        ))}
        {/* </ReactSortable> */}
      </div>
    );
  }
}

export class DisplayController extends React.Component {
  static contextType = DBContext;
  id = v4();
  state = {
    SettingsOpen: false,
    FullOpen: false,
    showViews: false,
    showElements: true,
    showPaths: false,
  };
  viewID(view) {
    return `${this.id}-${view.id}`;
  }
  elementID(element) {
    return `${this.id}-${element.id}`;
  }
  lineID(view, element) {
    return `${this.viewID(view)}__${this.elementID(element)}`;
  }
  lineOptions(display, view, element) {
    let start = document.getElementById(this.viewID(view));
    let end = document.getElementById(this.elementID(element));
    if (start === null || end === null) return null;
    let options = {
      startHeight: start.offsetTop + start.clientHeight / 2,
      endHeight: end.offsetTop + end.clientHeight / 2,
      size: 2,
    };

    if (element.live) {
      options.color = "red";
      options.zIndex = 5;
      options.strokeWidth = 3;
    } else if (element.preview && !display.displayObject.autoLive) {
      options.color = "green";
      options.zIndex = 3;
      options.strokeWidth = 2;
    } else {
      options.color = "grey";
      options.zIndex = 1;
      options.strokeWidth = 1;
    }
    return options;
  }
  get paths() {
    let paths = [];
    let display = this.context.globals.display(this.props.displayId);
    if (this.props.displayName) display.setName(this.props.displayName);
    for (let view of display.views || []) {
      for (let element of view.elements) {
        let lineOptions = this.lineOptions(display, view, element);
        if (lineOptions !== null) {
          paths.push(lineOptions);
        }
      }
    }
    let greatestHeight = 0;
    for (let path of paths) {
      if (path.startHeight > greatestHeight) greatestHeight = path.startHeight;
      if (path.endHeight > greatestHeight) greatestHeight = path.endHeight;
    }
    return paths.sort((a, b) => a.zIndex - b.zIndex);
  }
  render() {
    let display = this.context.globals.display(this.props.displayId);
    if (this.props.displayName) display.setName(this.props.displayName);
    if (!display.exists) return null;
    let displayObject = display.displayObject;
    let elements = display.elements;
    let views = display.views;
    return (
      <>
        <Box
          id={this.id}
          right={
            <IconContext.Provider
              value={{ size: ".75em", style: { verticalAlign: "middle" } }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Button
                  size={"small"}
                  type={display.displayObject.isOpen ? "primary" : "default"}
                  onClick={(e) => {
                    display.displayObject.isOpen = display.displayObject.isOpen
                      ? false
                      : true;
                    e.stopPropagation();
                  }}
                >
                  <ImDisplay />
                </Button>
                <Button
                  onClick={(e) => {
                    display.displayObject.setRefresh = true;
                    e.stopPropagation();
                  }}
                >
                  <MdRefresh />
                </Button>
                <Button
                  onClick={(e) => {
                    this.setState({
                      SettingsOpen: this.state.SettingsOpen ? false : true,
                    });
                    e.stopPropagation();
                  }}
                >
                  <MdSettings />
                </Button>
                {display.displayObject.autoLive ? null : (
                  <Button
                    onClick={(e) => {
                      display.takeShot();
                      e.stopPropagation();
                    }}
                  >
                    <AiFillPlayCircle />
                  </Button>
                )}
              </div>
            </IconContext.Provider>
          }
          left={
            !this.props.showSettings ? (
              <Button
                onClick={(e) => {
                  this.setState({
                    FullOpen: this.state.FullOpen ? false : true,
                  });
                  e.stopPropagation();
                }}
              >
                {!this.state.FullOpen ? <MdExpandMore /> : <MdExpandLess />}
              </Button>
            ) : null
          }
          bottom={
            this.props.showSettings || this.state.FullOpen ? (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Button
                  type={this.state.showViews ? "primary" : "default"}
                  onClick={() =>
                    this.setState({
                      showViews: this.state.showViews ? false : true,
                    })
                  }
                >
                  <VscPreview />
                </Button>
                <Button
                  type={this.state.showPaths ? "primary" : "default"}
                  disabled={!this.state.showViews || this.state.showElements}
                  onClick={() =>
                    this.setState({
                      showPaths: this.state.showPaths ? false : true,
                    })
                  }
                >
                  <RiStackshareLine />
                </Button>
                <Button
                  type={this.state.showElements ? "primary" : "default"}
                  onClick={() =>
                    this.setState({
                      showElements: this.state.showElements ? false : true,
                    })
                  }
                >
                  <CgViewList />
                </Button>
                <div style={{ flexGrow: 1 }} />
                <Button
                  type={display.displayObject.autoLive ? "primary" : "default"}
                  onClick={() =>
                    (display.displayObject.autoLive = display.displayObject
                      .autoLive
                      ? false
                      : true)
                  }
                >
                  Auto Live
                </Button>
              </div>
            ) : null
          }
        >
          {displayObject.name}
        </Box>
        {this.props.showSettings || this.state.FullOpen ? (
          <div style={{ padding: "10px 0px" }}>
            <div
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "row",
                justifyContent: this.state.showPaths
                  ? "center"
                  : "space-around",
              }}
            >
              {this.state.showViews ? (
                <div
                  style={{
                    minWidth: 150,
                    width: 300,
                    flexGrow: 0,
                    flexShrink: 1,
                    margin: "0px 10px",
                    position: "relative",
                  }}
                >
                  <DisplayViewsController
                    display={display}
                    views={views}
                    elements={elements}
                    controllerID={this.id}
                  />
                </div>
              ) : null}

              {this.state.showPaths ? (
                <div
                  style={{
                    minWidth: 200,
                    width: 200,
                    flexGrow: 0,
                    flexShrink: 0,
                    margin: "0px -10px",
                  }}
                >
                  <svg
                    height={"100%"}
                    width="200"
                    style={{ overflow: "visible" }}
                  >
                    {this.paths.map((v) => (
                      <path
                        d={`M 0 ${v.startHeight} c 100 0 100 ${
                          v.endHeight - v.startHeight
                        } 200 ${v.endHeight - v.startHeight}`}
                        stroke={v.color}
                        strokeWidth={v.strokeWidth}
                        fill="none"
                      />
                    ))}
                  </svg>
                </div>
              ) : null}
              {this.state.showElements ? (
                <>
                  <div
                    style={{
                      minWidth: 150,
                      width: 300,
                      flexGrow: 0,
                      flexShrink: 1,
                      margin: "0px 10px",
                      position: "relative",
                    }}
                  >
                    <DisplayElementsController
                      display={display}
                      views={views}
                      elements={elements}
                      controllerID={this.id}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        ) : null}
        <Modal
          open={this.state.SettingsOpen}
          onClose={() =>
            this.setState({
              SettingsOpen: false,
            })
          }
          title={"Display Settings"}
          parentSelector={() =>
            document.getElementById(this.id).parentElement.parentElement
          }
        >
          <DisplayConfig display={display} />
        </Modal>
      </>
    );
  }
}
export class DisplayViewsController extends React.Component {
  static contextType = DBContext;
  state = { SettingsOpen: false };
  render() {
    let views = this.props.views;
    let groups = [];
    for (let view of views) {
      let type = view.viewObject.exclusive || "inc";
      if (type === "gr") {
        let group = view.viewObject.groupID || "Ungrouped";
        if (!groups.includes(group)) groups.push(group);
      }
    }
    let elements = [];
    let inclusive = views.filter(
      (v) => (v.viewObject.exclusive || "inc") === "inc"
    );
    if (inclusive.length) elements.push("Inclusive Scenes", ...inclusive);
    for (let group of groups) {
      let groupViews = views.filter((v) => {
        if (group === "Ungrouped") return v.viewObject.groupID ? false : true;
        else return v.viewObject.groupID === group;
      });
      if (groupViews.length) elements.push(group, ...groupViews);
    }

    let exclusive = views.filter(
      (v) => (v.viewObject.exclusive || "inc") === "exc"
    );
    if (exclusive.length) elements.push("Exclusive Scenes", ...exclusive);

    return (
      <>
        <Title
          right={
            <div
              onClick={(e) => {
                this.context.displays.views.splice(0, 0, {
                  name: "Untitled Scene",
                  id: v4(),
                  elements: [],
                  defaultDisplay: this.props.display.id,
                });
              }}
            >
              <MdAdd />
            </div>
          }
        >
          Scenes
        </Title>
        {elements.map((v, vIndex) => {
          if (typeof v === "string") return <Heading key={vIndex}>{v}</Heading>;
          else
            return (
              <ViewConfig
                controllerID={this.props.controllerID}
                key={v.id}
                view={v}
                viewIndex={vIndex}
              />
            );
        })}
        {/* {views.filter((v) => (v.viewObject.exclusive || "inc") === "inc")
          .length ? (
          <React.Fragment>
            <Heading>Inclusive Scenes</Heading>
            {views
              .filter((v) => (v.viewObject.exclusive || "inc") === "inc")
              .map((view, viewIndex) => (
                <ViewConfig
                  controllerID={this.props.controllerID}
                  key={view.id}
                  view={view}
                  viewIndex={viewIndex}
                />
              ))}
            <div style={{ height: 10 }} />
          </React.Fragment>
        ) : null}
        {groups.map((group) => (
          <React.Fragment key={group}>
            <Heading>{group}</Heading>
            {views
              .filter((v) => v.viewObject.groupID === group)
              .map((view, viewIndex) => (
                <ViewConfig
                  controllerID={this.props.controllerID}
                  key={view.id}
                  view={view}
                  viewIndex={viewIndex}
                />
              ))}
          </React.Fragment>
        ))}
        {views.filter((v) => (v.viewObject.exclusive || "inc") === "exc")
          .length ? (
          <>
            <Heading>Exclusive Scenes</Heading>
            {views
              .filter((v) => (v.viewObject.exclusive || "inc") === "exc")
              .map((view, viewIndex) => (
                <ViewConfig
                  controllerID={this.props.controllerID}
                  key={view.id}
                  view={view}
                  viewIndex={viewIndex}
                />
              ))}
          </>
        ) : null} */}
      </>
    );
  }
}
export class DisplayElementsController extends React.PureComponent {
  static contextType = DBContext;
  state = { SettingsOpen: false };

  render() {
    return (
      <>
        <Title
          right={
            <div
              onClick={(e) => {
                let newElementId = v4();
                this.context.displays.elements.splice(0, 0, {
                  name: "Untitled Element",
                  id: newElementId,
                  element: {},
                  container: {},
                  props: {},
                });
                this.props.display &&
                  this.props.display.displayObject.elements.push(newElementId);
              }}
            >
              <MdAdd />
            </div>
          }
        >
          Elements
        </Title>
        {this.props.elements.map((element, elementIndex) => {
          if (!element.elementObject) return null;
          return (
            <ElementConfig
              controllerID={this.props.controllerID}
              key={element.id}
              element={element.elementObject}
              display={this.props.display}
              displayId={this.props.display.id}
            />
          );
        })}
      </>
    );
  }
}

export class LocationConfig extends React.Component {
  static contextType = DBContext;
  state = { SettingsOpen: false };
  render() {
    return (
      <>
        <Heading
          right={
            <>
              <div
                onClick={(e) => {
                  this.setState({
                    SettingsOpen: this.state.SettingsOpen ? false : true,
                  });
                }}
              >
                <MdSettings />
              </div>
              <div
                onClick={(e) => {
                  this.context.displays.displays.splice(0, 0, {
                    name: "Untitled Window",
                    label: "",
                    id: v4(),
                    location: this.props.location.id,
                    element: {
                      width: 1920,
                      height: 1080,
                    },
                    window: {},
                    isOpen: false,
                    elements: [],
                    preview: [],
                    live: [],
                  });
                }}
              >
                <MdAdd />
              </div>
              <Modal
                open={this.state.SettingsOpen}
                onClose={() =>
                  this.setState({
                    SettingsOpen: false,
                  })
                }
                title={"Computer Location Settings"}
              >
                <CaserTextField
                  value={this.props.location.name}
                  onChange={(val) => {
                    this.props.location.name = val;
                  }}
                  label="Location Name"
                />
                <CaserTextField
                  value={this.props.location.label}
                  onChange={(val) => {
                    this.props.location.label = val;
                  }}
                  label="Location Label"
                />

                <ConfirmButton
                  onClick={() =>
                    this.context.globals
                      .location(this.props.location.id)
                      .delete()
                  }
                >
                  Delete Location
                </ConfirmButton>
              </Modal>
            </>
          }
        >
          {this.props.location.label || this.props.location.name}
        </Heading>
      </>
    );
  }
}
export class DisplayConfig extends React.Component {
  static contextType = DBContext;
  render() {
    let v = this.props.display;
    return (
      <>
        <CaserTextField
          value={v.displayObject.name}
          onChange={(val) => {
            v.displayObject.name = val;
          }}
          label="Display Name"
        />
        <CaserTextField
          value={v.displayObject.label}
          onChange={(val) => {
            v.displayObject.label = val;
          }}
          label="Display Label"
        />
        <CaserSelectField
          value={v.displayObject.location}
          onChange={(val) => {
            v.displayObject.location = val;
          }}
          opts={(this.context.displays.locations || []).map((l) => {
            let loc = this.context.globals.location(l.id);
            return { optionId: loc.id, label: loc.label };
          })}
          noBounce
          label="Location"
        />
        <CaserSelectField
          value={v.displayObject.screenIndex}
          onChange={(val) => {
            v.displayObject.screenIndex = val;
          }}
          opts={(
            (
              this.context.globals.location(v.displayObject.location)
                .locationObject || {}
            ).screens || [1, 2, 3]
          ).map((l, index) => {
            let label = "Screen " + (index + 1);
            if (l.size)
              label += `: (` + l.size.width + ` x ` + l.size.height + `)`;
            return {
              optionId: index,
              label,
            };
          })}
          noBounce
          label="Screen"
        />
        <CaserSelectField
          value={v.displayObject.elements}
          onChange={(val) => {
            if (val === null) v.displayObject.elements = [];
            else v.displayObject.elements = val.map((el) => el.value);
          }}
          opts={this.context.displays.elements.map((el) => {
            return { optionId: el.id, label: el.name };
          })}
          noBounce
          label="Elements"
          isMulti={true}
          closeMenuOnSelect={false}
        />
        <CaserNumberField
          value={v.displayObject.window.height || 1080}
          onChange={(val) => {
            v.displayObject.window.height = parseInt(val);
            v.displayObject.element.height = parseInt(val);
          }}
          noBounce
          label="Height"
        />
        <CaserNumberField
          value={v.displayObject.window.width || 1920}
          onChange={(val) => {
            v.displayObject.window.width = parseInt(val);
            v.displayObject.element.width = parseInt(val);
          }}
          noBounce
          label="Width"
        />
        <CaserRadioField
          value={v.displayObject.window.fullscreen}
          onChange={(val) => {
            v.displayObject.window.fullscreen = val;
          }}
          opts={[
            { optionId: false, label: "No" },
            { optionId: true, label: "Yes" },
          ]}
          noBounce
          label="Fullscreen"
        />
        <CaserRadioField
          value={v.displayObject.decklinkStream || false}
          onChange={(val) => {
            v.displayObject.decklinkStream = val;
          }}
          opts={[
            { optionId: false, label: "No" },
            { optionId: true, label: "Yes" },
          ]}
          noBounce
          label="Stream To Decklink"
        />
        {v.displayObject.decklinkStream && (
          <>
            <CaserTextField
              value={v.displayObject.decklinkModule}
              onChange={(val) => {
                v.displayObject.decklinkModule = val;
              }}
              label="Decklink Module"
            />
            <CaserRadioField
              value={v.displayObject.offscreenOnly || false}
              onChange={(val) => {
                v.displayObject.offscreenOnly = val;
              }}
              opts={[
                { optionId: false, label: "No" },
                { optionId: true, label: "Yes" },
              ]}
              noBounce
              label="Stream Only"
            />
          </>
        )}
        {/* <CaserJSONField
          value={v.displayObject.window || { fullscreen: true }}
          onChange={(val) => {
            v.displayObject.window = val;
          }}
          label={"Display Window Properties"}
        />

        <CaserJSONField
          value={v.displayObject.element || {}}
          onChange={(val) => {
            v.displayObject.element = val;
          }}
          label={"Display Element Style Properties"}
        /> */}

        <ConfirmButton onClick={() => v.delete()}>Delete Display</ConfirmButton>
      </>
    );
  }
}
export class ElementConfig extends React.Component {
  static contextType = DBContext;
  state = { SettingsOpen: false };
  parseDefault(field, element) {
    if (typeof field.default === "undefined") return undefined;
    if (typeof this[field.default] === "function")
      return this[field.default](field, element);
    return this[field.default];
  }

  parseOpts(field) {
    if (Array.isArray(field.opts)) return field.opts;
    return this[field.opts](field);
  }
  parseOnChangeFunc(field, v) {
    if (field.onChangeFunctionName)
      return this[field.onChangeFunctionName].bind(this);
    return (val) => {
      if (typeof this.props.element.props !== "object")
        this.props.element.props = {};
      this.props.element.props[v] = val;
    };
  }
  computedProperties() {
    let element = this.context.globals.element(this.props.element.id);
    let propObject =
      (ElementSchema[element.computedType] || {}).properties || {};
    return Object.keys(propObject).map((v, i) => {
      if (
        typeof propObject[v].show === "function" &&
        !propObject[v].show(this.props)
      )
        return null;
      else if (propObject[v].type === "string") {
        return (
          <CaserTextField
            key={i}
            value={
              (this.props.element.props || {})[v] ||
              this.parseDefault(propObject[v], this.props.element)
            }
            onChange={this.parseOnChangeFunc(propObject[v], v)}
            noBounce
            label={propObject[v].label || v}
          />
        );
      }
      if (propObject[v].type === "radio") {
        return (
          <CaserRadioField
            key={i}
            value={
              (this.props.element.props || {})[v] ||
              this.parseDefault(propObject[v], this.props.element)
            }
            onChange={this.parseOnChangeFunc(propObject[v], v)}
            opts={this.parseOpts(propObject[v])}
            noBounce
            label={propObject[v].label}
          />
        );
      }
      if (propObject[v].type === "select") {
        return (
          <CaserSelectField
            key={i}
            value={
              (this.props.element.props || {})[v] ||
              this.parseDefault(propObject[v], this.props.element)
            }
            onChange={this.parseOnChangeFunc(propObject[v], v)}
            opts={this.parseOpts(propObject[v])}
            noBounce
            label={propObject[v].label}
          />
        );
      }
      if (propObject[v].type === "object") {
        return (
          <CaserJSONField
            key={i}
            value={
              (this.props.element.props || {})[v] ||
              this.parseDefault(propObject[v], this.props.element)
            }
            onChange={this.parseOnChangeFunc(propObject[v], v)}
            label={propObject[v].label}
          />
        );
      }

      return null;
    });
  }
  updateStreamingDevice(val) {
    this.props.element.props.streamLabel = val;
    this.props.element.props.type = val.split("___")[0];
  }
  getLocationStreamingDevices() {
    if (!(this.props.element.props || {}).location) return [];
    let location = this.context.globals.location(
      this.props.element.props.location
    );
    if (location.name === "remote") return [];
    return (location.locationObject.streamingDevices || []).map((v) => {
      return {
        optionId: `${v.type}___${v.name}`,
        label: `${v.type}: ${v.name}`,
      };
    });
  }
  getLocations() {
    return (this.context.displays.locations || []).map((l) => {
      let loc = this.context.globals.location(l.id);
      return { optionId: loc.id, label: loc.label };
    });
  }
  constraintDefault(field, element) {
    let type = (element.props || {}).type;
    if (type === "audioinput")
      return {
        audio: {
          deviceId: {
            exact: "_deviceId",
          },
          echoCancellation: false,
          noiseSuppression: false,
          channelCount: 1,
          autoGainControl: false,
        },
      };
    if (type === "videoinput")
      return {
        video: {
          deviceId: {
            exact: "_deviceId",
          },
          width: 1920,
          height: 1080,
          frameRate: {
            exact: 30,
          },
        },
      };
    if (type === "desktopinput")
      return {
        video: {
          mandatory: {
            chromeMediaSource: "desktop",
            chromeMediaSourceId: "_deviceId",
          },
          frameRate: { exact: 30 },
        },
        audio: false,
      };
    return {};
  }
  clickElement = (element) => {
    if (this.props.display && this.props.display.displayObject.autoLive)
      element.live = element.live ? false : true;
    else element.preview = element.preview ? false : true;
  };

  render() {
    let element = this.context.globals.element(
      this.props.element.id,
      this.props.displayId
    );
    let Icon = (ElementSchema[element.computedType] || {}).icon || BiFileBlank;
    return (
      <>
        <Box
          id={`${this.props.controllerID}-${this.props.element.id}`}
          onClick={() => this.clickElement(element)}
          color={element.ready ? "#2D7D9A" : "#2D7D9A"}
          right={
            <div
              onClick={(e) => {
                this.setState({
                  SettingsOpen: true,
                });
                e.stopPropagation();
              }}
            >
              <MdSettings />
            </div>
          }
          left={
            element.display && (
              <ObjectStatus
                isLive={element.live}
                isPreview={
                  !element.display.displayObject.autoLive
                    ? element.preview
                    : false
                }
              />
            )
          }
          // bottom={
          //   <div style={{ display: "flex", flexDirection: "row" }}>
          //     <Button
          //       type={element.preview ? "primary" : "default"}
          //       onClick={(e) => {
          //         element.preview
          //           ? (element.preview = false)
          //           : (element.preview = true);
          //       }}
          //     >
          //       Preview
          //     </Button>
          //     <Button
          //       type={element.live ? "primary" : "default"}
          //       onClick={(e) => {
          //         element.live ? (element.live = false) : (element.live = true);
          //       }}
          //     >
          //       Live
          //     </Button>
          //   </div>
          // }
        >
          <Icon />
          <div style={{ width: "5px" }} />
          {element.elementObject.name}
        </Box>
        <Modal
          open={this.state.SettingsOpen}
          onClose={() =>
            this.setState({
              SettingsOpen: false,
            })
          }
          parentSelector={() =>
            document.getElementById(
              this.props.controllerID || "MainControllerWindow"
            ).parentElement.parentElement
          }
          title={"Element Settings"}
        >
          <Title>General Settings</Title>
          <CaserTextField
            value={this.props.element.name}
            onChange={(val) => {
              this.props.element.name = val;
            }}
            label="Element Name"
          />
          <CaserSelectField
            value={this.props.element.type}
            onChange={(val) => {
              this.props.element.type = val;
            }}
            opts={Object.keys(ElementSchema).map((v) => {
              // console.log(v);
              return { optionId: v, label: ElementSchema[v].label || v };
            })}
            noBounce
            label="Element Type"
          />
          {this.props.element.type === "clone" ? (
            <CaserSelectField
              value={(this.props.element.props || {}).elementID}
              onChange={(val) => {
                this.props.element.props = { elementID: val };
              }}
              opts={this.context.displays.elements
                .filter((v) => v.id !== this.props.element.id)
                .map((v) => {
                  return { optionId: v.id, label: v.name };
                })}
              noBounce
              label="Cloned From"
            />
          ) : null}

          <CaserTextField
            value={this.props.element.groupID}
            onChange={(val) => {
              this.props.element.groupID = val;
            }}
            noBounce
            label="Element Group ID"
          />
          {/* <CaserRadioField
            value={this.props.element.persistent}
            onChange={(val) => {
              this.props.element.persistent = val;
            }}
            opts={[
              {
                optionId: false,
                label: "No",
              },
              {
                optionId: true,
                label: "Yes",
              },
            ]}
            noBounce
            label="Persistent"
          /> */}
          <ConfirmButton
            onClick={() =>
              this.context.globals.element(this.props.element.id).delete()
            }
          >
            Delete Element
          </ConfirmButton>
          <Title>Element Properties</Title>
          {this.computedProperties()}
          <Title>Container Style</Title>
          <StyleConfig element={element.elementObject.container} />
          <Title>Element Style</Title>
          <StyleConfig element={this.props.element.element} />
        </Modal>
      </>
    );
  }
}
export class ViewConfig extends React.Component {
  static contextType = DBContext;
  state = { SettingsOpen: false };
  clickView = () => {
    let { view } = this.props;
    console.log("Click");
    if (view.display.displayObject.autoLive)
      view.live = view.live ? false : true;
    else view.preview = view.preview ? false : true;
  };
  render() {
    let { view } = this.props;

    return (
      <>
        <Box
          id={`${this.props.controllerID}-${view.id}`}
          onClick={this.clickView}
          key={view.id}
          right={
            <div
              onClick={(e) => {
                this.setState({
                  SettingsOpen: true,
                });
                e.stopPropagation();
              }}
            >
              <MdSettings />
            </div>
          }
          // bottom={
          //   <div style={{ display: "flex", flexDirection: "row" }}>
          //     <Button
          //       type={view.preview ? "primary" : "default"}
          //       onClick={(e) => {
          //         view.preview ? (view.preview = false) : (view.preview = true);
          //       }}
          //     >
          //       Preview
          //     </Button>
          //     <Button
          //       type={view.live ? "primary" : "default"}
          //       onClick={(e) => {
          //         view.live ? (view.live = false) : (view.live = true);
          //       }}
          //     >
          //       Live
          //     </Button>
          //   </div>
          // }
          color={view.ready ? "#03396c" : "#03396c"}
          left={
            <ObjectStatus
              isLive={view.live}
              isPreview={
                !view.display.displayObject.autoLive ? view.preview : false
              }
            />
          }
        >
          {view.viewObject.name}
        </Box>
        <Modal
          open={this.state.SettingsOpen}
          onClose={() =>
            this.setState({
              SettingsOpen: false,
            })
          }
          title={"Scene Settings"}
          parentSelector={() =>
            document.getElementById(this.props.controllerID).parentElement
              .parentElement
          }
        >
          <CaserTextField
            value={view.viewObject.name}
            onChange={(val) => {
              view.viewObject.name = val;
            }}
            label="Scene Name"
          />
          <CaserRadioField
            value={view.viewObject.exclusive || "inc"}
            onChange={(val) => {
              view.viewObject.exclusive = val;
            }}
            opts={[
              {
                optionId: "inc",
                label: "Inclusive",
              },
              {
                optionId: "exc",
                label: "Exclusive",
              },
              {
                optionId: "gr",
                label: "Group",
              },
            ]}
            noBounce
            label="Persistent"
          />
          {view.viewObject.exclusive === "gr" ? (
            <CaserTextField
              value={view.viewObject.groupID}
              onChange={(val) => {
                view.viewObject.groupID = val;
              }}
              noBounce
              label="Scene Group ID"
            />
          ) : null}

          <CaserSelectField
            value={view.viewObject.elements || []}
            onChange={(val) => {
              if (val === null) view.viewObject.elements = [];
              else view.viewObject.elements = val.map((v) => v.value);
            }}
            opts={this.context.displays.elements.map((v) => {
              return { optionId: v.id, label: v.name };
            })}
            noBounce
            label="Elements"
            isMulti
            closeMenuOnSelect={false}
          />
          <ConfirmButton
            onClick={() =>
              this.context.globals.view(this.props.view.id).delete()
            }
          >
            Delete Scene
          </ConfirmButton>
        </Modal>
      </>
    );
  }
}

export class Heading extends React.Component {
  render() {
    return (
      <div
        className={"displayHeader"}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <div style={{ flexGrow: 1 }}>{this.props.children}</div>
        <div style={{ flexGrow: 0, display: "flex", flexDirection: "row" }}>
          {this.props.right}
        </div>
      </div>
    );
  }
}
export class Title extends React.Component {
  render() {
    return (
      <div
        className={"displayTitle"}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <div style={{ flexGrow: 1 }}>{this.props.children}</div>
        <div style={{ flexGrow: 0, display: "flex", flexDirection: "row" }}>
          {this.props.right}
        </div>
      </div>
    );
  }
}
export class Box extends React.Component {
  render() {
    return (
      <div
        id={this.props.id}
        onClick={this.props.onClick && this.props.onClick}
        className={"displayBox"}
        style={{
          backgroundColor: this.props.color || "",
        }}
      >
        <div
          className={"displayBox-backgroundOverlay"}
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: this.props.color || "",
          }}
        >
          {this.props.top ? (
            <div
              className={"displayBoxBottom"}
              style={{ flexGrow: 0, paddingBottom: "3px" }}
            >
              {this.props.top}
            </div>
          ) : null}
          <div
            className={"displayBoxMain"}
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div style={{ flexGrow: 0 }}>{this.props.left}</div>
            <div
              className={"displayBoxMainCenter"}
              style={{ flexGrow: 1, display: "flex", flexDirection: "row" }}
            >
              {this.props.children || this.props.title}
            </div>
            <div style={{ flexGrow: 0 }}>{this.props.right}</div>
          </div>
          {this.props.bottom ? (
            <div
              className={"displayBoxBottom"}
              style={{ flexGrow: 0, paddingTop: "3px" }}
            >
              {this.props.bottom}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
export class Modal extends React.PureComponent {
  render() {
    return (
      <ReactModal
        isOpen={this.props.open}
        onRequestClose={this.props.onClose}
        parentSelector={this.props.parentSelector || (() => document.body)}
        contentLabel={this.props.title}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            position: "absolute",
            height: "100%",
            zIndex: 99,
          },
          content: {
            padding: "0px",
            zIndex: 100,
          },
        }}
        preventScroll={true}
      >
        <TextBar
          right={<Button onClick={this.props.onClose}>Close</Button>}
          style={{
            top: 0,
            backgroundColor: "#000",
            color: "#fff",
          }}
        >
          <div
            style={{
              overflow: "scroll",
              display: "absolute",
              top: 0,
              bottom: 0,
            }}
          >
            {this.props.title}
          </div>
        </TextBar>
        <div style={{ padding: "10px" }}>{this.props.children}</div>
      </ReactModal>
    );
  }
}

export class StyleConfig extends React.Component {
  state = { showAdvanced: false };
  getShadowOpacity(shadow = "rgba(0, 0, 0, 0) 0px 5px 15px") {
    let opacity1 = shadow.split(",");
    if (!opacity1[3]) return "0";
    let opacity2 = opacity1[3].split(")");
    let opactiy3 = opacity2[0].trim();
    if (isNaN(opactiy3)) return "0";
    return opactiy3;
  }
  render() {
    return (
      <>
        <CaserTextField
          value={(this.props.element || {}).height || 1080}
          onChange={(val) => {
            console.log(this.props.element, this.props);
            if (!this.props.element) this.props.element = {};
            this.props.element.height = isNaN(val) ? val : parseFloat(val);
          }}
          noBounce
          label="Height"
        />
        <CaserTextField
          value={(this.props.element || {}).width || 1920}
          onChange={(val) => {
            console.log(this.props.element);
            if (!this.props.element) this.props.element = {};
            this.props.element.width = isNaN(val) ? val : parseFloat(val);
          }}
          noBounce
          label="Width"
        />
        <CaserTextField
          value={(this.props.element || {}).top || "0"}
          onChange={(val) => {
            if (!this.props.element) this.props.element = {};
            this.props.element.top = isNaN(val) ? val : parseFloat(val);
          }}
          noBounce
          label="Position from Top"
        />
        <CaserTextField
          value={(this.props.element || {}).left || "0"}
          onChange={(val) => {
            if (!this.props.element) this.props.element = {};
            this.props.element.left = isNaN(val) ? val : parseFloat(val);
          }}
          noBounce
          label="Position from Left"
        />
        <CaserTextField
          value={(this.props.element || {}).background || "auto"}
          onChange={(val) => {
            if (!this.props.element) this.props.element = {};

            this.props.element.background = val;
          }}
          noBounce
          label="Background"
        />
        <CaserSliderField
          value={(this.props.element || {}).opacity || 1}
          onChange={(val) => {
            if (!this.props.element) this.props.element = {};
            this.props.element.opacity = isNaN(val) ? val : parseFloat(val);
          }}
          sliderProps={{
            min: 0,
            max: 1,
            marks: true,
            step: 0.001,
            valueLabelDisplay: "auto",
            valueLabelFormat: (val) => Math.round(val * 100) + "%",
          }}
          noBounce
          label="Transparency"
        />
        <CaserSliderField
          value={this.getShadowOpacity((this.props.element || {}).boxShadow)}
          onChange={(val) => {
            if (!this.props.element) this.props.element = {};
            if (parseFloat(val) === 0) this.props.element.boxShadow = "none";
            else
              this.props.element.boxShadow = `rgba(0, 0, 0, ${parseFloat(
                val
              )}) 0px 5px 15px`;
          }}
          noBounce
          sliderProps={{
            min: 0,
            max: 1,
            marks: true,
            step: 0.001,
            valueLabelDisplay: "auto",
            valueLabelFormat: (val) => Math.round(val * 100) + "%",
          }}
          label="Shadow"
        />
        <CaserSliderField
          value={(this.props.element || {}).borderRadius || "0"}
          onChange={(val) => {
            if (!this.props.element) this.props.element = {};
            this.props.element.borderRadius = isNaN(val)
              ? val
              : parseFloat(val);
          }}
          noBounce
          sliderProps={{
            min: 0,
            max: 100,
            marks: true,
            valueLabelDisplay: "auto",
            valueLabelFormat: (val) => val + "px",
          }}
          label="Border Rounding"
        />
        <CaserTextField
          value={(this.props.element || {}).backdropFilter || ""}
          onChange={(val) => {
            if (!this.props.element) this.props.element = {};
            this.props.element.backdropFilter = val;
          }}
          noBounce
          label="Filter Behind"
        />
        <CaserTextField
          value={(this.props.element || {}).filter || ""}
          onChange={(val) => {
            if (!this.props.element) this.props.element = {};
            this.props.element.filter = val;
          }}
          noBounce
          label="Filter In Front"
        />
        <Button
          type={this.state.showAdvanced ? "primary" : "default"}
          onClick={() =>
            this.setState({
              showAdvanced: this.state.showAdvanced ? false : true,
            })
          }
        >
          Advanced
        </Button>
        <CaserJSONField
          value={this.props.element || {}}
          onChange={(val) => {
            this.props.element = val;
          }}
          label={"Advanced Style Properties"}
        />
      </>
    );
  }
}

export class ObjectStatus extends React.PureComponent {
  centerColor() {
    let { isLive, isPreview } = this.props;
    if (!isLive && !isPreview) return "grey";
    if ((isLive && isPreview) || isLive) return "red";
    if (isPreview) return "green";
  }
  borderColor() {
    let { isLive, isPreview } = this.props;
    if (!isLive && !isPreview) return "grey";
    if ((isLive && isPreview) || isPreview) return "green";
    if (isLive) return "red";
  }
  render() {
    return (
      <div
        style={{
          margin: "0px 8px",
          height: "12px",
          width: "12px",
          borderRadius: "50%",
          backgroundColor: this.centerColor(),
          border: "solid 3px green",
          borderColor: this.borderColor(),
        }}
      />
    );
  }
}
