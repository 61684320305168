/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import "./control.css";
import DBContext from "../DBContext";
import SmartControl from "../classes/smartControl";
import { Spin } from "antd";

function Control() {
  let context = useContext(DBContext);
  let smartControl = new SmartControl({ context });
  return (
    <>
      <Title>Church</Title>
      <div className={"control-group"}>
        <PowerButton
          smartControl={smartControl}
          label={"Microphones"}
          devices={["audioPower"]}
          onChange={(val) =>
            smartControl.runAction("audioPower", {
              value: val,
              set: val,
              device: "audioPower",
            })
          }
        />

        <PowerButton
          smartControl={smartControl}
          label={"TVs"}
          onChange={(val) => {
            smartControl.runActions([
              {
                name: "northDeaconTVPower",
                params: {
                  value: val,
                  set: val,
                  device: "northDeaconTV",
                },
              },
              {
                name: "southDeaconTVPower",
                params: {
                  value: val,
                  set: val,
                  device: "southDeaconTV",
                },
              },
              {
                name: "northNaveTVPower",
                params: {
                  value: val,
                  set: val,
                  device: "northNaveTV",
                },
              },
              {
                name: "southNaveTVPower",
                params: {
                  value: val,
                  set: val,
                  device: "southNaveTV",
                },
              },
            ]);
            // smartControl.runAction("southDeaconTVPower", {
            //   value: val,
            // });
            // smartControl.runAction("northNaveTVPower", {
            //   value: val,
            // });
            // smartControl.runAction("southNaveTVPower", {
            //   value: val,
            // });
          }}
          devices={[
            "northDeaconTVPower",
            "southDeaconTVPower",
            "northNaveTVPower",
            "southNaveTVPower",
          ]}
        />
        <PowerButton
          smartControl={smartControl}
          label={"Cameras"}
          onChange={(val) => {
            smartControl.runAction("cameraPower", {
              value: val,
              set: val,
              device: "cameraPower",
            });
            smartControl.runAction("IPCameraPower", {
              value: val,
              set: val,
              device: "IPCameraPower",
            });
          }}
          devices={["cameraPower", "IPCameraPower"]}
        />
      </div>
      <Title>Hall</Title>{" "}
      <div className={"control-group"}>
        <PowerButton
          smartControl={smartControl}
          label={"Microphones"}
          onChange={(val) =>
            smartControl.runAction("hallAudioPower", {
              value: val,
            })
          }
          devices={["hallAudioPower"]}
        />
        <PowerButton
          smartControl={smartControl}
          label={"Cameras"}
          onChange={(val) =>
            smartControl.runAction("hallCameraPower", {
              value: val,
            })
          }
          devices={["hallCameraPower"]}
        />
      </div>
    </>
  );
}
export default Control;

let Title = (props) => {
  return (
    <div className={"control-title"}>
      <div className={"control-title-text"}>{props.children}</div>
    </div>
  );
};

let Device = ({ name, smartControl }) => {
  return (
    <div
      className={"control-devices-device"}
      onClick={() => smartControl.toggleAction(name)}
    >
      <div
        className={
          smartControl.getState(name)
            ? "control-devices-device-state on"
            : "control-devices-device-state off"
        }
      />{" "}
      {smartControl.getLabel(name)}
    </div>
  );
};

let PowerButton = ({ label, onChange, devices, smartControl }) => {
  let value = devices.map((v) => smartControl.getState(v));
  let remoteValue = devices;
  if (Array.isArray(value)) {
    if (new Set(value).size !== 1) remoteValue = null;
    else remoteValue = value[0];
  }

  let [localValue, setLocalValue] = React.useState(null);
  let [showDevices, setShowDevices] = React.useState(false);
  let [isLoading, setIsLoading] = React.useState(null);

  React.useEffect(() => {
    if (remoteValue === false && isLoading === false) {
      setIsLoading(null);
    }
    if (remoteValue === true && isLoading === true) {
      setIsLoading(null);
    }
    if (isLoading === null && remoteValue !== localValue)
      setLocalValue(remoteValue);
  }, [remoteValue]);

  let setClicked = (val) => {
    setIsLoading(val);
    setTimeout(() => {
      setIsLoading(null);
      setLocalValue(null);
    }, 10000);
  };

  let offClasses = ["control-button", "control-button-off"];
  let onClasses = ["control-button", "control-button-on"];

  if (localValue === false) {
    offClasses.push("control-button-active");
  }
  if (localValue === true) {
    onClasses.push("control-button-active");
  }

  return (
    <div className={"control-container"}>
      <div className={"control-label"}>{label}</div>{" "}
      <div className={"control-buttons-container"}>
        <div
          onClick={() => {
            setClicked(false);
            setLocalValue(false);
            onChange(false);
          }}
          className={offClasses.join(" ")}
        >
          <Spin spinning={isLoading === false}>OFF</Spin>
        </div>
        <div
          onClick={() => {
            setClicked(true);
            setLocalValue(true);
            onChange(true);
          }}
          className={onClasses.join(" ")}
        >
          <Spin spinning={isLoading === true}>ON</Spin>
        </div>
      </div>
      {devices && devices.length > 1 ? (
        <>
          {" "}
          <div
            className={"control-devices-toggler"}
            onClick={() => {
              if (showDevices) setShowDevices(false);
              else setShowDevices(true);
            }}
          >
            {!showDevices ? "Show Devices" : "Hide Devices"}
          </div>{" "}
          {showDevices && (
            <div className={"control-devices-container"}>
              {devices.map((v) => (
                <Device smartControl={smartControl} name={v} key={v} />
              ))}
            </div>
          )}
        </>
      ) : (
        <div className={"control-devices-toggler"}></div>
      )}
    </div>
  );
};
