import React from "react";
export default class Image extends React.PureComponent {
  componentDidMount() {
    this.props.element.ready = true;
  }
  render() {
    return (
      // <img
      //   onLoadedData={() => (this.props.element.ready = true)}
      //   src={this.props.element.mediaUrl}
      //   style={{ width: "inherit", height: "inherit", border: "none" }}
      //   alt={""}
      // />
      <div
        style={{
          width: "inherit",
          height: "inherit",
          border: "none",
          backgroundImage: `url(${this.props.element.mediaUrl})`,
          backgroundRepeat: "no-repeat",
          backgroundSize:
            this.props.element.computedElement.props.viewType || "contain",
          backgroundPosition:
            this.props.element.computedElement.props.viewPosition ||
            "center center",
        }}
      ></div>
    );
  }
}
