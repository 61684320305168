import React from "react";
export default class Video extends React.PureComponent {
  videoFile = React.createRef();
  componentDidUpdate(prevProps) {
    console.log(this.props.live, prevProps.live);
    if (this.props.live && !prevProps.live) {
      this.videoFile.current.currentTime = 0;
      this.videoFile.current.play();
    } else if (!this.props.live && prevProps.live) {
      this.videoFile.current.pause();
      this.videoFile.current.currentTime = 0;
    }
    // console.log("play", prevProps);
  }
  render() {
    return (
      <video
        src={this.props.element.mediaUrl}
        ref={this.videoFile}
        muted={false}
        autoPlay={this.props.element.live}
        preload={"auto"}
        onEnded={() => (this.props.element.live = false)}
        onCanPlayThrough={() => (this.props.element.ready = true)}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
        }}
      />
    );
  }
}
