import React from "react";
import DisplaySettings from "../displays/DisplaySettings";

let OCL = class extends React.Component {
  render() {
    return (
      <div
        style={{ width: "100%", height: "100%", backgroundColor: "#ffffff82" }}
      >
        <DisplaySettings />
      </div>
    );
  }
};

export default OCL;
