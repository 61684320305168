import React, { Component } from "react";
import Card from "./../Tools/mainComponents/Card";

import { Container, Button } from "@material-ui/core";
// import MuiAlert from "@material-ui/lab/Alert";
class Touch extends Component {
  state = {
    username: "",
    device: "Device",
    password: "",
    error: null,
  };
  async handleLoginClick() {
    try {
      this.props.touched();
    } catch (e) {
      console.log(e.message);
      this.setState({ error: e.message });
    }
  }
  async handleLogoutClick() {
    this.props.logout();
  }
  render() {
    return (
      <div>
        <Container maxWidth={"xs"} style={{ paddingTop: 150 }}>
          <Card
            header={"Logged in to the AV Controlller"}
            ContentProps={{
              style: { background: "white", textAlign: "center" },
            }}
          >
            <p>
              <b>Account Name:</b> {this.props.user.name.firstname}{" "}
              {this.props.user.name.lastname}
            </p>
            <p>
              <b>Account Username:</b> {this.props.user.username}
            </p>
            <p>
              <b>Device:</b> {this.props.session.device}
            </p>
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => this.handleLoginClick(event)}
            >
              Click to Start
            </Button>{" "}
            <Button
              variant="contained"
              onClick={(event) => this.handleLogoutClick(event)}
            >
              Logout
            </Button>
          </Card>
        </Container>
      </div>
    );
  }
}

export default Touch;
