import React from "react";
import DBContext from "../../DBContext";

export default class text extends React.Component {
  static contextType = DBContext;
  element = React.createRef();
  componentDidMount() {
    this.props.element.ready = true;
  }
  componentDidUpdate() {
    this.element.current.scrollTop = this.element.current.scrollHeight;
  }
  render() {
    return (
      <div
        style={{ width: "inherit", height: "inherit", border: "none" }}
        className={
          (this.props.element.elementObject || {}).props.class ||
          "textElement-translation"
        }
        ref={this.element}
      >
        {this.context.admin.translationText || ""}
      </div>
    );
  }
}
