import React, { Component } from "react";
// import Card from "./../Tools/mainComponents/Card";
import { Container, CircularProgress } from "@material-ui/core";
class Touch extends Component {
  state = {
    username: "",
    device: "Device",
    password: "",
    error: null,
  };
  async handleLoginClick() {
    try {
      this.props.touched();
    } catch (e) {
      console.log(e.message);
      this.setState({ error: e.message });
    }
  }

  render() {
    return (
      <div style={{ textAlign: "center", height: "100%", display: "flex" }}>
        <Container
          maxWidth={"xs"}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <Card
            header={"Please Wait"}
            ContentProps={{
              style: { background: "white", textAlign: "center" },
            }}
          > */}
          <CircularProgress color={"#000"} size={70} thickness={2.5} />
          {/* </Card> */}
        </Container>
      </div>
    );
  }
}

export default Touch;
