import React from "react";
export default class text extends React.PureComponent {
  componentDidMount() {
    this.props.element.ready = true;
  }
  render() {
    return (
      <div
        style={{ width: "inherit", height: "inherit", border: "none" }}
        className={(this.props.element.elementObject||{}).props.class||"textElement-text"}
      >
        {(this.props.element.elementObject||{}).props.text}
      </div>
    );
  }
}
