import React from "react";
import { Icon } from "antd";
import Video from "./../Tools/StreamMedium";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { GlobalHotKeys } from "react-hotkeys";
import Joystick from "../Tools/Joystick";
import ReactResizeDetector from "react-resize-detector";
import { Knob } from "react-rotary-knob";
import * as skins from "react-rotary-knob-skin-pack";
import { throttle } from "throttle-debounce";
import cloneDeep from "lodash.clonedeep";
import { MdTextFields } from "react-icons/md";
import {
  BiVideo,
  BiVideoOff,
  // BiJoystickButton,
  BiVideoRecording,
} from "react-icons/bi";
import { ImYoutube2 } from "react-icons/im";
import DBContext from "./../DBContext";
import { CaserRadioField } from "../Tools/mainComponents/Fields";
import { Menu, MenuItem, MenuHeader, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import PIPButton from "./components/pipButton";
let gameControl = window.gameControl;

let PresetTranslation = [
  56, 64, 72, 57, 65, 73, 58, 66, 74, 59, 67, 75, 60, 68, 76, 61, 69, 77,
];

let CameraControl = class extends React.Component {
  static contextType = DBContext;
  Cameras = {
    1: React.createRef(),
    2: React.createRef(),
    3: React.createRef(),
    4: React.createRef(),
    5: React.createRef(),
  };
  keysPressed = [];

  CameraControls = {
    Zoom: { label: "Zoom", getStates: [] },
    Focus: {
      label: "Focus",
      getStates: ["focusMode", "focusAbsolute", "afSensitivity", "afMode"],
    },
    WB: {
      label: "White Balance",
      getStates: ["wbMode", "redGain", "blueGain"],
    },
    Exposure: {
      label: "Exposure",
      getStates: [
        "aeMode",
        "shutterAbsolute",
        "irisAbsolute",
        "gain",
        "gainLimit",
        "exposureCompensationMode",
        "exposureCompensationAbsolute",
      ],
    },
    Brightness: {
      label: "Brightness",
      getStates: ["wdrMode", "wdrAbsolute", "bright", "gammaMode"],
    },
    Color: {
      label: "Color",
      getStates: [
        "colorEnhanceMode",
        "colorEnhanceAbsolute",
        "colorGain",
        "colorHue",
      ],
    },
    Detail: {
      label: "Detail",
      getStates: ["apertureAbsolute", "noiseReduction"],
    },
    Other: {
      label: "Other",
      getStates: [
        "hrMode",
        "lrReverse",
        "freeze",
        "pictureEffect",
        "pictureFlip",
        "icrMode",
        "id",
        "version",
        "display",
        "mute",
        "lensTemperature",
      ],
    },
  };
  state = {
    // selectedCam:1,
    SelectedSecondary: "Zoom",
    axis0Active: false,
    axis1Active: false,
    hoveredPresetIndex: 0,
    audioPlaying: false,
    autoPreview: true,
    autoLive: false,
    autoControl: false,
    streamActive: false,
    streamid: null,
    viewPresets: true,
    viewPreview: true,
    viewCanvases: true,
    expandedLeft: false,
    liveCam1: null,
    previewCam1: null,
    operatorMode: [],
    category: "cameras",
  };
  constructor(props) {
    super(props);
    this.cam1 = React.createRef();
    this.cam2 = React.createRef();
    this.cam3 = React.createRef();
    this.cam4 = React.createRef();
    this.cam5 = React.createRef();
    this.camP = React.createRef();
    this.camL = React.createRef();
    this.camLCanvas = React.createRef();
    this.axis0Active = false;
    this.axis2Active = false;
    this.modifiers = {
      up: false,
      down: false,
      left: false,
      right: false,
    };
    this.buttonCount = {};
    this.gamepad = null;
  }
  componentDidMount() {
    // this.display = this.context.globals.display().setName("Stream");
    // this.cameras = {
    //   1: this.display.view().setName("Cam 1"),
    //   2: this.display.view().setName("Cam 2"),
    //   3: this.display.view().setName("Cam 3"),
    //   4: this.display.view().setName("Cam 4"),
    //   5: this.display.view().setName("Cam 5"),
    //   OCL: this.display.view().setName("OCL"),
    // };

    for (let x = 0; x < 80; x++) {
      this.props.main.xkeys.setBacklight(x, false, true);
      this.props.main.xkeys.setBacklight(x, false);
    }
    this.master = false;
    this.initXkeys();
    this.initController();
    // this.HB = setInterval(() => {
    //   this.playStream();
    // }, 1000);
    this.setState({ streamActive: false });
    // this.playStream();
    this.axesChecks = null;
  }
  componentWillUnmount() {
    console.log("Teams Unmount");
    if (this.props.main.xkeys.active) {
      this.props.main.xkeys.off("pressed", this.onKeyPressed);
      this.props.main.xkeys.off("ptChanged", this.onPTChanged);
      this.props.main.xkeys.off("zChanged", this.onZChanged);
    }
    clearInterval(this.HB);
  }
  componentDidUpdate(prevProps) {
    // console.log("DID UPDATE", this.context.camera.live, prevProps.main.state.cameraSelection.live, this.context.camera.preview, prevProps.main.state.cameraSelection.preview)
    // this.playStreamInMain();
  }

  initXkeys() {
    this.onPTChanged = (position) => {
      if (!this.props.isOpen) return;
      console.log("ptChanged: ", position);
      let threshhold1 = 0;
      if (position.x !== threshhold1 || position.y !== threshhold1) {
        this.axis1Active = true;
        this.ptzDrive.pan = position.x;
        this.ptzDrive.tilt = 0 - position.y;
        this.setPTZDrive();

        // this.onPTMove(position);
      } else if (this.axis1Active === true) {
        this.ptzDrive.pan = 0;
        this.ptzDrive.tilt = 0;
        this.setPTZDrive();
        this.axis1Active = false;
      }
    };
    this.onZChanged = (position) => {
      if (!this.props.isOpen) return;
      // if (this.zPosition === position) return;
      // this.zPosition = position;
      console.log("zChanged: ", position);
      let threshholdLR = 0;

      if (Math.abs(position) > threshholdLR) {
        console.log(position);
        this.ptzDrive.zoom = position;
        this.setPTZDrive();

        this.axis2Active = true;
      } else if (this.axis2Active === true) {
        this.axis2Active = false;
        this.ptzDrive.zoom = 0;
        this.setPTZDrive();

        console.log("rear Stop");
      }
      //   this.onZSet(position);
    };
    this.onKeyPressed = (key) => {
      key = parseInt(key, 10);
      if (!this.props.isOpen) return;
      console.log(key, this.props.isOpen);
      switch (key) {
        case this.props.main.xkeys.KEYMAP.CONTROL_CAM1:
          this.selectCamera(1);
          break;
        case this.props.main.xkeys.KEYMAP.CONTROL_CAM2:
          this.selectCamera(2);
          break;
        case this.props.main.xkeys.KEYMAP.CONTROL_CAM3:
          this.selectCamera(3);
          break;
        case this.props.main.xkeys.KEYMAP.CONTROL_CAM4:
          this.selectCamera(4);
          break;
        case this.props.main.xkeys.KEYMAP.CONTROL_CAM5:
          this.selectCamera(5);
          break;
        case 6:
          this.selectPreview(1);
          break;
        case 14:
          this.selectPreview(2);
          break;
        case 22:
          this.selectPreview(3);
          break;
        case 30:
          this.selectPreview(4);
          break;
        case 38:
          this.selectPreview(5);
          break;
        case 7:
          this.selectLive(1);
          break;
        case 15:
          this.selectLive(2);
          break;
        case 23:
          this.selectLive(3);
          break;
        case 31:
          this.selectLive(4);
          break;
        case 39:
          this.selectLive(5);
          break;
        case 71:
          this.take();
          break;
        case this.props.main.xkeys.KEYMAP.STREAM:
          //Streaming Button
          this.props.main.SmartControl.toggleAction("churchStream");
          break;
        case this.props.main.xkeys.KEYMAP.RECORD:
          //Recording Button
          this.props.main.SmartControl.toggleAction("churchRecord");
          break;
        case this.props.main.xkeys.KEYMAP.POWER_CAMERA:
          //Camera Power
          !this.context.camera.cameraPowerState
            ? this.systemPowerOn()
            : this.systemPowerOff();
          break;
        case this.props.main.xkeys.KEYMAP.POWER_AUDIO:
          //Audio Power
          !this.context.camera.audioPowerState
            ? this.audioPowerOn()
            : this.audioPowerOff();
          break;

        case this.props.main.xkeys.KEYMAP.OCL_PREVIOUS:
          this.oclPreviousPage();
          break;
        case this.props.main.xkeys.KEYMAP.OCL_NEXT:
          this.oclNextPage();
          break;
        case this.props.main.xkeys.KEYMAP.POWER_TEXT:
          this.props.main.SmartControl.toggleAction("oclDisplay");

          break;

        case 47:
          //AutoPreview Toggle
          this.setState({ autoPreview: !this.state.autoPreview });
          break;
        case 55:
          //AutoControl Toggle
          this.setState({ autoControl: !this.state.autoControl });
          break;
        case 63:
          //AutoLive Toggle
          this.setState({ autoLive: !this.state.autoLive });
          break;
        default:
          break;
      }

      //Presets
      let keyIndex = parseInt(key, 10);
      console.log(keyIndex, PresetTranslation.indexOf(keyIndex), "");

      if (PresetTranslation.includes(keyIndex)) {
        console.log(
          keyIndex,
          PresetTranslation.indexOf(keyIndex),
          "PresetFired"
        );
        this.callPreset(PresetTranslation.indexOf(keyIndex) + 1);
      }
    };
    this.props.main.xkeys.on("ptChanged", this.onPTChanged);
    this.props.main.xkeys.on("zChanged", this.onZChanged);
    this.props.main.xkeys.on("pressed", this.onKeyPressed);
  }

  initController() {
    // console.log(window.gameControl);
    gameControl.on("connect", (gamepad) => {
      this.props.main.setState({ direct: false });
      if (gamepad.buttons === 12) {
        this.axisgamepad = gamepad;

        // gamepad.before(
        //   "button11",
        //   () => (this.buttonCount.button11 = 0)
        // );

        gamepad.after("button0", () => {
          this.selectCamera(1);
        });
        gamepad.after("button2", () => {
          this.selectCamera(2);
        });
        gamepad.after("button4", () => {
          this.selectCamera(3);
        });
        gamepad.after("button6", () => {
          this.selectCamera(4);
        });
        gamepad.after("button8", () => {
          this.selectCamera(5);
        });
        gamepad.after("button1", () => {
          this.selectPreview("Supersource");
        });
        gamepad.after("button10", () => {
          this.setState((state) => {
            if (!state.hoveredPresetIndex || state.hoveredPresetIndex === 18)
              state.hoveredPresetIndex = 1;
            else state.hoveredPresetIndex = state.hoveredPresetIndex + 1;
            return state;
          });
        });

        gamepad.after("button3", () => {
          this.props.main.SmartControl.toggleAction("oclDisplay");
        });

        gamepad.after("button5", () => {
          this.props.main.SmartControl.toggleAction("churchRecord");
        });

        gamepad.after("button7", () => {
          this.props.main.SmartControl.toggleAction("churchStream");
        });

        gamepad.before("button11", () => (this.buttonCount.button11 = 0));

        gamepad.on("button11", () => {
          this.buttonCount.button11++;
          if (this.buttonCount.button11 === 50)
            this.savePreset(this.state.hoveredPresetIndex);
        });
        gamepad.after("button11", () => {
          if (this.buttonCount.button11 < 50)
            this.callPreset(
              this.context.presets[
                "camera" + this.props.main.state.selectedCam + "Presets"
              ][this.state.hoveredPresetIndex].id
            );
        });

        gamepad.after("button9", () => {
          this.take();
        });

        this.axesChecks = setInterval(this.onAxesCheckAxis, 80);
        console.log("new", navigator.getGamepads()[gamepad.id]);
        // gamepad.after("button11", () => {
        //   if (this.buttonCount.button11 < 50) this.systemPowerOn();
        // });
      } else if (gamepad.buttons > 31) {
        // } else if (gamepad.buttons === 28) {
        //   gamepad.before("button0", () => {
        //     if (this.state.axis0Active)
        //       this.callPreset(
        //         this.context.presets[
        //           "camera" + this.props.main.state.selectedCam + "Presets"
        //         ][this.state.hoveredPresetIndex].id
        //       );
        //     else this.selectCamera(5);
        //   });
        //   gamepad.before("button1", () => this.selectCamera(4));
        //   gamepad.before("button2", () => {
        //     if (this.state.axis0Active)
        //       this.replacePreset(
        //         this.context.presets[
        //           "camera" + this.props.main.state.selectedCam + "Presets"
        //         ][this.state.hoveredPresetIndex].id
        //       );
        //     else this.selectCamera(3);
        //   });
        //   gamepad.before("button3", () => {
        //     if (this.state.axis0Active)
        //       this.deletePreset(
        //         this.context.presets[
        //           "camera" + this.props.main.state.selectedCam + "Presets"
        //         ][this.state.hoveredPresetIndex].id
        //       );
        //     else this.selectCamera(4);
        //   });
        //   gamepad.before("button4", () => this.selectCamera(5));
        //   gamepad.before("button5", () => this.selectLive(1));
        //   gamepad.before("button6", () => this.selectLive(2));
        //   gamepad.before("button7", () => this.selectLive(3));
        //   gamepad.before("button8", () => this.selectLive(4));
        //   gamepad.before("button9", () => this.selectLive(5));
        //   // gamepad.on('button6',     () => console.log("left trigger down"));
        //   // gamepad.on('button7',     () => console.log("right trigger down"));
        //   //PowerOffandOn
        //   gamepad.before("button18", () => (this.buttonCount.button11 = 0));
        //   gamepad.on("button18", () => {
        //     this.buttonCount.button11++;
        //     if (this.buttonCount.button11 === 50) this.systemPowerOff();
        //   });
        //   gamepad.after("button18", () => {
        //     if (this.buttonCount.button11 < 50) this.systemPowerOn();
        //   });
        //   //Streaming Button
        //   gamepad.before("button17", () => (this.buttonCount.share = 0));
        //   gamepad.on("button17", () => {
        //     this.buttonCount.share++;
        //     if (this.buttonCount.share === 50)
        // this.props.main.SmartControl.runAction("churchStream",{value:false});
        //   });
        //   gamepad.after("button17", () => {
        //     if (this.buttonCount.share < 50)
        //         this.props.main.SmartControl.runAction("churchStream", {
        //           value: true,
        //         });
        //   });
        //   //Recording Button
        //   gamepad.before("button16", () => (this.buttonCount.options = 0));
        //   gamepad.on("button16", () => {
        //     this.buttonCount.options++;
        //     if (this.buttonCount.options === 50)
        // this.props.main.SmartControl.runAction("churchRecord", {
        //   value: false,
        // });
        //   });
        //   gamepad.after("button16", () => {
        //     if (this.buttonCount.options < 50)
        // this.props.main.SmartControl.runAction("churchRecord", {
        //   value: true,
        // });
        //   });
        //   //Presets
        //   gamepad.before("button10", () => (this.buttonCount.button10 = 0));
        //   gamepad.before("button25", () => {
        //     if (this.state.axis0Active) {
        //       if (
        //         this.state.hoveredPresetIndex >=
        //         this.context.presets[
        //           "camera" + this.props.main.state.selectedCam + "Presets"
        //         ].length
        //       )
        //         this.setState({
        //           hoveredPresetIndex: 0,
        //         });
        //       else
        //         this.setState({
        //           hoveredPresetIndex: this.state.hoveredPresetIndex + 1,
        //         });
        //     } else {
        //       this.setState({
        //         axis0Active: true,
        //         hoveredPresetIndex: 0,
        //       });
        //     }
        //   });
        //   gamepad.before("button26", () => {
        //     if (this.state.axis0Active) {
        //       if (this.state.hoveredPresetIndex === 0)
        //         this.setState({
        //           hoveredPresetIndex:
        //             this.context.presets[
        //               "camera" + this.props.main.state.selectedCam + "Presets"
        //             ].length - 1,
        //         });
        //       else
        //         this.setState({
        //           hoveredPresetIndex: this.state.hoveredPresetIndex - 1,
        //         });
        //     } else {
        //       this.setState({
        //         axis0Active: true,
        //         hoveredPresetIndex: 0,
        //       });
        //     }
        //   });
        //   gamepad.on("button10", () => {
        //     this.buttonCount.button10++;
        //     // if (this.buttonCount.button10 === 50)  this.deletePreset(this.deletePreset(this.state.selectedPreset[this.props.main.state.selectedCam]));
        //   });
        //   gamepad.after("button10", () => {
        //     if (this.buttonCount.button10 < 50) this.createPreset();
        //   });
        //   gamepad.on("button24", () => {
        //     if (this.state.axis0Active) {
        //       this.setState({
        //         axis0Active: false,
        //         hoveredPresetIndex: null,
        //       });
        //     } else {
        //       this.setState({
        //         axis0Active: true,
        //         hoveredPresetIndex: 0,
        //       });
        //     }
        //   });
        //   // gamepad.on('button10',     () => console.log("left thumb button"));
        //   // gamepad.on('button11',     () => console.log("right thumb button"));
        //   //Modifiers
        //   gamepad.before("button21", () => (this.modifiers.up = true));
        //   gamepad.after("button21", () => (this.modifiers.up = false));
        //   gamepad.before("button22", () => (this.modifiers.down = true));
        //   gamepad.after("button22", () => (this.modifiers.down = false));
        //   // gamepad.before("button14", () => (this.modifiers.left = true));
        //   // gamepad.after("button14", () => (this.modifiers.left = false));
        //   // gamepad.before("button15", () => (this.modifiers.right = true));
        //   // gamepad.after("button15", () => (this.modifiers.right = false));
        //   //OCL Toggle Button
        //   gamepad.before("button23", () => (this.buttonCount.oclButton = 0));
        //   gamepad.on("button23", () => {
        //     this.buttonCount.oclButton++;
        //     if (this.buttonCount.oclButton === 50)
        // this.props.main.SmartControl.runAction("oclDisplay", {
        //   value: false,
        // });
        //   });
        //   gamepad.after("button23", () => {
        //     if (this.buttonCount.oclButton < 50)
        // this.props.main.SmartControl.runAction("oclDisplay", {
        //   value: true,
        // });
        //   });
        //   //Take Button
        //   gamepad.after("button27", () => {
        //     console.log("fire   ");
        //     this.take();
        //   });
        //   //Secondary
        //   // gamepad.before(
        //   //     "button4",
        //   //     () => (this.buttonCount.secondary = 0)
        //   // );
        //   // gamepad.on("button4", () => {
        //   //     this.buttonCount.secondary++;
        //   //     if (this.buttonCount.secondary === 50)
        //   //         this.setSecondaryToAuto();
        //   // });
        //   // gamepad.after("button4", () => {
        //   //     if (this.buttonCount.secondary < 50) {
        //   //         if (!this.modifiers.up) this.toggleSecondary();
        //   //         else this.setSecondary(0);
        //   //     }
        //   // });
        //   this.axesChecks = setInterval(this.onAxesCheckJoystick, 80);
      } else {
        this.gamepad = gamepad;

        gamepad.before("button0", () => {
          if (this.state.axis0Active)
            this.callPreset(
              this.context.presets[
                "camera" + this.props.main.state.selectedCam + "Presets"
              ][this.state.hoveredPresetIndex].id
            );
          else this.selectCamera(5);
        });
        gamepad.before("button1", () => this.selectCamera(4));
        gamepad.before("button2", () => {
          if (this.state.axis0Active)
            this.replacePreset(
              this.context.presets[
                "camera" + this.props.main.state.selectedCam + "Presets"
              ][this.state.hoveredPresetIndex].id
            );
          else this.selectCamera(3);
        });

        gamepad.before("button3", () => {
          if (this.state.axis0Active)
            this.deletePreset(
              this.context.presets[
                "camera" + this.props.main.state.selectedCam + "Presets"
              ][this.state.hoveredPresetIndex].id
            );
          else this.selectCamera(2);
        });
        gamepad.before("button5", () => this.selectCamera(1));

        // gamepad.on('button6',     () => console.log("left trigger down"));
        // gamepad.on('button7',     () => console.log("right trigger down"));

        //PowerOffandOn
        gamepad.before("button11", () => (this.buttonCount.button11 = 0));

        gamepad.on("button11", () => {
          this.buttonCount.button11++;
          if (this.buttonCount.button11 === 50) this.systemPowerOff();
        });
        gamepad.after("button11", () => {
          if (this.buttonCount.button11 < 50) this.systemPowerOn();
        });

        //Streaming Button
        gamepad.before("button8", () => (this.buttonCount.share = 0));

        gamepad.on("button8", () => {
          this.buttonCount.share++;
          if (this.buttonCount.share === 50)
            this.props.main.SmartControl.runAction("churchStream", {
              value: false,
            });
        });
        gamepad.after("button8", () => {
          if (this.buttonCount.share < 50)
            this.props.main.SmartControl.runAction("churchStream", {
              value: true,
            });
        });

        //Recording Button
        gamepad.before("button9", () => (this.buttonCount.options = 0));

        gamepad.on("button9", () => {
          this.buttonCount.options++;
          if (this.buttonCount.options === 50)
            this.props.main.SmartControl.runAction("churchRecord", {
              value: false,
            });
        });
        gamepad.after("button9", () => {
          if (this.buttonCount.options < 50)
            this.props.main.SmartControl.runAction("churchRecord", {
              value: true,
            });
        });

        //Create Preset
        gamepad.before("button10", () => (this.buttonCount.button10 = 0));

        gamepad.on("button10", () => {
          this.buttonCount.button10++;
          // if (this.buttonCount.button10 === 50)  this.deletePreset(this.deletePreset(this.state.selectedPreset[this.props.main.state.selectedCam]));
        });
        gamepad.after("button10", () => {
          if (this.buttonCount.button10 < 50) this.createPreset();
        });

        //Power
        gamepad.before("button11", () => (this.buttonCount.button11 = 0));

        gamepad.on("button11", () => {
          this.buttonCount.button11++;
          if (this.buttonCount.button11 === 50) this.systemPowerOff();
        });
        gamepad.after("button11", () => {
          if (this.buttonCount.button11 < 50) this.systemPowerOn();
        });

        // gamepad.on('button9',     () => console.log("options"));

        // gamepad.on('button10',     () => console.log("left thumb button"));
        // gamepad.on('button11',     () => console.log("right thumb button"));

        //Modifiers
        gamepad.before("button12", () => (this.modifiers.up = true));
        gamepad.after("button12", () => (this.modifiers.up = false));

        gamepad.before("button13", () => (this.modifiers.down = true));
        gamepad.after("button13", () => (this.modifiers.down = false));

        gamepad.before("button14", () => (this.modifiers.left = true));
        gamepad.after("button14", () => (this.modifiers.left = false));

        gamepad.before("button15", () => (this.modifiers.right = true));
        gamepad.after("button15", () => (this.modifiers.right = false));

        //OCL Toggle Button
        gamepad.before("button16", () => (this.buttonCount.oclButton = 0));
        gamepad.on("button16", () => {
          this.buttonCount.oclButton++;
          if (this.buttonCount.oclButton === 50)
            this.props.main.SmartControl.runAction("oclDisplay", {
              value: false,
            });
        });
        gamepad.after("button16", () => {
          if (this.buttonCount.oclButton < 50)
            this.props.main.SmartControl.runAction("oclDisplay", {
              value: true,
            });
        });

        //Take Button
        gamepad.after("button17", () => {
          console.log("fire   ");
          this.take();
        });

        //Secondary
        gamepad.before("button4", () => (this.buttonCount.secondary = 0));

        gamepad.on("button4", () => {
          this.buttonCount.secondary++;
          if (this.buttonCount.secondary === 50) this.setSecondaryToAuto();
        });
        gamepad.after("button4", () => {
          if (this.buttonCount.secondary < 50) {
            if (!this.modifiers.up) this.toggleSecondary();
            else this.setSecondary(0);
          }
        });

        gamepad.vibrate(0.5, 1000);
        this.axesChecks = setInterval(this.onAxesCheck, 80);
      }
    });
    gameControl.on("disconnect", (gamepad) => {
      this.props.main.setState({ direct: true });
      this.gamepad = null;
      clearInterval(this.axesChecks);
    });
  }
  setKeyPresses(e) {
    e.preventDefault();
    let key = e.key;
    let action = e.type;
    console.log(key, action);
    if (action === "keyup" && this.keysPressed.includes(key)) {
      this.keysPressed.splice([this.keysPressed.indexOf(key)], 1);
    } else if (action === "keydown" && !this.keysPressed.includes(key)) {
      this.keysPressed.push(key);
    }
  }

  kbAxesCheck = (e) => {
    let pan = 0;
    let tilt = 0;
    // let zoom = 0;

    //Axis 0
    if (
      this.keysPressed.includes("ArrowUp") &&
      !this.keysPressed.includes("ArrowDown")
    )
      tilt = 1;
    else if (
      !this.keysPressed.includes("ArrowUp") &&
      this.keysPressed.includes("ArrowDown")
    )
      tilt = -1;

    //Axis 1
    if (
      this.keysPressed.includes("ArrowLeft") &&
      !this.keysPressed.includes("ArrowRight")
    )
      pan = 1;
    else if (
      !this.keysPressed.includes("ArrowLeft") &&
      this.keysPressed.includes("ArrowRight")
    )
      pan = -1;

    //Axis 2

    // if (this.keysPressed.includes("t") && !this.keysPressed.includes("w"))
    //   zoom = 1;
    // else if (!this.keysPressed.includes("t") && this.keysPressed.includes("w"))
    //   zoom = -1;

    if (pan === 0 && tilt === 0) {
      this.onPTStop();
    } else {
      this.onPTMove({ x: 0.5 * pan, y: 0.5 * tilt });
    }
  };
  onAxesCheckXKeysJoystick = () => {
    let values = gameControl.getGamepads()[0].axeValues;
    // // Axis 0
    // let threshhold0 = 0.004;
    // let axis0X = values[1][1];
    // let axis0Y = values[2][0];
    // if (Math.abs(axis0X) > threshhold0 || Math.abs(axis0Y) > threshhold0) {
    //     console.log(axis0X, axis0Y);
    //     this.setState({ axis0Active: true });
    //     axis0X = parseFloat(axis0X) * 10;
    //     axis0Y = parseFloat(axis0Y) * 10;
    //     let tan = Math.round(Math.atan(axis0Y / axis0X) * 57.29578);
    //     if (axis0X > 0 && axis0Y < 0) tan = tan + 180;
    //     if (axis0X > 0 && axis0Y > 0) tan = tan + 180;
    //     if (axis0X < 0 && axis0Y > 0) tan = 360 + tan;
    //     console.log(tan);

    //     let factor =
    //         360 /
    //         this.context.presets[
    //             "camera" + this.props.main.state.selectedCam + "Presets"
    //         ].length;

    //     this.setState({
    //         axis0Active: true,
    //         hoveredPresetIndex: Math.floor(tan / factor),
    //     });

    //     // this.onZoomMove({y:values[0][1]})
    // } else if (this.state.axis0Active === true) {
    //     // this.onZoomStop();
    //     // this.setState({ axis0Active: false, hoveredPresetIndex: null });
    // }
    //Axis 0
    let threshhold1 = 0.00393;

    console.log(values[1][1], values[2][0]);
    if (
      Math.abs(values[1][1]) > threshhold1 ||
      Math.abs(values[2][0]) > threshhold1
    ) {
      this.axis1Active = true;
      let fixedVals = {
        x: parseFloat(values[1][1]),
        y: parseFloat(values[2][0]),
      };
      // console.log(fixedVals);

      if (fixedVals.x > 0)
        fixedVals.x = (fixedVals.x - threshhold1) * (1 / (1 - threshhold1));
      else fixedVals.x = (fixedVals.x + threshhold1) * (1 / (1 - threshhold1));

      if (fixedVals.y > 0)
        fixedVals.y = (fixedVals.y - threshhold1) * (1 / (1 - threshhold1));
      else fixedVals.y = (fixedVals.y + threshhold1) * (1 / (1 - threshhold1));
      console.log(fixedVals);
      this.onPTMove(fixedVals);
      // this.onControllerMainMove();
    } else if (this.axis1Active === true) {
      this.onPTStop();
      this.axis1Active = false;
    }
    //Axis 1
    // let threshhold1 = 0.0039;

    console.log(values[0][0], values[0][1]);
    if (
      Math.abs(values[0][0]) > threshhold1 ||
      Math.abs(values[0][1]) > threshhold1
    ) {
      this.axis1Active = true;
      let fixedVals = {
        x: parseFloat(values[0][0]),
        y: parseFloat(values[0][1]),
      };
      // console.log(fixedVals);

      if (fixedVals.x > 0)
        fixedVals.x = (fixedVals.x - threshhold1) * (1 / (1 - threshhold1));
      else fixedVals.x = (fixedVals.x + threshhold1) * (1 / (1 - threshhold1));

      if (fixedVals.y > 0)
        fixedVals.y = (fixedVals.y - threshhold1) * (1 / (1 - threshhold1));
      else fixedVals.y = (fixedVals.y + threshhold1) * (1 / (1 - threshhold1));
      console.log(fixedVals);
      this.onPTMove(fixedVals);
      // this.onControllerMainMove();
    } else if (this.axis1Active === true) {
      this.onPTStop();
      this.axis1Active = false;
    }

    //Axis 2
    console.log("zoom" + values[1][0]);

    let threshholdLR = 0.004;
    let rearTriggerAverageValue = values[1][0];

    if (Math.abs(rearTriggerAverageValue) > threshholdLR) {
      console.log(rearTriggerAverageValue);
      this.onZoomMove({ y: rearTriggerAverageValue }, 1, 7);

      this.axis2Active = true;
    } else if (this.axis2Active === true) {
      this.axis2Active = false;
      this.onZoomStop();

      console.log("rear Stop");
    }
  };
  // onAxesCheckJoystick = () => {
  //   let values = gameControl.getGamepads()[0].axeValues;
  //   // // Axis 0
  //   // let threshhold0 = 0.004;
  //   // let axis0X = values[1][1];
  //   // let axis0Y = values[2][0];
  //   // if (Math.abs(axis0X) > threshhold0 || Math.abs(axis0Y) > threshhold0) {
  //   //     console.log(axis0X, axis0Y);
  //   //     this.setState({ axis0Active: true });
  //   //     axis0X = parseFloat(axis0X) * 10;
  //   //     axis0Y = parseFloat(axis0Y) * 10;
  //   //     let tan = Math.round(Math.atan(axis0Y / axis0X) * 57.29578);
  //   //     if (axis0X > 0 && axis0Y < 0) tan = tan + 180;
  //   //     if (axis0X > 0 && axis0Y > 0) tan = tan + 180;
  //   //     if (axis0X < 0 && axis0Y > 0) tan = 360 + tan;
  //   //     console.log(tan);

  //   //     let factor =
  //   //         360 /
  //   //         this.context.presets[
  //   //             "camera" + this.props.main.state.selectedCam + "Presets"
  //   //         ].length;

  //   //     this.setState({
  //   //         axis0Active: true,
  //   //         hoveredPresetIndex: Math.floor(tan / factor),
  //   //     });

  //   //     // this.onZoomMove({y:values[0][1]})
  //   // } else if (this.state.axis0Active === true) {
  //   //     // this.onZoomStop();
  //   //     // this.setState({ axis0Active: false, hoveredPresetIndex: null });
  //   // }
  //   //Axis 0
  //   let threshhold1 = 0.0039;

  //   console.log(values[1][1], values[2][0]);
  //   if (
  //     Math.abs(values[1][1]) > threshhold1 ||
  //     Math.abs(values[2][0]) > threshhold1
  //   ) {
  //     this.axis1Active = true;
  //     let fixedVals = {
  //       x: parseFloat(values[1][1]),
  //       y: parseFloat(values[2][0]),
  //     };
  //     // console.log(fixedVals);

  //     if (fixedVals.x > 0)
  //       fixedVals.x = (fixedVals.x - threshhold1) * (1 / (1 - threshhold1));
  //     else fixedVals.x = (fixedVals.x + threshhold1) * (1 / (1 - threshhold1));

  //     if (fixedVals.y > 0)
  //       fixedVals.y = (fixedVals.y - threshhold1) * (1 / (1 - threshhold1));
  //     else fixedVals.y = (fixedVals.y + threshhold1) * (1 / (1 - threshhold1));
  //     console.log(fixedVals);
  //     this.onPTMove(fixedVals);
  //     // this.onControllerMainMove();
  //   } else if (this.axis1Active === true) {
  //     this.onPTStop();
  //     this.axis1Active = false;
  //   }
  //   //Axis 1
  //   // let threshhold1 = 0.0039;

  //   console.log(values[0][0], values[0][1]);
  //   if (
  //     Math.abs(values[0][0]) > threshhold1 ||
  //     Math.abs(values[0][1]) > threshhold1
  //   ) {
  //     this.axis1Active = true;
  //     let fixedVals = {
  //       x: parseFloat(values[0][0]),
  //       y: parseFloat(values[0][1]),
  //     };
  //     // console.log(fixedVals);

  //     if (fixedVals.x > 0)
  //       fixedVals.x = (fixedVals.x - threshhold1) * (1 / (1 - threshhold1));
  //     else fixedVals.x = (fixedVals.x + threshhold1) * (1 / (1 - threshhold1));

  //     if (fixedVals.y > 0)
  //       fixedVals.y = (fixedVals.y - threshhold1) * (1 / (1 - threshhold1));
  //     else fixedVals.y = (fixedVals.y + threshhold1) * (1 / (1 - threshhold1));
  //     console.log(fixedVals);
  //     this.onPTMove(fixedVals);
  //     // this.onControllerMainMove();
  //   } else if (this.axis1Active === true) {
  //     this.onPTStop();
  //     this.axis1Active = false;
  //   }

  //   //Axis 2
  //   console.log("zoom" + values[1][0]);

  //   let threshholdLR = 0.004;
  //   let rearTriggerAverageValue = values[1][0];

  //   if (Math.abs(rearTriggerAverageValue) > threshholdLR) {
  //     console.log(rearTriggerAverageValue);
  //     this.onZoomMove({ y: rearTriggerAverageValue });

  //     this.axis2Active = true;
  //   } else if (this.axis2Active === true) {
  //     this.axis2Active = false;
  //     this.onZoomStop();

  //     console.log("rear Stop");
  //   }
  // };
  onAxesCheckAxis = () => {
    if (this.axisgamepad === null) return null;
    let values = navigator.getGamepads()[this.axisgamepad.id].axes;
    let threshhold1 = 0.1;

    //Axis 0/1
    if (
      Math.abs(values[0]) > threshhold1 ||
      Math.abs(values[1]) > threshhold1
    ) {
      this.axis1Active = true;
      let fixedVals = {
        x: parseFloat(values[0]),
        y: parseFloat(values[1]),
      };
      // console.log(fixedVals);

      if (fixedVals.x > 0)
        fixedVals.x = (fixedVals.x - threshhold1) * (1 / (1 - threshhold1));
      else fixedVals.x = (fixedVals.x + threshhold1) * (1 / (1 - threshhold1));

      if (fixedVals.y > 0)
        fixedVals.y = (fixedVals.y - threshhold1) * (1 / (1 - threshhold1));
      else fixedVals.y = (fixedVals.y + threshhold1) * (1 / (1 - threshhold1));
      console.log(fixedVals);
      this.ptzDrive.pan = fixedVals.x;
      this.ptzDrive.tilt = fixedVals.y;
      this.setPTZDrive();

      // this.onPTMove(fixedVals);
      // this.onControllerMainMove();
    } else if (this.axis1Active === true) {
      this.ptzDrive.pan = 0;
      this.ptzDrive.tilt = 0;
      this.setPTZDrive();

      // this.onPTStop();
      this.axis1Active = false;
    }

    //Axis 2
    let threshholdLR = 0.05;
    let rearTriggerAverageValue = values[2];

    if (Math.abs(rearTriggerAverageValue) > threshholdLR) {
      console.log(rearTriggerAverageValue);
      this.ptzDrive.zoom = rearTriggerAverageValue;
      this.setPTZDrive();

      // this.onZoomMove({ y: rearTriggerAverageValue });

      this.axis2Active = true;
    } else if (this.axis2Active === true) {
      // this.axis2Active = false;
      // this.onZoomStop();
      this.ptzDrive.zoom = 0;
      this.setPTZDrive();

      console.log("rear Stop");
    }
  };
  onAxesCheck = () => {
    if (this.gamepad === null) return null;
    let values = this.gamepad.axeValues;
    //Axis 0
    let threshhold0 = 0.2;
    let threshhold1 = 0.1;
    if (
      Math.abs(values[0][0]) > threshhold0 ||
      Math.abs(values[0][1]) > threshhold0
    ) {
      this.setState({ axis0Active: true });
      values[0][0] = parseFloat(values[0][0]) * 10;
      values[0][1] = parseFloat(values[0][1]) * 10;
      let tan = Math.round(Math.atan(values[0][1] / values[0][0]) * 57.29578);
      if (values[0][0] > 0 && values[0][1] < 0) tan = tan + 180;
      if (values[0][0] > 0 && values[0][1] > 0) tan = tan + 180;
      if (values[0][0] < 0 && values[0][1] > 0) tan = 360 + tan;
      console.log(tan);

      let factor =
        360 /
        this.context.presets[
          "camera" + this.props.main.state.selectedCam + "Presets"
        ].length;

      this.setState({
        axis0Active: true,
        hoveredPresetIndex: Math.floor(tan / factor),
      });

      // this.onZoomMove({y:values[0][1]})
    } else if (this.state.axis0Active === true) {
      // this.onZoomStop();
      this.setState({ axis0Active: false, hoveredPresetIndex: null });
    }

    //Axis 1
    if (
      Math.abs(values[1][0]) > threshhold1 ||
      Math.abs(values[1][1]) > threshhold1
    ) {
      this.axis1Active = true;
      let fixedVals = {
        x: parseFloat(values[1][0]),
        y: parseFloat(values[1][1]),
      };
      // console.log(fixedVals);

      if (fixedVals.x > 0)
        fixedVals.x = (fixedVals.x - threshhold1) * (1 / (1 - threshhold1));
      else fixedVals.x = (fixedVals.x + threshhold1) * (1 / (1 - threshhold1));

      if (fixedVals.y > 0)
        fixedVals.y = (fixedVals.y - threshhold1) * (1 / (1 - threshhold1));
      else fixedVals.y = (fixedVals.y + threshhold1) * (1 / (1 - threshhold1));
      console.log(fixedVals);
      this.ptzDrive.pan = fixedVals.x;
      this.ptzDrive.tilt = fixedVals.y;
      this.setPTZDrive();

      // this.onPTMove(fixedVals);
      // this.onControllerMainMove();
    } else if (this.axis1Active === true) {
      this.ptzDrive.pan = 0;
      this.ptzDrive.tilt = 0;
      this.setPTZDrive();

      // this.onPTStop();
      this.axis1Active = false;
    }

    //Axis 2
    let threshholdLR = 0.05;
    let rearTriggerAverageValue =
      0 -
      navigator.getGamepads()[0].buttons[6].value +
      navigator.getGamepads()[0].buttons[7].value;

    if (Math.abs(rearTriggerAverageValue) > threshholdLR) {
      console.log(rearTriggerAverageValue);
      this.ptzDrive.zoom = rearTriggerAverageValue;
      this.setPTZDrive();

      // this.onZoomMove({ y: rearTriggerAverageValue });

      this.axis2Active = true;
    } else if (this.axis2Active === true) {
      // this.axis2Active = false;
      // this.onZoomStop();
      this.ptzDrive.zoom = 0;
      this.setPTZDrive();

      console.log("rear Stop");
    }
  };
  onControllerMainMove = () => {
    let values = gameControl.getGamepads()[0].axeValues[1];

    this.onPTMove({ x: values[0], y: values[1] });
    // console.log(gameControl.getGamepads()[0].axeValues[1]);
    // console.log(gameControl.getGamepads()[0]);
  };

  ptzDrive = {
    pan: 0,
    tilt: 0,
    zoom: 0,
  };
  setPTZDrive() {
    // console.log(this.ptzDrive);
    this.props.main.CameraControl.cameras[
      this.state.operatorMode.length === 1
        ? this.state.operatorMode[0]
        : this.props.main.state.selectedCam
    ].setPanTiltZoomDrive(this.ptzDrive);
  }
  selectCamera(camera, stopProp = false) {
    console.log("Select Camera: ", camera);
    if (
      !this.modifiers.left &&
      !this.modifiers.right &&
      this.state.autoPreview &&
      !stopProp &&
      !this.state.operatorMode.length
    )
      this.selectPreview(camera);
    else if (this.modifiers.right) this.selectLive(camera);
    this.props.main.setState({ selectedCam: camera, hoveredPresetIndex: 1 });
  }
  selectPreview(camera, stopProp = false) {
    // if (this.cameras && this.cameras[camera])
    //   this.cameras[camera].preview = true;
    if (this.state.autoControl) this.selectCamera(camera, true);
    if (this.state.autoLive && !stopProp) this.selectLive(camera);
    this.props.main.SmartControl.runAction("selectPreview", { value: camera });
  }
  selectLive(camera) {
    // if (this.cameras && this.cameras[camera.toString()]) {
    //   this.cameras[camera].preview = true;
    // } else
    //   console.log(
    //     this.cameras,
    //     camera,
    //     this.cameras[camera],
    //     this.cameras[camera.toString()]
    //   );
    // if (this.cameras && this.cameras[camera]) this.cameras[camera].live = true;

    if (this.state.autoLive) this.selectPreview(camera, true);
    this.props.main.SmartControl.runAction("selectLive", { value: camera });
  }

  isSelectedSecondary(S, str = true) {
    if (!str) return S === this.state.SelectedSecondary;
    return S === this.state.SelectedSecondary ? "true" : "false";
  }
  selectSecondary = (S) => {
    this.setState({ SelectedSecondary: S });
  };
  toggleSecondary = () => {
    let S = this.state.SelectedSecondary + 1;
    if (S > 7) S = 0;
    this.selectSecondary(S);
  };
  setCamerasToDefault = () => {
    this.props.main.ws.request("CameraControl", "setToDefault", []);
  };
  setCamerasPreClose = () => {
    this.props.main.ws.request("CameraControl", "preClose", []);
  };

  systemPowerOn = () => {
    this.props.main.SmartControl.runAction("cameraPower", { value: true });
  };
  systemPowerOff = () => {
    this.props.main.SmartControl.runAction("cameraPower", { value: false });
  };
  audioPowerOn = () => {
    this.props.main.SmartControl.runAction("audioPower", { value: true });
  };
  audioPowerOff = () => {
    this.props.main.SmartControl.runAction("audioPower", { value: false });
  };
  recordingPowerOn = () => {
    this.props.main.SmartControl.runAction("recordingRecord", { value: true });
  };
  streamingPowerOn = () => {
    this.props.main.SmartControl.runAction("streamingStream", { value: false });
  };

  take = () => {
    // if(this.props.main.state.selectedCam === this.main.state.cameraSelection.preview) this.setState({selectedCam:this.main.state.cameraSelection.live})
    // if (this.display && this.display.exists) this.display.takeShot();
    if (this.state.operatorMode.length) return;
    console.log("Take");
    let cameraSelection = this.context.camera;
    if (cameraSelection.preview === this.props.main.state.selectedCam) {
      this.props.main.setState({ selectedCam: cameraSelection.live });
    }
    // let oldLive = cameraSelection.live;
    // cameraSelection.live = cameraSelection.preview;
    // cameraSelection.preview = oldLive;
    this.props.main.SmartControl.runAction("switcherTake");
  };
  cameraStates(cam = this.props.main.state.selectedCam) {
    return this.context.camera["camera" + cam + "States"] || {};
  }
  cameraControl(cam = this.props.main.state.selectedCam) {
    return this.props.main.CameraControl.cameras[cam];
  }
  getCurrentStates() {
    if (
      this.CameraControls[this.state.SelectedSecondary] &&
      this.CameraControls[this.state.SelectedSecondary].getStates
    )
      for (let state of this.CameraControls[this.state.SelectedSecondary]
        .getStates) {
        this.cameraControl().get(state);
      }
  }
  savePreset = (id) => {
    let pngData =
      this.Cameras[this.props.main.state.selectedCam].current.savePNG();
    this.props.main.ws.request("CameraControl", "method", [
      this.props.main.state.selectedCam,
      "saveCurrentAsPreset",
      [{ previewData: pngData }, id],
    ]);
  };

  deletePreset = (id) => {
    this.props.main.ws.request("CameraControl", "method", [
      this.props.main.state.selectedCam,
      "deletePreset",
      [id],
    ]);
  };
  callPreset = (id) => {
    this.props.main.ws.request("CameraControl", "method", [
      this.props.main.state.selectedCam,
      "callPreset",
      [id],
    ]);
  };
  oclOn = () => {
    // if (this.cameras && this.cameras["OCL"]) this.cameras["OCL"].preview = true;
    // if (this.cameras && this.cameras["OCL"]) this.cameras["OCL"].live = true;
  };
  oclOff = () => {
    // if (this.cameras && this.cameras["OCL"])
    //   this.cameras["OCL"].preview = false;
    // if (this.cameras && this.cameras["OCL"]) this.cameras["OCL"].live = false;
  };
  oclNextPage = () => {
    document
      .getElementById("OCLText")
      .contentWindow.postMessage({ name: "nextPage", content: [] }, "*");
  };
  oclPreviousPage = () => {
    document
      .getElementById("OCLText")
      .contentWindow.postMessage({ name: "previousPage", content: [] }, "*");
  };
  render() {
    if (this.props.main.xkeys && this.props.isOpen) {
      //   this.setAllBacklight(false, true);
      //   console.log("setBacklight");
      this.props.main.xkeys.setBacklight(
        46,
        this.props.main.state.selectedCam === 1
      );
      this.props.main.xkeys.setBacklight(
        54,
        this.props.main.state.selectedCam === 2
      );
      this.props.main.xkeys.setBacklight(
        62,
        this.props.main.state.selectedCam === 3
      );
      this.props.main.xkeys.setBacklight(
        70,
        this.props.main.state.selectedCam === 4
      );
      this.props.main.xkeys.setBacklight(
        78,
        this.props.main.state.selectedCam === 5
      );
      this.props.main.xkeys.setBacklightIntensity(255, 255);
      this.props.main.xkeys.setBacklight(
        7,
        this.context.camera.live === 1,
        true
      );
      this.props.main.xkeys.setBacklight(
        15,
        this.context.camera.live === 2,
        true
      );
      this.props.main.xkeys.setBacklight(
        23,
        this.context.camera.live === 3,
        true
      );
      this.props.main.xkeys.setBacklight(
        31,
        this.context.camera.live === 4,
        true
      );
      this.props.main.xkeys.setBacklight(
        39,
        this.context.camera.live === 5,
        true
      );

      //setPresetBacklight
      // if (this.props.main.state.selectedCam < 6) {
      //   let numberOfPresets =
      //     this.context.presets[
      //       "camera" + this.props.main.state.selectedCam + "Presets"
      //     ].length;
      //   //   console.log(numberOfPresets, PresetTranslation);
      //   for (let presetIndex in PresetTranslation) {
      //     this.props.main.xkeys.setBacklight(
      //       PresetTranslation[presetIndex],
      //       false
      //     );
      //     if (numberOfPresets <= presetIndex) {
      //       this.props.main.xkeys.setBacklight(
      //         PresetTranslation[presetIndex],
      //         false,
      //         true
      //       );
      //     } else {
      //       //   console.log("Preset", PresetTranslation[presetIndex]);

      //       this.props.main.xkeys.setBacklight(
      //         PresetTranslation[presetIndex],
      //         true,
      //         true
      //       );
      //     }
      //   }
      // if (this.context.camera.activePresets)
      // console.log(
      //   this.context.camera.activePresets[
      //     this.props.main.state.selectedCam
      //   ]
      // );
      // }

      //AutoPreview
      this.props.main.xkeys.setBacklight(47, this.state.autoPreview);
      //AutoControl
      this.props.main.xkeys.setBacklight(55, this.state.autoControl);
      //AutoControl
      this.props.main.xkeys.setBacklight(63, this.state.autoLive);

      //OCL Enabled
      this.props.main.xkeys.setBacklight(
        this.props.main.xkeys.KEYMAP.POWER_TEXT,
        this.context.camera.oclActive
      );

      //Camera Power
      this.props.main.xkeys.setBacklight(
        this.props.main.xkeys.KEYMAP.POWER_CAMERA,
        this.context.camera.cameraPowerState
      );
      //Audio Power
      this.props.main.xkeys.setBacklight(
        this.props.main.xkeys.KEYMAP.POWER_AUDIO,
        this.context.camera.audioPowerState
      );

      //Preview Selection
      this.props.main.xkeys.setBacklight(
        6,
        this.context.camera.preview === 1,
        false
      );
      this.props.main.xkeys.setBacklight(
        14,
        this.context.camera.preview === 2,
        false
      );
      this.props.main.xkeys.setBacklight(
        22,
        this.context.camera.preview === 3,
        false
      );
      this.props.main.xkeys.setBacklight(
        30,
        this.context.camera.preview === 4,
        false
      );
      this.props.main.xkeys.setBacklight(
        38,
        this.context.camera.preview === 5,
        false
      );
      //Recording

      this.props.main.xkeys.setBacklight(
        this.props.main.xkeys.KEYMAP.RECORD,
        this.context.camera.recording,
        true
      );
      this.props.main.xkeys.setBacklight(
        this.props.main.xkeys.KEYMAP.STREAM,
        this.context.camera.streaming,
        true
      );
    } else {
    }
    // if (!this.props.main.SmartControl.getState("cameraPower"))
    //   return (
    //     <div
    //       style={{
    //         display: "flex",
    //         width: "100%",
    //         height: "100%",
    //         flexDirection: "column",
    //         alignItems: "center",
    //         justifyContent: "center",
    //       }}
    //     >
    //       <div style={{ flexGrow: "1" }}></div>
    //       <div
    //         className={"bottomButton open"}
    //         title="Camera Power"
    //         onClick={() => {
    //           this.props.main.SmartControl.runAction("IPCameraPower", {
    //             value: this.props.main.SmartControl.getState("cameraPower")
    //               ? false
    //               : true,
    //           });
    //           this.props.main.SmartControl.toggleAction("cameraPower");
    //         }}
    //       >
    //         Turn on the Cameras
    //       </div>
    //       <div style={{ flexGrow: "1" }}></div>
    //     </div>
    //   );
    let displayStreamOverlay = this.context.globals
      .display()
      .setName("StreamOverlay");

    return (
      <GlobalHotKeys keyMap={this.keyMap} handlers={this.handlers}>
        <div
          className={[
            this.state.viewCanvases ? "" : "hideCanvases",
            this.state.viewPreview && !this.state.operatorMode.length
              ? ""
              : "hidePreview",
            this.state.viewPresets ? "" : "hidePresets",
            this.props.main.state.direct ? "" : "hideInteractions",
            this.state.expandedLeft ? "expandedLeft" : "",
            this.state.operatorMode.length ? "operatorMode" : "",
          ].join(" ")}
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <div id={"statusBar"}>
            <div
              className={"caser-pageTabs-tabbar-container-inner"}
              style={{
                flexGrow: 0,
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Menu
                portal
                theming="dark"
                overflow="auto"
                arrow
                keepMounted
                menuButton={
                  <div className={"bottomButton"} title="Menu">
                    Menu
                  </div>
                }
              >
                <MenuHeader>Overlay Views</MenuHeader>
                {displayStreamOverlay.views.map((view) => (
                  <MenuItem
                    type="checkbox"
                    checked={view.live}
                    onClick={(config) => {
                      config.keepOpen = true;
                      view.live = view.live ? false : true;
                    }}
                  >
                    {view.viewObject.name}
                  </MenuItem>
                ))}
                <MenuHeader>Video Preview Feed</MenuHeader>
                <MenuItem
                  type="checkbox"
                  disabled={
                    !(
                      this.props.main.streaming.devices.mv &&
                      this.props.main.streaming.devices.mv.isRemote
                    )
                  }
                  checked={
                    this.props.main.streaming.devices.mv.deviceShouldStream
                  }
                  onClick={(config) => {
                    if (
                      this.props.main.streaming.devices.mv.deviceShouldStream
                    ) {
                      this.props.main.streaming.devices.mv.deviceShouldStream = false;
                    } else {
                      this.props.main.streaming.devices.mv.deviceShouldStream = true;
                    }
                  }}
                >
                  Live Video Enabled
                </MenuItem>
                <MenuItem
                  onClick={(config) => {
                    this.props.main.streaming.reInit();
                  }}
                >
                  Refresh Video Preview
                </MenuItem>
                <MenuHeader>Control</MenuHeader>
                <MenuItem
                  type="checkbox"
                  checked={this.state.autoPreview}
                  onClick={(config) => {
                    this.setState({
                      autoPreview: this.state.autoPreview ? false : true,
                    });
                  }}
                >
                  Auto Preview
                </MenuItem>
                <MenuItem
                  type="checkbox"
                  checked={this.state.autoLive}
                  onClick={(config) => {
                    this.setState({
                      autoLive: this.state.autoLive ? false : true,
                    });
                  }}
                >
                  Auto Live
                </MenuItem>
                <MenuItem
                  type="checkbox"
                  checked={this.state.autoControl}
                  onClick={(config) => {
                    this.setState({
                      autoControl: this.state.autoControl ? false : true,
                    });
                  }}
                >
                  Auto Control
                </MenuItem>
                <MenuHeader>View</MenuHeader>
                <MenuItem
                  type="checkbox"
                  checked={this.state.viewCanvases}
                  onClick={(config) => {
                    this.setState({
                      viewCanvases: this.state.viewCanvases ? false : true,
                    });
                    config.keepOpen = true;
                  }}
                >
                  Camera Chooser
                </MenuItem>
                <MenuItem
                  type="checkbox"
                  checked={this.state.viewPreview}
                  onClick={(config) => {
                    this.setState({
                      viewPreview: this.state.viewPreview ? false : true,
                    });
                    config.keepOpen = true;
                  }}
                >
                  Preview
                </MenuItem>
                <MenuItem
                  type="checkbox"
                  checked={this.props.main.state.direct}
                  onClick={(config) => {
                    this.props.main.setState({
                      direct: this.props.main.state.direct ? false : true,
                    });
                    config.keepOpen = true;
                  }}
                >
                  Camera Controls
                </MenuItem>
                <MenuItem
                  type="checkbox"
                  checked={this.state.expandedLeft}
                  onClick={(config) => {
                    this.setState({
                      expandedLeft: this.state.expandedLeft ? false : true,
                    });
                    config.keepOpen = true;
                  }}
                >
                  Expanded Camera Features
                </MenuItem>
                <MenuItem
                  type="checkbox"
                  checked={this.state.viewPresets}
                  onClick={(config) => {
                    this.setState({
                      viewPresets: this.state.viewPresets ? false : true,
                    });
                    config.keepOpen = true;
                  }}
                >
                  Presets
                </MenuItem>
                <SubMenu label="Operator Mode">
                  {[1, 2, 3, 4, 5].map((v) => (
                    <MenuItem
                      key={v}
                      type="checkbox"
                      checked={this.state.operatorMode.includes(v)}
                      onClick={(config) => {
                        this.setState((state) => {
                          let index = state.operatorMode.indexOf(v);
                          if (index > -1) state.operatorMode.splice(index, 1);
                          else state.operatorMode.push(v);
                          if (
                            state.operatorMode.length &&
                            !state.operatorMode.includes(
                              this.props.main.state.selectedCam
                            )
                          )
                            this.selectCamera(state.operatorMode[0]);
                          return state;
                        });
                        config.keepOpen = true;
                      }}
                    >
                      Camera {v}
                    </MenuItem>
                  ))}
                  {this.state.operatorMode.length && (
                    <MenuItem
                      onClick={(config) => {
                        this.setState({ operatorMode: [] });
                      }}
                    >
                      Exit Operator Mode
                    </MenuItem>
                  )}
                </SubMenu>
              </Menu>
            </div>

            <div style={{ flexGrow: 1, textAlign: "center" }}></div>
            {!this.state.operatorMode.length ? (
              <CaserRadioField
                type={"statusBar"}
                opts={[
                  { optionId: "cameras", label: "PTZ Cameras" },
                  { optionId: "views", label: "Other Views" },
                  // { optionId: "color", label: "Color" },
                ]}
                // label={"Camera Power"}
                value={this.state.category}
                onChange={(category) => this.setState({ category })}
              ></CaserRadioField>
            ) : (
              <div className={"bottomButton"} title="Operator Mode">
                Operator Mode
              </div>
            )}
            <div style={{ flexGrow: 1, textAlign: "center" }}></div>
            {!this.state.operatorMode.length && (
              <div
                className={"caser-pageTabs-tabbar-container-inner"}
                style={{
                  flexGrow: 0,
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  className={
                    "bottomButton" +
                    (this.props.main.SmartControl.getState("cameraPower") &&
                    this.props.main.SmartControl.getState("IPCameraPower")
                      ? " open"
                      : "")
                  }
                  title="Camera Power"
                  onClick={() => {
                    this.props.main.SmartControl.runAction("IPCameraPower", {
                      value: this.props.main.SmartControl.getState(
                        "cameraPower"
                      )
                        ? false
                        : true,
                    });
                    this.props.main.SmartControl.toggleAction("cameraPower");
                  }}
                >
                  {this.props.main.SmartControl.getState("cameraPower") ? (
                    <BiVideo />
                  ) : (
                    <BiVideoOff />
                  )}
                </div>
                <div
                  className={
                    "bottomButton" +
                    (this.props.main.SmartControl.getState("oclDisplay")
                      ? " open"
                      : "")
                  }
                  title="Show/Hide OCL Text"
                  onClick={() => {
                    // if (this.props.main.SmartControl.getState("oclDisplay"))
                    //   this.oclOff();
                    // else this.oclOn();
                    this.props.main.SmartControl.toggleAction("oclDisplay");
                  }}
                >
                  <MdTextFields
                    color={
                      this.props.main.SmartControl.getState("oclDisplay")
                        ? "#fff"
                        : "#ccc"
                    }
                  />
                </div>
                <div
                  className={
                    "bottomButton" +
                    (this.props.main.SmartControl.getState("churchRecord")
                      ? " open red"
                      : "")
                  }
                  onClick={() =>
                    this.props.main.SmartControl.toggleAction("churchRecord")
                  }
                >
                  <BiVideoRecording />
                </div>
                <div
                  className={
                    "bottomButton" +
                    (this.props.main.SmartControl.getState("churchStream")
                      ? " open green"
                      : "")
                  }
                  onClick={() =>
                    this.props.main.SmartControl.toggleAction("churchStream")
                  }
                >
                  <ImYoutube2 />
                </div>
              </div>
            )}
          </div>
          <div
            id={"canvases"}
            onClick={this.props.onClick}
            className={this.state.operatorMode.length === 1 ? "oneCam" : ""}
          >
            {(this.state.category === "cameras" ||
              this.state.category === "color") && (
              <>
                {this.state.operatorMode.length === 1 && (
                  <div style={{ flexGrow: 1 }}></div>
                )}
                {[1, 2, 3, 4, 5].map(
                  (cam) =>
                    (!this.state.operatorMode.length ||
                      this.state.operatorMode.includes(cam)) && (
                      <div
                        onClick={() => {
                          this.selectCamera(cam);
                        }}
                        controls
                        id={"cam" + cam + "Canvas"}
                        // style={canvasStyle}
                        className={
                          this.props.main.state.selectedCam === cam
                            ? "camCanvas camEnabled"
                            : "camCanvas"
                        }
                      >
                        <Video
                          streaming={this.props.main.streaming}
                          view={cam}
                          id={"cam" + cam}
                          ref={this.Cameras[cam]}
                        />
                        {!this.state.operatorMode.length && (
                          <>
                            <PIPButton
                              input={cam}
                              location="left"
                              SmartControl={this.props.main.SmartControl}
                            />
                            <PIPButton
                              input={cam}
                              location="right"
                              SmartControl={this.props.main.SmartControl}
                            />
                          </>
                        )}
                        {this.state.operatorMode.length ? (
                          <div className={"canvasOperatorLabel"}>
                            Camera {cam}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )
                )}
                {this.state.operatorMode.length === 1 && (
                  <div style={{ flexGrow: 1 }}></div>
                )}
              </>
            )}
            {this.state.category === "views" && (
              <>
                <div
                  onClick={() => {
                    this.selectPreview("Supersource");
                  }}
                  controls
                  id={"cam6Canvas"}
                  // style={canvasStyle}
                  className={
                    this.props.main.state.selectedCam === 6
                      ? "camCanvas camEnabled"
                      : "camCanvas"
                  }
                >
                  <Video
                    streaming={this.props.main.streaming}
                    view={6}
                    id="cam6"
                  />
                  <PIPButton
                    input={6}
                    location="left"
                    SmartControl={this.props.main.SmartControl}
                  />
                  <PIPButton
                    input={6}
                    location="right"
                    SmartControl={this.props.main.SmartControl}
                  />
                  {/* <canvas ref={this.cam1} id="cam1" /> */}
                </div>
                <div
                  onClick={() => {
                    this.selectPreview(7);
                  }}
                  id={"cam7Canvas"}
                  // style={canvasStyle}
                  className={
                    this.props.main.state.selectedCam === 7
                      ? "camCanvas camEnabled"
                      : "camCanvas"
                  }
                >
                  <Video
                    streaming={this.props.main.streaming}
                    view={7}
                    id="cam7"
                  />
                  <PIPButton
                    input={7}
                    location="left"
                    SmartControl={this.props.main.SmartControl}
                  />
                  <PIPButton
                    input={7}
                    location="right"
                    SmartControl={this.props.main.SmartControl}
                  />
                </div>
                <div
                  onClick={() => {
                    this.selectPreview(8);
                  }}
                  id={"cam8Canvas"}
                  // style={canvasStyle}
                  className={
                    this.props.main.state.selectedCam === 8
                      ? "camCanvas camEnabled"
                      : "camCanvas"
                  }
                >
                  <Video
                    streaming={this.props.main.streaming}
                    view={8}
                    id="cam8"
                  />
                  <PIPButton
                    input={8}
                    location="left"
                    SmartControl={this.props.main.SmartControl}
                  />
                  <PIPButton
                    input={8}
                    location="right"
                    SmartControl={this.props.main.SmartControl}
                  />
                </div>
              </>
            )}
          </div>
          <div id={"ControlandLive"} className={"Stream"}>
            {this.state.category === "cameras" && (
              <div id={"leftControl"}>
                <div id={"leftControlList"}>
                  {Object.entries(this.CameraControls).map(([i, v]) => (
                    <div
                      key={i}
                      className={
                        "item" +
                        (this.state.SelectedSecondary === i ? " selected" : "")
                      }
                      onClick={() => {
                        this.setState({ SelectedSecondary: i });
                        // this.getCurrentStates();
                      }}
                    >
                      {v.label}
                    </div>
                  ))}
                </div>
                <div id={"leftControlDetail"}>
                  <LeftControl
                    comp={this}
                    element={"Zoom"}
                    showJoystick
                    jostickProps={{
                      invert: true,
                      onStop: () => {
                        this.ptzDrive.zoom = 0;
                        this.setPTZDrive();
                      },
                      onMove: (data) => {
                        this.ptzDrive.zoom = data.y;
                        this.setPTZDrive();
                      },
                    }}
                  />
                  <LeftControl
                    comp={this}
                    element={"Focus"}
                    showJoystick={this.cameraStates().focusMode === "manual"}
                    jostickProps={{
                      invert: true,
                      onStop: () => {
                        this.cameraControl().setFocusDrive({ focus: 0 });
                      },
                      onMove: (data) => {
                        this.cameraControl().setFocusDrive({ focus: data.y });
                      },
                    }}
                  >
                    {/* <Button
                    size={"small"}
                    ghost="true"
                    onClick={() =>
                      this.cameraControl().toggleFocusMode()
                    }
                  >
                    Auto/Manual
                  </Button> */}
                    <KnobWithLabel
                      label={"Manual Focus"}
                      skin={8}
                      value={this.cameraStates().focusAbsolute}
                      disabled={this.cameraStates().focusMode === "auto"}
                      KnobProps={{ min: 4096, max: 61440, rotateDegrees: 180 }}
                      onChange={(val) =>
                        this.cameraControl().set("focusDrive", Math.round(val))
                      }
                      after={
                        <VerticalRadio
                          noContainer
                          opts={[
                            { optionId: "auto", label: "Auto" },
                            { optionId: "manual", label: "Manual" },
                          ]}
                          value={this.cameraStates().focusMode}
                          onChange={(val) =>
                            this.props.main.CameraControl.cameras[
                              this.props.main.state.selectedCam
                            ].set("focusMode", val)
                          }
                        />
                      }
                    />
                    <VerticalRadio
                      label={"Autofocus Sensitivity"}
                      disabled={this.cameraStates().focusMode === "manual"}
                      opts={[
                        { optionId: "normal", label: "Normal" },
                        { optionId: "low", label: "Low" },
                      ]}
                      value={this.cameraStates().afSensitivity}
                      onChange={(val) =>
                        this.cameraControl().set("afSensitivity", val)
                      }
                    />
                    <VerticalRadio
                      label={"Autofocus Mode"}
                      value={this.cameraStates().afMode}
                      disabled={this.cameraStates().focusMode === "manual"}
                      opts={[
                        { optionId: "normal", label: "Normal" },
                        { optionId: "internal", label: "Internal" },
                        { optionId: "zoomTriggered", label: "Zoom-Triggered" },
                      ]}
                      onChange={(val) =>
                        this.cameraControl().set("afMode", val)
                      }
                    />
                  </LeftControl>
                  <LeftControl comp={this} element={"WB"} showJoystick={false}>
                    <VerticalRadio
                      label={"White Balance Mode"}
                      value={this.cameraStates().wbMode}
                      opts={[
                        { optionId: "auto", label: "Auto" },
                        { optionId: "indoor", label: "Indoor" },
                        { optionId: "outdoor", label: "Outdoor" },
                        { optionId: "onePushWB", label: "One-Push WB" },
                        { optionId: "ATW", label: "ATW" },
                        { optionId: "manual", label: "Manual" },
                        {
                          optionId: "onePushTrigger",
                          label: "One-Push Trigger",
                        },
                        { optionId: "outdoorAuto", label: "Outdoor Auto" },
                        {
                          optionId: "sodiumLampAuto",
                          label: "Sodium Lamp Auto",
                        },
                        { optionId: "sodiumLamp", label: "Sodium Lamp" },
                        {
                          optionId: "sodiumLampOutdoorAuto",
                          label: "Sodium Lamp Outdoor Auto",
                        },
                      ]}
                      onChange={(val) =>
                        this.cameraControl().set("wbMode", val)
                      }
                    ></VerticalRadio>
                    <KnobWithLabel
                      label={"Red Gain"}
                      skin={8}
                      disabled={this.cameraStates().wbMode === "auto"}
                      KnobProps={{ min: 0, max: 255, rotateDegrees: 180 }}
                      value={this.cameraStates().redGainAbsolute}
                      onChange={(val) =>
                        this.cameraControl().set(
                          "redGainAbsolute",
                          Math.round(val)
                        )
                      }
                    ></KnobWithLabel>
                    <KnobWithLabel
                      label={"Blue Gain"}
                      skin={8}
                      disabled={this.cameraStates().wbMode === "auto"}
                      KnobProps={{ min: 0, max: 255, rotateDegrees: 180 }}
                      value={this.cameraStates().blueGainAbsolute}
                      onChange={(val) =>
                        this.cameraControl().set(
                          "blueGainAbsolute",
                          Math.round(val)
                        )
                      }
                    ></KnobWithLabel>
                    <VerticalRadio
                      label={"Backlight Comp"}
                      disabled={this.cameraStates().wbMode !== "auto"}
                      opts={[
                        { optionId: "off", label: "Off" },
                        {
                          optionId: "on",
                          label: "On",
                        },
                      ]}
                      value={this.cameraStates().backlight}
                      onChange={(val) =>
                        this.cameraControl().set("backlight", val)
                      }
                    />
                  </LeftControl>
                  <LeftControl
                    comp={this}
                    element={"Exposure"}
                    showJoystick={false}
                  >
                    <VerticalRadio
                      label={"Auto Exposure Mode"}
                      opts={[
                        { optionId: "fullAuto", label: "Full Auto" },
                        { optionId: "manual", label: "Manual" },
                        {
                          optionId: "shutterPriority",
                          label: "Shutter Priority",
                        },
                        { optionId: "irisPriority", label: "Iris Priority" },
                        { optionId: "bright", label: "Bright" },
                      ]}
                      value={this.cameraStates().aeMode}
                      onChange={(val) =>
                        this.cameraControl().set("aeMode", val)
                      }
                    />
                    <KnobWithLabel
                      label={"Shutter"}
                      skin={8}
                      labelFunction={(props, val) =>
                        this.props.main.CameraControl.shutterValues[
                          Math.round(val)
                        ]
                      }
                      disabled={
                        this.cameraStates().aeMode !== "manual" &&
                        this.cameraStates().aeMode !== "shutterPriority"
                      }
                      value={this.cameraStates().shutterAbsolute}
                      KnobProps={{ min: 0, max: 22, rotateDegrees: 180 }}
                      onChange={(val) =>
                        this.cameraControl().set(
                          "shutterAbsolute",
                          Math.round(val)
                        )
                      }
                    ></KnobWithLabel>
                    <KnobWithLabel
                      label={"Iris"}
                      skin={8}
                      disabled={
                        this.cameraStates().aeMode !== "manual" &&
                        this.cameraStates().aeMode !== "irisPriority"
                      }
                      labelFunction={(props, val) =>
                        this.props.main.CameraControl.irisValues[
                          this.props.main.state.selectedCam === 2 ||
                          this.props.main.state.selectedCam === 5
                            ? 30
                            : 12
                        ][Math.round(val)]
                      }
                      KnobProps={{ min: 5, max: 17, rotateDegrees: 180 }}
                      value={this.cameraStates().irisAbsolute}
                      onChange={(val) =>
                        this.cameraControl().set(
                          "irisAbsolute",
                          Math.round(val)
                        )
                      }
                      after={
                        <VerticalRadio
                          noContainer
                          opts={[{ optionId: "reset", label: "Reset" }]}
                          onChange={(val) =>
                            this.props.main.CameraControl.cameras[
                              this.props.main.state.selectedCam
                            ].set("iris", val)
                          }
                        />
                      }
                    ></KnobWithLabel>
                    <KnobWithLabel
                      label={"Gain"}
                      skin={8}
                      labelFunction={(props, val) =>
                        ((Math.round(val) - 1) * 2).toString()
                      }
                      KnobProps={{
                        min: 1,
                        max: 20,
                        rotateDegrees: 180,
                      }}
                      value={this.cameraStates().gainAbsolute}
                      onChange={(val) =>
                        this.cameraControl().set(
                          "gainAbsolute",
                          Math.round(val)
                        )
                      }
                    ></KnobWithLabel>
                    <KnobWithLabel
                      label={"Gain Limit"}
                      skin={8}
                      labelFunction={(props, val) =>
                        ((Math.round(val) - 1) * 2).toString()
                      }
                      value={this.cameraStates().gainLimit}
                      KnobProps={{ min: 4, max: 15, rotateDegrees: 180 }}
                      onChange={(val) =>
                        this.cameraControl().set("gainLimit", Math.round(val))
                      }
                    />
                    <KnobWithLabel
                      label={"Exposure Comp"}
                      skin={8}
                      disabled={this.cameraStates().aeMode !== "fullAuto"}
                      labelFunction={(props, val) =>
                        this.props.main.CameraControl.expCompValues[
                          Math.round(val)
                        ].iris
                      }
                      value={this.cameraStates().exposureCompensationAbsolute}
                      KnobProps={{ min: 0, max: 14, rotateDegrees: 180 }}
                      onChange={(val) =>
                        this.cameraControl().set(
                          "exposureCompensationAbsolute",
                          Math.round(val)
                        )
                      }
                      after={
                        <VerticalRadio
                          noContainer
                          value={this.cameraStates().exposureCompensationMode}
                          opts={[
                            { optionId: "reset", label: "Reset" },
                            { optionId: "off", label: "Off" },
                            {
                              optionId: "on",
                              label: "On",
                            },
                          ]}
                          onChange={(val) =>
                            this.props.main.CameraControl.cameras[
                              this.props.main.state.selectedCam
                            ].set("exposureCompensationMode", val)
                          }
                        />
                      }
                    ></KnobWithLabel>
                  </LeftControl>

                  <LeftControl
                    comp={this}
                    element={"Brightness"}
                    showJoystick={false}
                  >
                    <VerticalRadio
                      label={"WDR Mode"}
                      opts={[
                        { optionId: "off", label: "Off" },
                        {
                          optionId: "on",
                          label: "On",
                        },
                        {
                          optionId: "ve",
                          label: "VE On",
                        },
                      ]}
                      value={this.cameraStates().wdrMode}
                      onChange={(val) =>
                        this.cameraControl().set("wdrMode", val)
                      }
                    />
                    <VerticalRadio
                      label={"Bright"}
                      opts={[
                        { optionId: "reset", label: "Reset" },
                        { optionId: "up", label: "Up" },
                        {
                          optionId: "down",
                          label: "Down",
                        },
                      ]}
                      onChange={(val) =>
                        this.cameraControl().set("bright", val)
                      }
                    />
                    <KnobWithLabel
                      label={"Gamma"}
                      skin={8}
                      disabled={
                        !this.cameraStates().gammaOffset ||
                        this.cameraStates().gammaOffset.polarity < 0
                      }
                      value={
                        this.cameraStates().gammaOffset
                          ? this.cameraStates().gammaOffset.offset
                          : 0
                      }
                      KnobProps={{
                        min: 0,
                        max: 639,
                        rotateDegrees: 180,
                      }}
                      onChange={(val) =>
                        this.cameraControl().set("gammaOffset", {
                          polarity: this.cameraStates().gammaOffset.polarity,
                          offset: Math.round(val),
                        })
                      }
                      after={
                        <VerticalRadio
                          noContainer
                          label={"Polarity"}
                          disabled={
                            !this.cameraStates().gammaOffset ||
                            typeof this.cameraStates().gammaOffset.offset !==
                              "number"
                          }
                          value={
                            this.cameraStates().gammaOffset
                              ? this.cameraStates().gammaOffset.polarity
                              : null
                          }
                          opts={[
                            { optionId: 0, label: "Plus" },
                            { optionId: 1, label: "Minus" },
                          ]}
                          onChange={(val) =>
                            this.cameraControl().set("gammaOffset", {
                              polarity: val,
                              offset: this.cameraStates().gammaOffset.offset,
                            })
                          }
                        />
                      }
                    />
                    <VerticalRadio
                      // noContainer
                      label={"Gamma Mode"}
                      value={this.cameraStates().gammaMode}
                      opts={[
                        { optionId: "standard", label: "Standard" },
                        { optionId: "straight", label: "Straight" },
                      ]}
                      onChange={(val) =>
                        this.cameraControl().set("gammaMode", val)
                      }
                    />
                  </LeftControl>

                  <LeftControl
                    comp={this}
                    element={"Color"}
                    showJoystick={false}
                  >
                    <VerticalRadio
                      label={"Color Enhance Mode"}
                      opts={[
                        { optionId: "off", label: "Off" },
                        {
                          optionId: "on",
                          label: "On",
                        },
                      ]}
                      value={this.cameraStates().colorEnhanceMode}
                      onChange={(val) =>
                        this.cameraControl().set("colorEnhanceMode", val)
                      }
                    />
                    {this.cameraStates().colorEnhanceAbsolute ? (
                      <>
                        <KnobWithLabel
                          label={"Threshhold"}
                          skin={8}
                          KnobProps={{ min: 0, max: 128, rotateDegrees: 180 }}
                          value={
                            this.cameraStates().colorEnhanceAbsolute.threshhold
                          }
                          onChange={(val) => {
                            let cE = this.cameraStates().colorEnhanceAbsolute;
                            return this.cameraControl().set(
                              "colorEnhanceAbsolute",
                              Object.assign(cE, {
                                threshhold: Math.round(val),
                              })
                            );
                          }}
                        />
                        <KnobWithLabel
                          label={"Yellow Low Intensity"}
                          skin={8}
                          KnobProps={{ min: 0, max: 128, rotateDegrees: 180 }}
                          value={this.cameraStates().colorEnhanceAbsolute.yL}
                          onChange={(val) => {
                            let cE = this.cameraStates().colorEnhanceAbsolute;
                            return this.cameraControl().set(
                              "colorEnhanceAbsolute",
                              Object.assign(cE, {
                                yL: Math.round(val),
                              })
                            );
                          }}
                        />

                        <KnobWithLabel
                          label={"CR Low Intensity"}
                          skin={8}
                          KnobProps={{ min: 0, max: 128, rotateDegrees: 180 }}
                          value={this.cameraStates().colorEnhanceAbsolute.crL}
                          onChange={(val) => {
                            let cE = this.cameraStates().colorEnhanceAbsolute;
                            return this.cameraControl().set(
                              "colorEnhanceAbsolute",
                              Object.assign(cE, {
                                crL: Math.round(val),
                              })
                            );
                          }}
                        />
                        <KnobWithLabel
                          label={"CB Low Intensity"}
                          skin={8}
                          KnobProps={{ min: 0, max: 128, rotateDegrees: 180 }}
                          value={this.cameraStates().colorEnhanceAbsolute.cbL}
                          onChange={(val) => {
                            let cE = this.cameraStates().colorEnhanceAbsolute;
                            return this.cameraControl().set(
                              "colorEnhanceAbsolute",
                              Object.assign(cE, {
                                cbL: Math.round(val),
                              })
                            );
                          }}
                        />
                        <KnobWithLabel
                          label={"Yellow High Intensity"}
                          skin={8}
                          KnobProps={{ min: 0, max: 128, rotateDegrees: 180 }}
                          value={this.cameraStates().colorEnhanceAbsolute.yH}
                          onChange={(val) => {
                            let cE = this.cameraStates().colorEnhanceAbsolute;
                            return this.cameraControl().set(
                              "colorEnhanceAbsolute",
                              Object.assign(cE, {
                                yH: Math.round(val),
                              })
                            );
                          }}
                        />
                        <KnobWithLabel
                          label={"CR High Intensity"}
                          skin={8}
                          KnobProps={{ min: 0, max: 128, rotateDegrees: 180 }}
                          value={this.cameraStates().colorEnhanceAbsolute.crH}
                          onChange={(val) => {
                            let cE = this.cameraStates().colorEnhanceAbsolute;
                            return this.cameraControl().set(
                              "colorEnhanceAbsolute",
                              Object.assign(cE, {
                                crH: Math.round(val),
                              })
                            );
                          }}
                        />
                        <KnobWithLabel
                          label={"CB High Intensity"}
                          skin={8}
                          KnobProps={{ min: 0, max: 128, rotateDegrees: 180 }}
                          value={this.cameraStates().colorEnhanceAbsolute.cbH}
                          onChange={(val) => {
                            let cE = this.cameraStates().colorEnhanceAbsolute;
                            return this.cameraControl().set(
                              "colorEnhanceAbsolute",
                              Object.assign(cE, {
                                cbH: Math.round(val),
                              })
                            );
                          }}
                        />
                      </>
                    ) : null}
                    <KnobWithLabel
                      label={"Color Gain"}
                      skin={8}
                      KnobProps={{ min: 0, max: 4, rotateDegrees: 180 }}
                      onChange={(val) =>
                        this.cameraControl().set("colorGain", Math.round(val))
                      }
                    />
                    <KnobWithLabel
                      label={"Color Gain"}
                      skin={8}
                      value={this.cameraStates().colorGain}
                      KnobProps={{ min: 0, max: 4, rotateDegrees: 180 }}
                      onChange={(val) =>
                        this.cameraControl().set("colorGain", Math.round(val))
                      }
                    ></KnobWithLabel>
                    <KnobWithLabel
                      label={"Color Hue"}
                      skin={8}
                      KnobProps={{ min: 0, max: 14, rotateDegrees: 180 }}
                      defaultValue={7}
                      value={this.cameraStates().colorHue}
                      onChange={(val) =>
                        this.cameraControl().set("colorHue", Math.round(val))
                      }
                    ></KnobWithLabel>
                  </LeftControl>

                  <LeftControl
                    comp={this}
                    element={"Detail"}
                    showJoystick={false}
                  >
                    <KnobWithLabel
                      label={"Aperture"}
                      skin={8}
                      value={this.cameraStates().apertureAbsolute}
                      KnobProps={{ min: 0, max: 15, rotateDegrees: 180 }}
                      onChange={(val) =>
                        this.cameraControl().set(
                          "apertureAbsolute",
                          Math.round(val)
                        )
                      }
                      after={
                        <VerticalRadio
                          noContainer
                          opts={[{ optionId: "reset", label: "Reset" }]}
                          onChange={(val) =>
                            this.props.main.CameraControl.cameras[
                              this.props.main.state.selectedCam
                            ].set("apertureMode", val)
                          }
                        />
                      }
                    ></KnobWithLabel>
                    <KnobWithLabel
                      label={"Noice Reduction"}
                      skin={8}
                      value={this.cameraStates().noiseReduction}
                      KnobProps={{ min: 0, max: 5, rotateDegrees: 180 }}
                      onChange={(val) =>
                        this.cameraControl().set(
                          "noiseReduction",
                          Math.round(val)
                        )
                      }
                    ></KnobWithLabel>
                  </LeftControl>

                  <LeftControl
                    comp={this}
                    element={"Other"}
                    showJoystick={false}
                  >
                    <VerticalRadio
                      label={"High Resolution Mode"}
                      value={this.cameraStates().hrMode}
                      opts={[
                        { optionId: "off", label: "Off" },
                        {
                          optionId: "on",
                          label: "On",
                        },
                      ]}
                      onChange={(val) =>
                        this.cameraControl().set("hrMode", val)
                      }
                    />
                    <VerticalRadio
                      label={"Tally (Live Light)"}
                      value={this.cameraStates().tally}
                      opts={[
                        { optionId: "off", label: "Off" },
                        {
                          optionId: "on",
                          label: "On",
                        },
                      ]}
                      onChange={(val) => this.cameraControl().set("tally", val)}
                    />
                    <VerticalRadio
                      label={"LR Reverse"}
                      value={this.cameraStates().lrReverse}
                      opts={[
                        { optionId: "off", label: "Off" },
                        {
                          optionId: "on",
                          label: "On",
                        },
                      ]}
                      onChange={(val) =>
                        this.cameraControl().set("lrReverse", val)
                      }
                    />
                    <VerticalRadio
                      label={"Picture Freeze"}
                      value={this.cameraStates().freeze}
                      opts={[
                        { optionId: "off", label: "Off" },
                        {
                          optionId: "on",
                          label: "On",
                        },
                      ]}
                      onChange={(val) =>
                        this.cameraControl().set("freeze", val)
                      }
                    />
                    <VerticalRadio
                      label={"Picture Effect"}
                      value={this.cameraStates().pictureEffect}
                      opts={[
                        { optionId: "off", label: "Off" },
                        {
                          optionId: "neg",
                          label: "Negative",
                        },
                        {
                          optionId: "bw",
                          label: "Greyscale",
                        },
                      ]}
                      onChange={(val) =>
                        this.cameraControl().set("pictureEffect", val)
                      }
                    />
                    <VerticalRadio
                      label={"Picture Flip"}
                      value={this.cameraStates().pictureFlip}
                      opts={[
                        { optionId: "off", label: "Off" },
                        {
                          optionId: "on",
                          label: "On",
                        },
                      ]}
                      onChange={(val) =>
                        this.cameraControl().set("pictureFlip", val)
                      }
                    />
                    <VerticalRadio
                      label={"IR Cut Removal"}
                      value={this.cameraStates().icrMode}
                      opts={[
                        { optionId: "off", label: "Off" },
                        {
                          optionId: "on",
                          label: "On",
                        },
                      ]}
                      onChange={(val) =>
                        this.cameraControl().set("icrMode", val)
                      }
                    />
                    <VerticalRadio
                      value={this.cameraStates().irCorrection}
                      opts={[
                        { optionId: "standard", label: "Standard" },
                        { optionId: "ir", label: "IR light" },
                      ]}
                      label={"IR Correction"}
                      onChange={(val) =>
                        this.cameraControl().set("irCorrection", val)
                      }
                    ></VerticalRadio>
                    <VerticalRadio
                      label={"Display"}
                      value={this.cameraStates().display}
                      opts={[
                        { optionId: "off", label: "Off" },
                        {
                          optionId: "on",
                          label: "On",
                        },
                        {
                          optionId: "toggle",
                          label: "Toggle",
                        },
                      ]}
                      onChange={(val) =>
                        this.cameraControl().set("display", val)
                      }
                    />
                    <VerticalRadio
                      label={"Mute"}
                      value={this.cameraStates().mute}
                      opts={[
                        { optionId: "off", label: "Off" },
                        {
                          optionId: "on",
                          label: "On",
                        },
                        {
                          optionId: "toggle",
                          label: "Toggle",
                        },
                      ]}
                      onChange={(val) => this.cameraControl().set("mute", val)}
                    />
                  </LeftControl>
                </div>
              </div>
            )}{" "}
            <div
              className={"mainCanvases"}
              style={{
                flexGrow: 10,
                maxHeight: "100%",
                textAlign: "center",
                verticalAlign: "middle",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  flexDirection: "column",
                  flexGrow: 1,
                  flexShrink: 1,
                  width: "100%",
                  position: "relative",
                }}
                id={"previewCanvasContainer"}
              >
                <div style={{ flexGrow: 1 }} />
                <div
                  id={"camPCanvas"}
                  onClick={this.take}
                  ref={this.camPCanvas}
                >
                  {this.props.main.SmartControl.getState(
                    "ChurchSwitcherDevice"
                  ) !== "atem" && (
                    <ReactResizeDetector handleWidth>
                      {({ width }) => {
                        return (
                          <div
                            id={"OCLContainer"}
                            style={{
                              borderBottomRightRadius:
                                (1920 / width) * 5 + "px",
                              borderBottomLeftRadius: (1920 / width) * 5 + "px",
                              opacity: this.context.camera.oclActive ? 1 : 0,
                              transform:
                                "scale(" +
                                (width ? (width / 1920).toFixed(3) : 0) +
                                ")",
                              width: 1920,
                              height: 450,
                            }}
                          >
                            <object
                              style={{
                                width: "1880px",
                                height: "410px",
                                margin: "20px",
                                borderRadius: "40px",
                                backdropFilter: "blur(40px) brightness(.65)",
                                WebkitBackdropFilter:
                                  "blur(40px) brightness(.65)",
                              }}
                              data={
                                "https://av.saintmarydelray.com/ocl/#/HTML/3/Transparent"
                              }
                            >
                              OCL
                            </object>
                          </div>
                        );
                      }}
                    </ReactResizeDetector>
                  )}
                  <div className={"mainCamCanvas"}>
                    <Video
                      streaming={this.props.main.streaming}
                      view={"Preview"}
                      id="camP"
                    />
                  </div>
                </div>
                <div
                  style={{
                    flexGrow: 0,
                    color: "#fff",
                    background: "#076f009f",
                    margin: "5px",
                    borderRadius: "5px",
                  }}
                  className={"mainCanvasLabel"}
                >
                  Preview (Camera {this.context.camera.preview})
                </div>
                <div style={{ flexGrow: 1 }} />
              </div>

              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  flexGrow: 1,
                  flexShrink: 1,
                  width: "100%",
                }}
                id={"liveCanvasContainer"}
              >
                <div style={{ flexGrow: 1 }} />
                <div
                  id={"camLCanvas"}
                  ref={this.camLCanvas}
                  onClick={this.take}
                >
                  {this.props.main.SmartControl.getState(
                    "ChurchSwitcherDevice"
                  ) !== "atem" && (
                    <ReactResizeDetector handleWidth>
                      {({ width }) => {
                        return (
                          <div
                            id={"OCLContainer"}
                            style={{
                              borderBottomRightRadius:
                                (1920 / width) * 5 + "px",
                              borderBottomLeftRadius: (1920 / width) * 5 + "px",
                              opacity: this.context.camera.oclActive ? 1 : 0,
                              transform:
                                "scale(" +
                                (width ? (width / 1920).toFixed(3) : 0) +
                                ")",
                              width: 1920,
                              height: 450,
                            }}
                          >
                            <object
                              style={{
                                width: "1880px",
                                height: "410px",
                                margin: "20px",
                                borderRadius: "40px",
                                backdropFilter: "blur(40px) brightness(.65)",
                                WebkitBackdropFilter:
                                  "blur(40px) brightness(.65)",
                              }}
                              data={
                                "https://av.saintmarydelray.com/ocl/#/HTML/3/Transparent"
                              }
                            >
                              OCL
                            </object>
                          </div>
                        );
                      }}
                    </ReactResizeDetector>
                  )}
                  <div className={"mainCamCanvas"}>
                    <Video
                      streaming={this.props.main.streaming}
                      view={"Live"}
                      id="camL"
                    />
                  </div>
                </div>

                <div
                  style={{
                    flexGrow: 0,
                    color: "#fff",
                    background: "#ff00009f",
                    margin: "5px",
                    borderRadius: "5px",
                  }}
                  className={"mainCanvasLabel"}
                >
                  Live (Camera {this.context.camera.live})
                </div>
                <div style={{ flexGrow: 1 }} />
              </div>
            </div>
            <div className={"mainCanvasesSpacer"} />
            {this.state.category === "cameras" && (
              <div
                id={"panTiltContainer"}
                style={{
                  flexGrow: 0,
                }}
              >
                <div style={{ flexGrow: 0 }} className={"panTiltLabel"}>
                  Pan/Tilt
                </div>
                <div style={{ flexGrow: 1 }} />
                <Joystick
                  style={{
                    width: "100%",
                    position: `relative`,
                    flexGrow: 0,
                  }}
                  size={{ v: 180, h: 180 }}
                  onStop={() => {
                    this.ptzDrive.pan = 0;
                    this.ptzDrive.tilt = 0;
                    this.setPTZDrive();
                  }}
                  onMove={(data) => {
                    this.ptzDrive.pan = data.x;
                    this.ptzDrive.tilt = data.y;
                    this.setPTZDrive();
                  }}
                />
                <div style={{ flexGrow: 1 }} />
              </div>
            )}{" "}
          </div>
          {this.state.category === "cameras" && (
            <div
              id={"presets"}
              className={this.state.axis0Active ? "fullScreen" : ""}
              style={{
                width: "100%",
                flexDirection: "row",
                display: "flex",
              }}
            >
              {/* <div id={"presetBar"}>
              
            </div> */}
              <div id={"presetsContainer"}>
                <div className={"presetRow"}>
                  <Preset parent={this} presetIndex={1} />
                  <Preset parent={this} presetIndex={2} />
                  <Preset parent={this} presetIndex={3} />
                  <Preset parent={this} presetIndex={4} />
                  <Preset parent={this} presetIndex={5} />
                  <Preset parent={this} presetIndex={6} />
                </div>
                <div className={"presetRow"}>
                  <Preset parent={this} presetIndex={7} />
                  <Preset parent={this} presetIndex={8} />
                  <Preset parent={this} presetIndex={9} />
                  <Preset parent={this} presetIndex={10} />
                  <Preset parent={this} presetIndex={11} />
                  <Preset parent={this} presetIndex={12} />
                </div>
                <div className={"presetRow"}>
                  <Preset parent={this} presetIndex={13} />
                  <Preset parent={this} presetIndex={14} />
                  <Preset parent={this} presetIndex={15} />
                  <Preset parent={this} presetIndex={16} />
                  <Preset parent={this} presetIndex={17} default />
                  <Preset parent={this} presetIndex={18} preclose />
                </div>
              </div>
              <canvas
                id="capture"
                width="256"
                height="144"
                style={{ display: "none" }}
              ></canvas>
              {/* <div id="presetsContainer">
              {(
                this.context.presets[
                  "cam" + this.props.main.state.selectedCam + "Presets"
                ] || []
              ).map((v, presetI) => (
                <div
                  key={presetI}
                  className={"presetContainer"}
                  style={
                    this.state.axis0Active
                      ? {
                          transform:
                            "rotate(" +
                            (360 /
                              this.context.presets[
                                "cam" +
                                  this.props.main.state.selectedCam +
                                  "Presets"
                              ].length) *
                              (presetI + 0.5) +
                            "deg)",
                        }
                      : {}
                  }
                >
                  <div
                    className={
                      "presetDiv " +
                      (this.state.hoveredPresetIndex === presetI
                        ? "hovered "
                        : "") +
                      (this.context.camera.activePresets &&
                      this.context.camera.activePresets[
                        this.props.main.state.selectedCam
                      ] === v.id
                        ? "active"
                        : "")
                    }
                    style={
                      this.state.axis0Active
                        ? {
                            transform:
                              "scale(0.75) rotate(-" +
                              (360 /
                                this.context.presets[
                                  "camera" +
                                    this.props.main.state.selectedCam +
                                    "Presets"
                                ].length) *
                                (presetI + 0.5) +
                              "deg)",
                          }
                        : {}
                    }
                  >
                    <img
                      alt={"Preview"}
                      onClick={() => this.callPreset(v.id)}
                      src={v.previewData}
                    />
                    <div className="labelContainer">
                      <p>Preset {parseInt(1 + presetI, 10).toString()}</p>
                      <Dropdown
                        className={"presentOptionMenu"}
                        overlay={
                          <Menu>
                            <Menu.Item>
                              <div onClick={() => this.deletePreset(v.id)}>
                                Delete Preset
                              </div>
                            </Menu.Item>
                            <Menu.Item>
                              <div onClick={() => this.replacePreset(v.id)}>
                                Replace Preset
                              </div>
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <div style={{ color: "#fff" }}>
                          <Icon type="down" />
                        </div>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              ))}{" "}
              <div className={"presetContainer createPreset"}>
                <div className={"presetDiv"}>
                  <div className="labelContainer">
                    <p>Create New Preset</p>
                  </div>
                </div>
              </div>
            </div> */}
            </div>
          )}
        </div>
      </GlobalHotKeys>
    );
  }
};

export default CameraControl;

class LeftControl extends React.Component {
  render() {
    if (!this.props.comp.isSelectedSecondary(this.props.element, false))
      return null;
    return (
      <div
        className={"leftJoystickContainer"}
        style={{
          flexGrow: 0,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ flexGrow: 0 }} className={"leftControlLabel"}>
          {this.props.comp.CameraControls[this.props.element].label}
        </div>
        {this.props.showJoystick !== false ? (
          <div style={{ flexGrow: 1 }} />
        ) : null}
        <div
          style={{
            flexGrow: 1,
            flexShrink: 1,
            overflow: "scroll",
            padding: "10 0",
            textAlign: "center",
            color: "#eee",
          }}
        >
          {this.props.showJoystick !== false ? (
            <Joystick
              style={{
                width: "100%",
                position: `relative`,
                flexGrow: 0,
              }}
              size={{ v: 200, h: 0 }}
              {...this.props.jostickProps}
            />
          ) : null}
          {/* <div style={{ flexGrow: 1, overflow: "scroll" }}> */}
          {this.props.children}
          {/* </div> */}
        </div>
        {this.props.showJoystick !== false ? (
          <div style={{ flexGrow: 1 }} />
        ) : null}
      </div>
    );
  }
}
class KnobWithLabel extends React.Component {
  active = false;
  state = { value: this.props.value || this.props.defaultValue || 0 };
  skin = cloneDeep(skins["s" + (this.props.skin || "8")]);
  componentDidMount() {
    this.onChange = this.props.onChange || function () {};
    this.onChange = throttle(100, this.onChange);
    this.onIncrease = this.props.onIncrease || function () {};
    this.onIncrease = throttle(100, this.onIncrease);
    this.onDecrease = this.props.onDecrease || function () {};
    this.onDecrease = throttle(100, this.onDecrease);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.active === false &&
      this.props.value &&
      this.state.value !== this.props.value
    ) {
      this.setState({ value: this.props.value });
    }
  }
  ended() {
    console.log("ENDED");
    setTimeout(() => {
      if (
        this.active === false &&
        this.props.value &&
        this.state.value !== this.props.value
      ) {
        this.setState({ value: this.props.value });
      }
    }, 1000);
  }
  render() {
    if (this.props.labelFunction && this.skin.updateAttributes) {
      this.skin.updateAttributes[0].content = this.props.labelFunction;
    } else if (this.skin.updateAttributes && this.skin.updateAttributes[0]) {
      this.skin.updateAttributes[0].content = (props, value) => {
        return !isNaN(value) ? parseInt(value).toString() : value.toString();
      };
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: this.props.direction || "column",
        }}
        className={"KnobContainer"}
      >
        <div style={{ flexGrow: 0 }}>{this.props.label}</div>
        <div style={{ flexGrow: 0 }}>{this.props.before}</div>
        <div
          onMouseUp={() => {
            this.active = false;
            this.ended();
          }}
        >
          <Knob
            skin={this.skin}
            value={this.state.value}
            style={{
              width: "80px",
              height: "80px",
              margin: "0 auto",
              display: "inline-block",
              opacity: this.props.disabled ? 0.5 : 1,
            }}
            width={80}
            height={80}
            clampMin={20}
            clampMax={340}
            preciseMode={false}
            {...(this.props.KnobProps || {})}
            onStart={() => (this.active = true)}
            onEnd={() => {
              this.active = false;
              this.ended();
            }}
            onChange={(newVal) => {
              if (this.props.disabled) return;

              const maxDistance = this.props.KnobProps.max;
              let distance = Math.abs(newVal - this.state.value);
              if (distance > maxDistance) {
                return;
              } else {
                this.setState({ value: newVal });
              }
              let val = Math.round(newVal);
              if (this.state.value < val)
                this.onIncrease(val - this.state.value);
              else if (this.state.value > val && this.props.onDecrease)
                this.onDecrease(this.state.value - val);
              this.onChange(newVal);
            }}
          />
        </div>
        <div style={{ flexGrow: 0 }}>{this.props.after}</div>
      </div>
    );
  }
}
function VerticalRadio(props) {
  let value = props.value || 0;
  let onChange = props.onChange || function () {};

  return (
    <div
      style={{ display: "flex", flexDirection: props.direction || "column" }}
      className={props.noContainer ? "" : "VerticalRadio"}
    >
      {props.label ? <div style={{ flexGrow: 0 }}>{props.label}</div> : null}

      {props.opts.map((v, i) => (
        <div
          key={v.optionId}
          className={
            "VerticalRadioItem" + (v.optionId === value ? " selected" : "")
          }
          onClick={() => onChange(v.optionId)}
        >
          {v.label}
        </div>
      ))}
    </div>
  );
}
class Preset extends React.Component {
  state = {
    renameActive: false,
  };
  get parent() {
    return this.props.parent;
  }
  get presets() {
    return (
      this.parent.context.presets[
        "camera" + this.parent.props.main.state.selectedCam + "Presets"
      ] || {}
    );
  }
  get presetI() {
    return this.props.presetIndex;
  }

  get v() {
    return this.presets[this.presetI] || {};
  }
  handleFocus = (event) => event.target.select();

  render() {
    return (
      <div key={this.presetI} className={"presetContainer"}>
        <div
          className={
            "presetDiv " +
            (this.props.parent.state.hoveredPresetIndex === this.presetI
              ? "hovered "
              : "") +
            (this.props.activePreset === this.presetI ? "active" : "")
          }
        >
          <div className="labelContainer">
            {this.state.renameActive ? (
              <input
                onChange={(e) => (this.v.presetName = e.target.value)}
                onFocus={this.handleFocus}
                onBlur={() => {
                  this.setState({ renameActive: false });
                }}
                autoFocus
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    event.preventDefault();
                    this.setState({ renameActive: false });
                  }
                }}
                type="text"
                value={this.v.presetName || "Preset " + this.presetI}
              />
            ) : (
              <p>
                {this.v.presetName || "Preset " + this.presetI}
                {this.props.preclose ? " (Close)" : ""}
                {this.props.default ? " (Default)" : ""}
              </p>
            )}
            {this.state.renameActive ? (
              <div />
            ) : (
              <Menu
                portal
                theming="dark"
                overflow="auto"
                arrow
                menuButton={
                  <div style={{ color: "#fff" }}>
                    <Icon type="down" />
                  </div>
                }
              >
                <MenuItem onClick={() => this.parent.savePreset(this.presetI)}>
                  Save as Preset
                </MenuItem>
                <MenuItem onClick={() => this.setState({ renameActive: true })}>
                  Rename Preset
                </MenuItem>
                <MenuItem
                  onClick={() => this.parent.deletePreset(this.presetI)}
                >
                  Delete Preset
                </MenuItem>
              </Menu>
            )}
          </div>
          {this.v.previewName ? (
            <img
              alt={"Preview"}
              className={"presetPreview"}
              onClick={() => this.parent.callPreset(this.v.id)}
              src={
                "https://av.saintmarydelray.com/pictures/CamPreset-" +
                this.v.previewName
              }
            />
          ) : (
            <div className={"presetPreview"} />
          )}
        </div>
      </div>
    );
  }
}
